import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

function AbouDWCParks_Kumana() {
  return (
    <>
      <Row>
        <Col>
          <h3 className="title">Kumana National Park</h3>
          <img
            src={require("assets/img/aboutDWC/parks/Kumana.png")}
            className="dwc-parks-image"
            alt="Kumana National Park"
          ></img>
          <h4>Introduction</h4>
          <p>
            Yala East National Park (Kumana) Lies on the south -east coast in
            Eastern Provience,12 km south of Arugam bay,and is accessible from
            the Wellawaya -Pottuvil Road.Contiguous to the south with Ruhuna
            (Yala) National Park/Yala Strict Nature Reserve (126,786 ha) along
            Kumbukkan oya.Block II was established on 26 December 1969,followed
            by Block I on 2 January 1970,having originally been reserved for
            shooting as an intermediate zone many years before hand.Total area
            is 18149ha with 285.3ha in Block II and 17,863.4 ha in Block I.
          </p>
          <h4>ACCESS</h4>
          <p>
            <ul>
              <li>
                Gateway to Yala East is Colombo - udawalawa - thanamalwila -
                Wallawaya- Monaragala -Siyambalanduwa- Lahugala - Pottuwil
                -panama - okanda - kumana.
              </li>
            </ul>
          </p>
          <h4>PHYSICAL CHARACTERRISTICS</h4>
          <p>
            The main feature is the world famous Kumana mangrove swamp
            (c.200ha),which is surronded by plains and jungle. The flat terrain
            of the coast is broken by numerous ,rocky outcrops.There are large
            saline lagoons along the coast ,often surround by extensive plains
          </p>
          <h4>VEGITATION</h4>
          <p>
            The water-filled basins in the central flood plain are characterised
            by the abundance and predominance of water -tolerant grasses and
            aquatic plants. the distribution of vegetation in the villus shows a
            characteristic pattern,which is related to the period of inundation
            and the depth of flooding.On the margis,where wet conditions are
            brief and the depth of flooding slight ,there are creeping
            grasses,such as Cynodon dactylon,and various essentially
            terrestrialannual plants which are capable of surviving wet
            conditions.
          </p>
          <h4>FAUNA</h4>
          <p>
            In general ,the fauna is similar to that found in Ruhuna National
            Park.Kumana is reputed for its avifauna.Large numbers of certian
            species congregate to nest in the mangrooves in May -June .Common
            birds include pelican Pelecanus sp.,painted stork Ibis
            leucocephalus,spoonbill Platalea leucorodia,white ibis Threskiornis
            melancocephalus,open-billed strok Anastomus oscitans,purple hero
            Ardea purpurea,grey heron A. cinerea,egrets Egretta spp.,pond heron
            Ardeola grayii,night heron Nycticorax nycticorax,Indian darter
            Anhinga melanogaster andcormorant Phalacrocorax niger.Moorhen
            Gallinula chloropus,water cock Gallicrex cinerea,purple mooehen
            Porphyrio porphyrio,pheasant - tailed jacana Hydrophasiamus
            chirurgus,black - winged stilt Himantopus himnatopus,whistling teal
            Dendrocygna javanica and little grebe Podiceps ruficollis are also
            present ,as it black- necked strok Xenorhynchus asiaticus, one of
            Sri Lanka's rarest birds.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default AbouDWCParks_Kumana;
