import React from "react";

// reactstrap components
import {
  
  Container,

} from "reactstrap";

import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

// core components
//import IndexNavbar from "components/Navbars/IndexNavbar.js";
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import config from "../../config.json";

const queryString = require("query-string");

function UploadLeopardImage(props) {
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    //console.log(status, meta, file);
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
   // console.log("Clciked on Submit!!");
   // console.log(files.map((f) => f.meta));
  };

  // parse query params in the url
  const [queryParams, setQueryParams] = React.useState(
    queryString.parse(props.history.location.search)
  );

  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => {
    return {
      url:
        config.APP_URLS.BACKEND_SERVER_API +
        "/web/upload-to-identify/" +
        queryParams.upload_session_id,
    };
  };

  function finishUpload() {
    setConfirmModal(true);
    setShowUploadControl(false);
  }

  const [showUploadControl, setShowUploadControl] = React.useState(true);
  const [confirmModal, setConfirmModal] = React.useState(false);

  React.useEffect(() => {
    //console.log(JSON.stringify(queryParams));
  }, []);

  return (
    <>
      <div className="wrapper">
        <SubPageHeader title="Yala Leopard Center" />

        <Container>
          <br />
          <br />
          {showUploadControl && (
            <div>
              <h5>Upload Image from Device to Identify Leopard :</h5>
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                onSubmit={() => finishUpload()}
                multiple={false}
                maxFiles={1}
                accept="image/*"
                inputContent="Browse Files from Device"
              />
            </div>
          )}

          {!showUploadControl && (
            <div
              className="modal-mini modal-info"
             
            >
              <div className="modal-header justify-content-center">
                <div className="modal-profile">
                  <i className="fas fa-check-circle fa-5x btn-neutral" ></i>
                </div>
              </div>
              <br />
              <div>
                <h5 className="text-center">
                  Image Uploaded Successfully!
                </h5>
              </div>
              
            </div>
          )}

          <br />
          <br />

        </Container>
      </div>

      <DefaultFooter />
    </>
  );
}

export default UploadLeopardImage;
