import React from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import WizardCheckBox from "components/detect-components/WizardCheckBox.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";
import { ToastContainer, toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import PulseLoader from "react-spinners/PulseLoader";

import ReactCrop, { makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import Dropzone from "react-dropzone-uploader";

import config from "../../config.json";

import { v4 as uuidv4 } from "uuid";

function ManualDetectLeopardsFilterAndUpload() {
  const history = useHistory();

  const [uploadDone, setUploadDone] = React.useState(false);
  const [cropDone, setCropDone] = React.useState(false);
  const [uploadSessionID, setUploadSessionID] = React.useState(uuidv4());
  const [readyToCrop, setReadyToCrop] = React.useState(false);
  const [uploadedImgBase64, setUploadedImgBase64] = React.useState("");
  const [serverFilePath, setServerFilePath] = React.useState("");

  const [showLoadingView, setShowLoadingView] = React.useState(false);
  const [showCropWaitingLoading, setShowCropWaitingLoading] = React.useState(
    false
  );
  const [infoModal, setInfoModal] = React.useState(false);
  const [cropInfoModal, setCropInfoModal] = React.useState(false);

  const [crop, setCrop] = React.useState({
    x: 10,
    y: 10,
    width: 80,
    height: 80,
    aspect: 1 / 1,
  });

  const [cropImgRef, setCropImgRef] = React.useState(null);

  function showLoading(isShown) {
    return (
      <LoadingOverlay
        active={isShown}
        spinner={<PulseLoader />}
        text=""
      ></LoadingOverlay>
    );
  }

  function showAlert(message) {
    //("Showing Alert : " + message);
    toast.error(message, { containerId: "LocationError" });
  }

  function finishUpload() {
    setUploadDone(true);
    waitForFileToBeUploaded();
  }

  function finishCrop() {
    setCropDone(true);
    cropImageFromServerAndSearch();
  }

  function waitForFileToBeUploaded() {
    setShowLoadingView(true);
    fetch(
      config.APP_URLS.BACKEND_SERVER_API +
        "/web/get-uploaded-file/" +
        uploadSessionID,
      {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Connection: "Keep-Alive",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setShowLoadingView(false);
        // If no results found, show toast and exit
        if (json["status"] == "success") {
          setReadyToCrop(true);
          //console.log("Uploaded img base64 : ");
          //console.log(json["uploadedImageBase64"]);
          setUploadedImgBase64(
            "data:image/png;base64," + json["uploadedImageBase64"]
          );
          setServerFilePath(json["filePathInServer"]);
        }
      })
      .catch((err) => {
        setShowLoadingView(false);
        //console.log("Error captured!!! : " + err);

        showAlert("Unable to wait for image to upload. Error: " + err.message);
      });
  }

  function cropImageFromServerAndSearch() {
    setShowCropWaitingLoading(true);
    // console.log("===> IN THE CROP COMMAND");
    fetch(
      config.APP_URLS.BACKEND_SERVER_API +
        "/web/identify-given-leopard/" +
        uploadSessionID +
        "/crop",
      {
        method: "POST",
        body: JSON.stringify({
          serverFilePath: serverFilePath,
          imageCrop: crop,
          scaleFactors: cropImgRef,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Connection: "Keep-Alive",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setShowCropWaitingLoading(false);
        // If no results found, show toast and exit
        // console.log("CROPPED RESPONSE : " + JSON.stringify(json));
        if (json["status"] == "success") {
          // console.log("CROPPED IMAGE : " + json["croppedImageBase64"]);
          return json["croppedImageBase64"];
        }
      })
      .then((croppedB64) => {
        history.push("/match-identify-leopard-results", {
          croppedUploadedImage: croppedB64,
          uploadSessionID: uploadSessionID,
        });
      })

      .catch((err) => {
        setShowCropWaitingLoading(false);
        // console.log("Error captured!!! : " + err);

        showAlert("Unable to wait for image to upload. Error: " + err.message);
      });
  }

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const onImageLoaded = (image) => {
    //console.log("onImageLoaded", image);
    var coords = {};
    coords.clientHeight = image.clientHeight;
    coords.clientWidth = image.clientWidth;
    coords.naturalWidth = image.naturalWidth;
    coords.naturalHeight = image.naturalHeight;

    setCropImgRef(coords);
    //console.log("onImageLoaded cords", coords);
  };

  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => {
    return {
      url:
        config.APP_URLS.BACKEND_SERVER_API +
        "/web/upload-to-identify/" +
        uploadSessionID,
    };
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    //console.log(status, meta, file);
  };

  const onCropComplete = (crop) => {
    // console.log("onCropComplete", crop);
    // console.log(
    //   "onCropComplete Crop Image ref : " + JSON.stringify(cropImgRef)
    // );
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  return (
    <>
      {SwitchNavBar()}
      <div className="wrapper">
        <SubPageHeader title="Match and Identify Leopards" />

        <Container>
          <br />
          <br />
          <h5>
            A leopard can be identified using it's spot patterns on various
            parts of the body. See the different identification methods in{" "}
            <a href="/about-leopard-identification-method">
              leopard identification methods
            </a>{" "}
            page
          </h5>
          <h5>
            This page currently supports identification of leopards using the
            forehead images. (Other identification methods are under development
            and will be coming soon)
          </h5>
          <h4>
            <WizardCheckBox checked={uploadDone} /> &nbsp;<b>Step 1 : </b>
            Upload Image from Device to Match Leopard : (
            <a onClick={() => setInfoModal(true)} href="#">
              {" "}
              Example <i class="fas fa-question-circle"></i>
            </a>{" "}
            )
          </h4>

          <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            onSubmit={() => finishUpload()}
            multiple={false}
            maxSizeBytes={10485760} // 10Mb
            maxFiles={1}
            // autoUpload={false}
            accept="image/*"
            inputContent="Drag and Drop or Browse Files from Device. (Max 10MB)"
            submitButtonContent="Next"
            styles={{
              submitButton: {
                "border-width": "1px",
                "border-radius": "30px",
                "background-color": "#2CA8FF",
                padding: "15px 48px",
                "font-size": "1em",
                "font-weight": "400",
              },
            }}
          />

          {uploadDone && (
            <div>
              <h4>
                <WizardCheckBox checked={cropDone} />
                &nbsp; <b>Step 2 : </b> Mark the head area of the leopard to be
                identified. Use the corners of the selection box to drag and
                adjust the head area. (
                <a onClick={() => setCropInfoModal(true)} href="#">
                  {" "}
                  Example <i class="fas fa-question-circle"></i>
                </a>{" "}
                )
              </h4>
              {showLoadingView && showLoading(showLoadingView)}

              {!showLoadingView && (
                <div>
                  <Row>
                    <Col className="text-center image-crop-div-background">
                      <ReactCrop
                        src={uploadedImgBase64}
                        crop={crop}
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                      />
                    </Col>
                    <br></br>
                  </Row>
                  <br />

                  <Row>
                    <Col className="text-center">
                      <Button
                        className="btn-round"
                        color="info"
                        type="button"
                        size="lg"
                        onClick={() => finishCrop()}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                  {showCropWaitingLoading &&
                    showLoading(showCropWaitingLoading)}
                </div>
              )}
            </div>
          )}

          {cropDone && <></>}

          <Modal isOpen={infoModal} toggle={() => setInfoModal(false)}>
            <div className="modal-header justify-content-center">
              <button
                className="close"
                type="button"
                onClick={() => setInfoModal(false)}
              >
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </button>
              <h4 className="title title-up">
                Example Image and Things to Consider When Uploading
              </h4>
            </div>
            <ModalBody>
              <p>
                Following types of head shot images can be used to identify a
                leopard currently.
              </p>
              <p>Things to consider when uploading images :</p>

              <p>
                <ul>
                  <li>
                    Images should be considerably clear without too much noise
                    or obstacles.
                  </li>
                  <li>
                    The size and the quality of the image will affect the
                    identification accuracy.
                  </li>
                  <li>
                    Different angles of the head shot might affect the
                    identification accuracy.
                  </li>
                </ul>
              </p>
              <h5>Example Head Shot Image:</h5>
              <img
                src={require("assets/img/detect-leopard/exampleHeadshot.png")}
              ></img>
            </ModalBody>
          </Modal>


          <Modal isOpen={cropInfoModal} toggle={() => setCropInfoModal(false)}>
            <div className="modal-header justify-content-center">
              <button
                className="close"
                type="button"
                onClick={() => setCropInfoModal(false)}
              >
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </button>
              <h4 className="title title-up">
                Head Area Selection/Marking
              </h4>
            </div>
            <ModalBody>
              <p>
                Using the selection box drag and adjust the head area of the leopard.
              </p>
              <img
                src={require("assets/img/detect-leopard/Selection Box Usage 700px.gif")}
              ></img>
            </ModalBody>
          </Modal>


        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default ManualDetectLeopardsFilterAndUpload;
