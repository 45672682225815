import React, { useState, useEffect } from "react";

import { Tooltip } from "reactstrap";

function GoogleMapMarker(props) {
  // console.log("GoogleMapMarker Props : " + JSON.stringify(props.text));

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {});

  return (
    <>
      <div className="hovicon effect-1 sub-a" id={props.idForTooltip}>
        <Tooltip
          placement="bottom"
          isOpen={tooltipOpen}
          target={props.idForTooltip}
          toggle={toggle}
        >
          {props.text}
        </Tooltip>
      </div>
    </>
  );
}

export default GoogleMapMarker;
