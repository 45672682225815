import config from "../config.json";

export const CheckImageUploadedFromDevice = (uuid) =>
  new Promise(function (resolve, reject) {
    fetch(
      config.APP_URLS.BACKEND_SERVER_API + "/web/wait-for-file-upload/" + uuid,
      {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        resolve(json);
      })
      .catch((err) => {
        reject(
          new Error(
            "Error occurred while waiting for the image to be uploaded. Error:" +
              err.message
          )
        );
      });
  });
