import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

function ImageCarousel(props) {
  //("Received props to corousal  : " + JSON.stringify(props));

  //var items = [];
  const [items, setitems] = React.useState([]);

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // USAGE :
  // Call the component with attribute "imagepaths"
  // Which should be relative to "assets/img/" folder

  function getImages(imagepaths) {
    //console.log("Show the files list in carousal : " + imagepaths);
    var fullpathslist = [];

    imagepaths.forEach((img) => {
      var imageItem = {};

      // Future reference why the hardcoded path :
      // https://stackoverflow.com/questions/41769385/react-cant-find-module-trying-to-pass-cell-image-path-variable#
      // Fix this
      imageItem.src = require("assets/img/" + img);
      imageItem.altText = "";
      imageItem.caption = "";

      fullpathslist.push(imageItem);
    });
    setitems(fullpathslist);
  }

  useEffect(() => {
    getImages(props.imagepaths);
  }, []);

  return (
    <>
      <div id="carousel">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12" md="12">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                interval={false}
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {items.map((item) => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.src}
                    >
                      <img src={item.src} alt={item.altText} />
                      <div className="carousel-caption d-none d-md-block">
                        <h5>{item.caption}</h5>
                      </div>
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <i
                    class="fab fas fa-chevron-circle-left fa-3x"
                    style={{ color: "#fa764b" }}
                  ></i>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <i
                    class="fab fas fa-chevron-circle-right fa-3x"
                    style={{ color: "#fa764b" }}
                  ></i>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ImageCarousel;
