import React, { useState, useEffect } from "react";

// reactstrap components
import { Collapse, CardBody, Card } from "reactstrap";

import { Tree, TreeNode } from "react-organizational-chart";
import FamilyTreeItem from "components/search-components/FamilyTreeItem.js";

import config from "../../config.json";
import FamilyTreeLegend from "./FamilyTreeLegend";

function FamilyTree(props) {
  // console.log("Received props to FamilyTree  : " + JSON.stringify(props));

  const [ancestryjson, setAncestryjson] = React.useState([]);
  const [numberOfLitters, setNumberOfLitters] = React.useState([]);

  function getLeopardAncestry() {
    fetch(
      config.APP_URLS.BACKEND_SERVER_API + "/web/get-ancestory-for-leopard",
      {
        method: "POST",
        body: JSON.stringify({ leopard_id: props.leopard_id }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        // If no results found, show toast and exit
        if (json["matchResults"].length == 0) {
          return;
        }

        constructAncestryJson(json["matchResults"]);
      });
  }

  function getNestedChildren(arr, parent) {
    var out = [];
    for (var i in arr) {
      if (arr[i].mother == parent) {
        var children = getNestedChildren(arr, arr[i].leopard_id);

        if (children.length) {
          arr[i].children = children;
        }
        out.push(arr[i]);
      }
    }
    return out;
  }

  function traverseAndRedoHierarchy(arr) {
    for (var i in arr) {
      if (arr[i].children != undefined) {
        var currchildren = arr[i].children;
        var recordedLitters = [];
        for (var j = 0; j < currchildren.length; j++) {
          if (!recordedLitters.includes(currchildren[j].firstrecordedyear)) {
            recordedLitters.push(currchildren[j].firstrecordedyear);
          }
        }
        
      
        arr[i].children = [];
        for (var k = 0; k < recordedLitters.length; k++) {
          arr[i].children.push({
            litternode: true,
            litteryear: recordedLitters[k],
          });

          // set the known number of litters for the tree
          setNumberOfLitters(numberOfLitters.push(recordedLitters[k]))
        }

        for (var l = 0; l < currchildren.length; l++) {
          for (var m = 0; m < arr[i].children.length; m++) {
            if (
              currchildren[l].firstrecordedyear == arr[i].children[m].litteryear
            ) {
              if (!arr[i].children[m].hasOwnProperty("children")) {
                arr[i].children[m].children = [];
              }
              arr[i].children[m].children.push(currchildren[l]);
            }
          }
        }

        for (var n = 0; n < recordedLitters.length; n++) {
          traverseAndRedoHierarchy(arr[i].children[n].children);
        }
      }
    }
   
    return arr;
  }

  function constructAncestryJson(arr) {
    var constructedJson = traverseAndRedoHierarchy(getNestedChildren(arr));

    // sort the litter years
    setNumberOfLitters(numberOfLitters.sort())

    //console.log("Nacestry Constructed : " + JSON.stringify(constructedJson));

    setAncestryjson(constructedJson);

    //console.log("State of Ancestry set : " + ancestryjson);
  }


  useEffect(() => {
    getLeopardAncestry();
  }, []);

  return (
    <>
      <Collapse isOpen={props.toggle}>
        <Card>
          <CardBody>
            <div className="family-tree-wrapper">
              {/* 
              Hide out legend due to complexity of litters.
              TODO: Implement the litter 1,2 concept for lower branches of the tree.
              */}
              {/* <FamilyTreeLegend litters={numberOfLitters}></FamilyTreeLegend> */}
              <p><b>Note : Only successful litters are listed here.</b></p>
              <Tree
                lineWidth={"2px"}
                label={
                  <div>
                    <div>
                      <span className="family-tree-litter-text"></span>
                    </div>
                  </div>
                }
                lineColor={"#f96332"}
              >
                <FamilyTreeItem ancestory={ancestryjson} litterYears={numberOfLitters}></FamilyTreeItem>
              </Tree>
            </div>
          </CardBody>
        </Card>
      </Collapse>
    </>
  );
}

export default FamilyTree;
