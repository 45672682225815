import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
//import IndexNavbar from "components/Navbars/IndexNavbar.js";
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";

function AboutSrilankanLeopard(props) {
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const queryParams = new URLSearchParams(props.location.search);

  return (
    <>
      {SwitchNavBar(queryParams.get("source"))}
      <div className="wrapper">
        <SubPageHeader title="SriLankan Leopard" />

        <Container>
          <h3 className="title">Overview</h3>
          <p>
            The Sri Lankan leopard (Panthera pardus kotiya) is a leopard
            subspecies native to Sri Lanka that was first described in 1956 by
            the Sri Lankan zoologist Deraniyagala.[1] Since 2008, the Sri Lankan
            leopard has been listed as Endangered on the IUCN Red List. The wild
            population is roughly estimated at 700–950 individuals as of 2015.
            The Sri Lankan leopard has a tawny or rusty yellow coat with dark
            spots and close-set rosettes, which are smaller than in Indian
            leopards.
          </p>
          <p>
            The Sri Lankan leopard has evolved to become a rather large leopard
            subspecies with very large males reaching almost 220 lb (100 kg),
            due to the fact that it is an apex predator without competition by
            other large wild cat species in the country.
          </p>
          <p className="description">
            <strong>
              Source - Deraniyagala, P. E. P. (1956). "The Ceylon leopard, a
              distinct subspecies". Spolia Zeylanica. 28: 115–116.
            </strong>
          </p>
          <h3 className="title">Scientific Classification</h3>
          <p>
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <p>Kingdom:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Animal">
                        Animalia
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Phylum:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Chordate">
                        Chordata
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Class:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Mammal">
                        Mammalia
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Order:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Carnivora">
                        Carnivora
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Suborder:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Feliformia">
                        Feliformia
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Family:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Felidae">
                        Felidae
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Subfamily:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Pantherinae">
                        Pantherinae
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Genus:</p>
                  </td>
                  <td>
                    <p>
                      <em>
                        <a href="https://en.wikipedia.org/wiki/Panthera">
                          Panthera
                        </a>
                      </em>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Species:</p>
                  </td>
                  <td>
                    <p>
                      <em>
                        <a href="https://en.wikipedia.org/wiki/Leopard">
                          P.&nbsp;pardus
                        </a>
                      </em>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Subspecies:</p>
                  </td>
                  <td>
                    <p>
                      <strong>
                        <em>P.&nbsp;p. kotiya</em>
                      </strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </p>
          <h3 className="title">Sri Lankan Leopard Facts</h3>
          <p>
            <ul>
              <li>
                Scientific name Sri Lankan sub species – Panthera pardus kotiya
              </li>
              <li>Life Span – around 12 - 15 years in wild</li>
              <li>Weight – Male average 60 kg and Female average 35 kg </li>
              <li>Number of toes – 18 (5 in each fore leg, 4 in hind leg) </li>
              <li>Sexual maturity – around 2.5 – 3 years </li>
              <li> Gestation Period – 95 to 105 days</li>
              <li> Litter size – 1-3 cubs </li>
              <li>
                {" "}
                The Leopardess is responsible for rearing and training the cubs
              </li>
              <li> Separation of cubs from mother – around 20-30 months </li>
              <li> Distribution – Arid Zone , Dry Zone and Wet Zone </li>
            </ul>
          </p>

          <h3 className="title">Leopard Distribution in Sri Lanka </h3>

          <div className="div-content-center">
            <img
              alt="Leopard Distribution in SriLanka"
              src={require("assets/img/about-leopard/LeopardDistribution.jpg")}
            ></img>
            <p className="description">
              <strong>
                <a href="https://f002.backblazeb2.com/file/cached-individual-maps/T15959A50660847.jpg?Authorization=3_20201101074837_a548e948893d067fa6a19ea6_90fea8d42f4f1067d24df69da1cee2d5aa170238_002_20201108074837_0019_dnld">
                  https://f002.backblazeb2.com/file/cached-individual-maps/T15959A50660847.jpg?..
                </a>
                <br />
                [accessed 01 Nov, 2020]
              </strong>
            </p>
          </div>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default AboutSrilankanLeopard;
