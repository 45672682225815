import React from "react";

// reactstrap components
import {

  Container,

} from "reactstrap";

// core components
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import ImageCarousel from "components/projects-components/ImageCarousel.js";

//import config from "../../config.json";
//import GoogleMapReact from "google-map-react";

import { SwitchNavBar } from "../../../utils/SwitchNavBar.js";

function ProjectOutReach() {
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      {SwitchNavBar()}
      <div className="wrapper">
        <SubPageHeader title="Outreach program to Ranminithenna Kanitu Vidyala" />

        <Container>
          <ImageCarousel
            imagepaths={[
              "about-projects/outreach/Img_1.jpg",
              "about-projects/outreach/Img_2.jpg",
              "about-projects/outreach/Img_3.jpg",
              "about-projects/outreach/Img_4.jpg",
              "about-projects/outreach/Img_5.jpg",
              "about-projects/outreach/Img_6.jpg",
              "about-projects/outreach/Img_7.jpg",
              "about-projects/outreach/Img_8.jpg",
              "about-projects/outreach/Img_9.jpg",

            ]}
          />

          <h3 className="title"> About </h3>
          <p>
          Yala Leopard Center conducted a community outreach project to Ranminithenna Kanitu Vidyala. Sixty students from Age groups 7-14 were provided a Yala safari on the 30th of July.
        </p>        
        <p>
          The main aim is to create a liking towards wildlife and to educate and create awareness on the benefits of wildlife to the 2nd Generation of the villages bordering Yala. The aim is to get the 2nd generation out of Poaching and other Illicit activities that the generation before them are engaged in.  </p>
          <p>
            Special thanks to Professor Thilak Jayalath for his generosity in sponsoring the entire project.
            Thank you to Yala National Park Warden Manoj Vidyaratne, Nishatha Ediriweera, and all DWC officials involved in making this project successful.

            </p>
          <p>
            <h5> Concluded on 30th July 2023 </h5>
          </p>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default ProjectOutReach;
