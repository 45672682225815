import React from "react";

// reactstrap components
import {
  
  ModalHeader,
  Container,

  Modal,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Badge,
} from "reactstrap";

// core components
//import IndexNavbar from "components/Navbars/IndexNavbar.js";
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { DocumentList } from "../../variables/AllDocumentList.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";

function AboutPublishedArticles(props) {
  const [documentList, setDocumentList] = React.useState(DocumentList);

  
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  const closeBtn = (
    <button className="model-close-button close" onClick={toggle}>
      &times;
    </button>
  );

  const [currentSrc, setCurrentSrc] = React.useState(null);
  const [currentDescription, setCurrentDescription] = React.useState("");
  const [currentDocumentType, setCurrentDocumentType] = React.useState(null);

  function showArticleModal(document) {
    setCurrentSrc(require(`assets/articles/${document.Path}`));
    setCurrentDescription(document.Name);
    setCurrentDocumentType(document.DocumentType)
    setModal(true);
  }

  function generateUUIDv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const queryParams = new URLSearchParams(props.location.search);

  return (
    <>
      {SwitchNavBar(queryParams.get("source"))}
      <div className="wrapper">
        <SubPageHeader title="Research Papers & Articles" />

        <Container>
          <br />
          {/* <Table>
            <Row>
              {documentList.map((document, index) => (
                <Col lg="3" md="4" sm="6" xs="12" key={"docs-" + index}>
                  <Card>
                    <div>
                      <a onClick={() => showArticleModal(document)}>
                        <img src={pdfLogo} />
                      </a>
                      <p>{document.Name}</p>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </Table> */}
          <ListGroup>
            {documentList.map((document, index) => (
              <ListGroupItem>
                <ListGroupItemHeading>
                  <a
                    href="javascript:;"
                    onClick={() => showArticleModal(document)}
                  >
                    {document.Name}
                  </a>
                </ListGroupItemHeading>
                <ListGroupItemText>
                  {document.DocumentType === "DOCUMENT" ? (
                    <Badge color="primary" className="mr-1">
                      PDF
                    </Badge>
                  ) : (
                    <Badge color="primary" className="mr-1">
                      Other
                    </Badge>
                  )}

                  <Badge color="info" className="mr-1">
                    Research Article
                  </Badge>
                </ListGroupItemText>
              </ListGroupItem>
            ))}
          </ListGroup>

          <Modal className="model-fullscreen" isOpen={modal} toggle={toggle}>
            <ModalHeader
              className="modal-header-no-padding"
              toggle={toggle}
              close={closeBtn}
            >
              {currentDescription}
            </ModalHeader>
            <div className="modal-body-no-border">

              {currentDocumentType === "DOCUMENT" ? (
                <iframe
                  className="result-document-inner-pdf"
                  src={currentSrc}
                  title={generateUUIDv4()}
                />
              ) : (
                <embed src={currentSrc} className="result-document-inner" />
              )}

              {/* <embed src={currentSrc} className="result-document-inner" /> */}
              {/* <Document
                file={'assets/articles/599702_2.pdf'}
                // onLoadSuccess={this.onDocumentLoadSuccess}
              ></Document> */}
            </div>
          </Modal>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default AboutPublishedArticles;
