import React from "react";

// reactstrap components
import { Container } from "reactstrap";

import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { SwitchNavBar } from "../../utils/SwitchNavBar.js";
import SubPageHeader from "components/Headers/SubPageHeader.js";

import config from "../../config.json";
import EmptyNavbar from "components/Navbars/EmptyNavbar.js";

function DeviceUploadTheImage(props) {
  const getUploadParams = () => ({
    url:
      config.APP_URLS.BACKEND_SERVER_API +
      "/web/upload-to-identify/" +
      props.match.params.uuid,
  });

  const [isNextActionAllowed, setIsNextActionAllowed] = React.useState(null);
  const [isUploadDisabled, setIsUploadDisabled] = React.useState(false);
  const [uploadText, setUploadText] = React.useState(
    "Browse Files from Device"
  );

  const { seconds = 10 } = { seconds: 10 };
  const [[secs], setTime] = React.useState([seconds]);

  const tick = () => {
    if (secs === 0) {
      closeMe();
    } else {
      setTime([secs - 1]);
    }
  };

  function closeMe() {
    window.opener = null;
    window.open("", "_self");
    window.close();
    window.history.go(-1);
  }
  const handleChangeStatus = ({ meta, remove }, status) => {
    if (status === "headers_received" || status === "done") {
      setIsUploadDisabled(true);
      setIsNextActionAllowed(true);
      setUploadText("Upload Completed Successfully! Click Next to Proceed");
      remove();
    }
  };

  React.useEffect(() => {
    if (isNextActionAllowed) {
      const timerId = setInterval(() => tick(), 1000);
      return () => clearInterval(timerId);
    }
  });

  // React.useEffect(() => {}, []);

  return (
    <>
      <EmptyNavbar />
      <div className="wrapper">
        <SubPageHeader title="Match and Identify Leopards" />

        <Container>
          <div>
            <br />
            <h5>Upload Image from Device to Identify Leopard : </h5>

            <br />

            {!isNextActionAllowed && (
              <Dropzone
                disabled={isUploadDisabled}
                getUploadParams={getUploadParams}
                multiple={false}
                maxFiles={1}
                accept="image/*"
                inputContent={uploadText}
                onChangeStatus={handleChangeStatus}
                maxSizeBytes={10485760}
              />
            )}
            {isNextActionAllowed && (
              <div className="upload-success-message">
                <i className="fa fa-check-circle fa-5x" aria-hidden="true"></i>
                <h5>
                  {" "}
                  &nbsp;&nbsp;
                  {"Image uploaded successfully! "}
                  <br />
                  {"Navigate to the previous screen to proceed."}
                </h5>
                <br />
                <br />
                <h6>
                  {"This window will be automatically closed in " +
                    `${secs.toString().padStart(2, "0")}` +
                    " seconds"}
                </h6>
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}

export default DeviceUploadTheImage;
