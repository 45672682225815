import React from "react";

// reactstrap components
import {

  Row,
  Col,
} from "reactstrap";

function AbouDWCParks_Hortonplains() {
  return (
    <>
      <Row>
        <Col>
          <h3 className="title">Horton Plains National Park</h3>
          <img
            src={require("assets/img/aboutDWC/parks/HortonPlains.png")}
            className="dwc-parks-image"
            alt="Horton Plains National Park"
          ></img>
          <h4>Introduction</h4>
          <p>
            Horton plain, its surroundings forests and the adjoining Peak
            Wilderness, consolidate Sri Lanka’s most important catchment area of
            almost all the major rives. The plains are also of outstanding the
            habitats and endemic plants and animals representatives of the
            country wet and montage zones.
          </p>
          <h4>ACCESS</h4>
          <p>
            <ul>
              <li>Via Nuwara Eliya, Ambewela and Pattipola (32km)</li>
              <li>Via Haputaleor Welimada, Boralanda, Ohiya (38km)</li>
              <li>
                Nuwara Eliya, Hakgala, Rendapola, Ambewela, pattipola (38km)
              </li>
            </ul>
          </p>
          <h4>PHYSICAL CHARACTERRISTICS</h4>
          <p>
            Horton plains comprises a gently undulation highland plateau at the
            southern end of the central mountains massif of Sri Lanka. It is
            dominated to the north by Mount Totupolakanda (2,357m) and to the
            west by Mount Kirigalpotta (2,389m). Two escarpments filling from
            the Horton Plain have contributed immensely to its awe inspiring
            physiognomy, “big worlds end” by 884m. The charm of the verdure of
            the mountains encircling he plains as intermittently concealed by
            mist is heightened by the sparking Baker’s fall. The altitude of the
            park ranges from about 1,800m to 2,389m at the top of Kirigalpotta.
            The plateau at 2,100m is the highest tableland in Sri Lanka. The
            annual rainfall in the region is about 2540mm, but for Horton Plains
            it may exceed 5000mm. rain occurs throughout most of the year but
            there is a dry season from January to March. Temperatures are low,
            with an annual mean temperature 15ºC and ground frost is common in
            December to February.
          </p>
          <h4>VEGITATION</h4>
          <p>
            Horton Plains is well recognized for its rich biodiversity, its
            flora given to a high level of endemism. 5% of species are found to
            be endemic to Sri Lanka. The plateau supports grassland fringed and
            interspersed with patches of dense montane cloud forest. A rich
            herbaceous flora flourishes on the patanas with numerous species of
            both temperate and topical origin. A vast extent of the patanas was
            broken and brought under potato cultivation a few decades ago. The
            origin of the montane grasslands has long been debated. Some workers
            opining that the grassland are an artificial community createx by
            forest clearance and maintained by periodic burning while others
            considering them to the natural vegetation of these uplands.
          </p>
          <h4>FAUNA</h4>
          <p>
            Large mammals could seldom be seen at Horton Plains. Samber is
            common sight at dusk and in the early morning hours. Mammals which
            still occur in reasonable numbers include Kelaart’s long clawed
            shrew Feroculus feroculus slender loris loris tardigradus endemic to
            the montane toque macaque Macaca sinica , purple faced langur
            Presbutis entellus, rusty-spotted cat felis rubigimosus and etc.
            Horton Plains National park harbors 12 species of endemic birds the
            following birds are recorded only for Horton Plains. Sri Lanka blue
            magpie Cissa ornate , dusky blur flycatcher Eumyias sordisa , Sri
            Lanka white – eye Zosterops ceulonensis and Sri Lanka wood pigeon
            columba torringtonii . There are various species of harriers and
            buzzards. This park is a paradise for butterflies too. Among
            reptiles are Snake Aspidurabrachyorrhos and the wide spread agamid
            Calotes nigrilabris . The only fish is the introduced rainbow trout
            Salmo gardneri . The distribution of the endemic fresh water shrimp
            Caridina singhalensis is believed to be confined to a 10k, stretch
            of river within the park.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default AbouDWCParks_Hortonplains;
