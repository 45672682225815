import React from "react";

// reactstrap components
import {
  Row,
  Col,
} from "reactstrap";



function AbouDWCParks_Bundala() {
  
  return (
    <>
      <Row>
        <Col>
          <h3 className="title">Bundala National Park</h3>
          <img
            src={require("assets/img/aboutDWC/parks/Bundala.png")}
            className="dwc-parks-image"
            alt="Bundala National Park"
          ></img>
          <h4>Introduction</h4>
          <p>
            Bundala National park is lies on the Hambantota District of the
            southern province. Bundala initially declared as sanctuary on 05 th
            December 1969 and was upgraded to a National park on 4 th January
            1993. This is the last refuge in the greater flamingo in this part
            of the island, as well as being important for elephant and a variety
            of threatened reptiles.
          </p>
          <h4>ACCESS</h4>
          <p>
            <ul>
              <li>
                Along the cost via Galle, Matara, Tangalla and Hambantota
                (Approximately 256km)
              </li>
              <li>
                Via Rathnapura, Pelmadulla, Embilipitiya. Hambantota
                (Approximately 245km){" "}
              </li>
            </ul>
          </p>
          <h4>PHYSICAL CHARACTERRISTICS</h4>
          <p>
            The park contains five shallow, brackish lagoons with salt pans in
            three interconnecting channels and marshes including the adjacent
            coast. This unique area of picturesque lagoons and inter tidal; mud
            flats where the wintering birds rest and fees, golden beaches and
            sand dunes frequented by nesting sea turtles. The park is also a
            paradise for 149 species of resident migratory bird and the area of
            the park is 6,216ha. Conditions are tropical and monsoonal, with a
            mean annual temperature of 27 ° C. Annual rainfall ranges from 900mm
            to 1300mm, with an extensive dry period from May to September.
          </p>
          <h4>VEGITATION</h4>
          <p>
            The phytoplankton in all the lagoons are dominated by blue-green
            alga, the arid vegetation consist largely of grass flats studded
            with a forest scrub.
          </p>
          <h4>FAUNA</h4>
          <p>
            The forest still harbors a few elephant and migratory herds of upto
            80 animals have been recorded in the area. The park is the home of
            every species of water bird resident in the country and during the
            in the northern winter, it is the final destination for countless
            numbers of waders of most species recorded in Sri Lanka.
          </p>
        </Col>
      </Row>
      

      



    </>
  );
}

export default AbouDWCParks_Bundala;
