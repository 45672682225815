import React from "react";

// reactstrap components
import { Container } from "reactstrap";

import config from "../../config.json";

function TouchNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [TOUCH_PANEL_UI_SERVE_URL,] = React.useState(
    config.APP_URLS.TOUCH_PANEL_UI_SERVE_URL
  );

  const [backToURL, setBackToURL] = React.useState(config.APP_URLS.TOUCH_PANEL_UI_SERVE_URL);

  function determineBackURL() {
    var url = window.location.href;

    if (url.includes("identified-leopard-result")) {
      setBackToURL("/search-identified-leopards?source=leopardcenter")
    } else if (url.includes("/search-identified-leopards") ||
      url.includes("our-identification-convention") ||
      url.includes("about-leopard-diary-team") ||
      url.includes("about-srilankan-leopard") ||
      url.includes("about-leopard-behaviours") ||
      url.includes("match-identify-leopards") ||
      
      url.includes("about-published-articles")) {
      setBackToURL(TOUCH_PANEL_UI_SERVE_URL + "/about-leopards")
    }
    else {
      setBackToURL(TOUCH_PANEL_UI_SERVE_URL)
    }


  }

  React.useEffect(() => {

    determineBackURL()

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 100 ||
        document.body.scrollTop > 100
      ) {
        setNavbarColor("-focus");
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor("");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}

      <Container>
        <a href={backToURL} className="touch-navbar-back-btn">
          <i
            class={
              "fas fa-arrow-circle-left fa-5x text-center touch-navbar-back-btn-icon" +
              navbarColor
            }
          ></i>
        </a>
      </Container>
    </>
  );
}

export default TouchNavbar;
