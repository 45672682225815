import config from "../config.json";

export const AIAssistedMatch = (filtered_leopards, session_id, orientation) =>
  new Promise(function (resolve, reject) {
    var json = {
      filtered_leopards: filtered_leopards,
      orientation: orientation,
    };

    // console.log("## AI assisted search to backend : " + JSON.stringify(json));

    fetch(
      config.APP_URLS.BACKEND_SERVER_API +
        "/web/identify-given-leopard/" +
        session_id,
      {
        method: "POST",
        body: JSON.stringify(json),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        // If no results found, show toast and exit
        if (
          json["matchResults"] === undefined ||
          json["matchResults"].length == 0
        ) {
          // console.log()
          reject(new Error("No match found in the database for the filter"));
        } else {
          resolve(json["matchResults"]);
        }
      })
      .catch((err) => {
        console.log("Error captured!!! : " + err);
        reject(
          new Error("Unable to search for leopards. Error:" + err.message)
        );
      });
  });
