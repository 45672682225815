import React from "react";

import config from "../config.json";
import TouchNavbar from "components/Navbars/TouchNavbar";
import IndexNavbar from "components/Navbars/IndexNavbar";

export const SwitchNavBar = (param) => {
  if (param === "leopardcenter") {
    return <TouchNavbar />;
  } else {
    return <IndexNavbar />;
  }
};
