import React from "react";
import { Link, useHistory } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

import { useMediaQuery } from "react-responsive";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const [isLeopardMenuOpen, leopardMenuOpen] = React.useState(false);
  const [isAboutLeopardMenuOpen, aboutLeopardMenuOpen] = React.useState(false);

  const history = useHistory();
  const isMobileMenuEnabled = useMediaQuery({
    query: "(max-width:  991px)",
  });

  React.useEffect(() => {
    if (isMobileMenuEnabled) {
      leopardMenuOpen(true);
    }

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 100 ||
        document.body.scrollTop > 100
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  function toggleLeopardMenuOpen() {
    leopardMenuOpen(true);
    aboutLeopardMenuOpen(false);
  }

  function toggleAboutLeopardMenuOpen() {
    leopardMenuOpen(false);
    aboutLeopardMenuOpen(true);
  }

  function navigateToYalaLeopardHome() {
    leopardMenuOpen(false);
    history.push("/our-identification-convention");
  }

  function closeAllExpandedMenus() {
    if (!isMobileMenuEnabled) {
      leopardMenuOpen(false);
      aboutLeopardMenuOpen(false);
    }
  }

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand href="/" id="navbar-brand">
              <img
                alt="..."
                className="landing-header-logo"
                src={require("assets/img/landing-page/Leopard Diary Logo 50x50.png")}
              ></img>
              Yala Leopard Diary
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Leopard Identification and more, for Better Conservation
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <UncontrolledDropdown isOpen={isLeopardMenuOpen} nav>
                <DropdownToggle
                  caret
                  color="default"
                  //href="/leopard-territories"
                  nav
                  onClick={() => navigateToYalaLeopardHome()}
                  onMouseOver={() => toggleLeopardMenuOpen()}
                >
                  <i className="now-ui-icons ui-1_zoom-bold"></i>
                  <p>Yala Leopards</p>
                </DropdownToggle>
                <DropdownMenu
                  onMouseLeave={() => leopardMenuOpen(false)}
                  onBlur={() => leopardMenuOpen(false)}
                >
                  <DropdownItem to="/our-identification-convention" tag={Link}>
                    <i className="now-ui-icons business_bulb-63"></i>
                    Our Identification Convention
                  </DropdownItem>
                  <DropdownItem to="/search-identified-leopards" tag={Link}>
                    <i className="now-ui-icons ui-1_zoom-bold"></i>
                    Identified Leopards
                  </DropdownItem>
                  <DropdownItem to="/match-identify-leopards" tag={Link}>
                    <i className="now-ui-icons gestures_tap-01"></i>
                    Automatically Identify Leopards
                  </DropdownItem>
                  <DropdownItem to="/leopard-homeranges" tag={Link}>
                    <i className="now-ui-icons location_map-big"></i>
                    Home Ranges of Leopards
                  </DropdownItem>
                  <DropdownItem to="/about-leopard-behaviours" tag={Link}>
                    <i className="now-ui-icons media-1_album"></i>
                    Distinct Observations
                  </DropdownItem>
                  <DropdownItem to="/leopard-videos" tag={Link}>
                    <i className="now-ui-icons media-1_album"></i>
                    Leopard Videos
                  </DropdownItem>
                  <DropdownItem to="/about-yala" tag={Link}>
                    <i className="now-ui-icons location_pin"></i>
                    About Yala
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown isOpen={isAboutLeopardMenuOpen} nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="#"
                  nav
                  onClick={(e) => e.preventDefault()}
                  onMouseOver={() => toggleAboutLeopardMenuOpen()}
                >
                  <i className="now-ui-icons travel_info"></i>
                  <p>About Leopards</p>
                </DropdownToggle>
                <DropdownMenu
                  onMouseLeave={() => aboutLeopardMenuOpen(false)}
                  onBlur={() => aboutLeopardMenuOpen(false)}
                >
                  <DropdownItem to="/about-leopards-across-world" tag={Link}>
                    <i className="now-ui-icons travel_info"></i>
                    Leopards Across the World
                  </DropdownItem>

                  <DropdownItem to="/about-srilankan-leopard" tag={Link}>
                    <i className="now-ui-icons travel_info"></i>
                    Sri Lankan Leopard
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem onMouseOver={() => closeAllExpandedMenus()}>
                <NavLink to="/about-leopard-diary-team" tag={Link}>
                  <i className="now-ui-icons business_badge"></i>
                  <p>Leopard Diary Team</p>
                </NavLink>
              </NavItem>

              <NavItem onMouseOver={() => closeAllExpandedMenus()}>
                <NavLink to="/projects/home" tag={Link}>
                  <i className="now-ui-icons design-2_ruler-pencil"></i>
                  <p>Our Conservation Initiatives</p>
                </NavLink>
              </NavItem>

              <NavItem onMouseOver={() => closeAllExpandedMenus()}>
                <NavLink to="/projects/research" tag={Link}>
                  <i className="now-ui-icons transportation_air-baloon"></i>
                  <p>Our Research</p>
                </NavLink>
              </NavItem>

              {/* 
              <DropdownItem to="/about-published-articles" tag={Link}>
                    <i className="now-ui-icons files_paper"></i>
                    Published Articles
                  </DropdownItem>
              <NavItem>
                <NavLink to="/about-dwc" tag={Link}>
                  <i className="now-ui-icons business_bank"></i>
                  <p>About DWC</p>
                </NavLink>
              </NavItem>
 <DropdownItem to="/about-zones" tag={Link}>
                    <i className="now-ui-icons business_chart-pie-36 mr-1"></i>
                    Demarcation Of Zones
                  </DropdownItem>
               <NavItem>
                <NavLink
                  to="/about-yala"
                  tag={Link}
                >
                  <i className="now-ui-icons location_pin"></i>
                  <p>About DWC</p>
                </NavLink>
              </NavItem> */}

              <NavItem onMouseOver={() => closeAllExpandedMenus()}>
                <NavLink
                  href="https://www.facebook.com/groups/129638654392324"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  Follow us on Facebook
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
