import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

function AbouDWCParks_Wasgamuwa() {
  return (
    <>
      <Row>
        <Col>
          <h3 className="title">Wasgamuwa National Park</h3>
          <img
            src={require("assets/img/aboutDWC/parks/Wasgamuwa.png")}
            className="dwc-parks-image"
            alt="Wasgamuwa National Park"
          ></img>
          <h4>Introduction</h4>
          <p>
            Wasgamuwa is well known its abundant wildlife population, wilderness
            setting and home of several interesting ancient sites. Wasgamuwa is
            distinguished from other parks by having a full complement of
            predators of which bears are particularly noteworthy. Ecological
            research undertaken in the park suggests that the density of bears
            in Wasgamuwa is probably higher than anywhere else in Sri Lanka. The
            name Wasgamuwa may also derive from an ancient meaning as a
            gathering place for bears. Part of Wasgamuwa was declared a Strict
            Nature Reserve in 1938 and additional areas added to become
            Wasgamuwa National Park on 07 th August 1984. The park covers almost
            37,062.9 hectares of which most is wilderness areas with no
            visitation.
          </p>
          <h4>ACCESS</h4>
          <p>
            <ul>
              <li>
                Wasgamuwa is 225kms from Colombo and can be reached via Kandy.
                Turnoff at Hasalaka on the Kandy-Mahiuyanganga Road and proceed
                via Wilgamuwa up to can reach Wasgamuwa via Laggala and
                Waligamuwa.
              </li>
            </ul>
          </p>
          <h4>PHYSICAL CHARACTERRISTICS</h4>
          <p>
            The dominant tomography is the north-south aligned, quartzite
            Sudukanda range (535m) in the west of the park, the Amban Ganga to
            the west and the Mahaweli Ganga. It futures to weosion remnants,
            such as Nuganagala (142m) in the North and Udawewalanda (190m)
            towards the south. Rocks are predominantly Pre-cambian. Soil are
            reddish-brown earth in the upper catchment areas and alluvial in
            floodplains. Total area is over 39,322 ha. The park is contiguous to
            the Riverine Nature Reserve (920.6ha) on the right bank of the
            Mahaweli Ganga. Climate conditions are fairly typical of the dry
            zone and largely influenced by the northeast monsoon (Maha) in
            October- February. Inter-monsoon rains occur in March-May. Annual
            rainfall increases from about 1,750mm in the north to 2,250mm in the
            south and mean annual temperature is about 27 ° C, with little
            variation year-round.
          </p>
          <h4>VEGITATION</h4>
          <p>
            The main vegetation type is topical dry mixed evergreen forest, but
            due to the variations in geology, landform, soils and hydrological
            conditions sub-natural vegetation type is also found, resulting from
            human interventions.
          </p>
          <h4>FAUNA</h4>
          <p>
            Twenty three species of mammals have been reported from the park.
            The park is especially known for its high abundance of sloth bears
            (Melursus Ursinus) and a reputation for housing some of the largest
            Asian elephants and the “marsh elephant” of Wasgamuwa are reputedly
            the largest of the Sri Lankan elephants, Some 143 species birds have
            been recorded of which 5 are endemic to Sri Lanka. Fifteen species
            of amphibians have been reported including the endemic, there are
            also 35 reptiles, many butterflies can be seen flitting around the
            park and 52 different species have been recorded, out of which are
            endemic and 17species of fish have been recorded.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default AbouDWCParks_Wasgamuwa;
