import React from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Progress,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";

import "react-image-crop/dist/ReactCrop.css";

import { Wizard, useWizard } from "react-use-wizard";
import { Stepper } from "react-form-stepper";

import config from "../../config.json";

import { v4 as uuidv4 } from "uuid";
import UploadTheImage from "./UploadTheImage.js";
import MarkInterestArea from "./MarkInterestArea.js";
import SelectOrientation from "./SelectOrientation.js";
import SubmitAndWaitForResults from "./SubmitAndWaitForResults.js";
import MatchLeopardResults from "./MatchLeopardResults.js";

function MatchLeopardWizard(props) {
  const [uploadUUID, setUploadUUID] = React.useState(-1);

  const [croppedBase64, setCroppedBase64] = React.useState(null);
  const [selectedOrientation, setSelectedOrientation] = React.useState(null);

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const Step0 = () => {
    const { handleStep, previousStep, nextStep } = useWizard();

    handleStep(() => {
      setUploadUUID(uuidv4());
    });

    return (
      <>
        <div>
          <h5>
            A leopard can be identified using it's spot patterns on various
            parts of the body. See the different identification methods in{" "}
            <a href="/about-leopard-identification-method">
              leopard identification methods
            </a>{" "}
            page
          </h5>
        </div>
        <Row>
          <Col md="4"></Col>
          <Col md="4">
            <Button
              color="info"
              size="lg"
              className="btn-round"
              onClick={() => nextStep()}
              block
            >
              {" "}
              Click to Start{" "}
            </Button>
          </Col>
          <Col md="4"></Col>
        </Row>
      </>
    );
  };

  const queryParams = new URLSearchParams(props.location.search);

  const WizHeader = () => {
    const { activeStep, isFirstStep } = useWizard();

    return (
      <>
        {!isFirstStep && (
          <>
            <Row>
              <Col md="12">
                <Stepper
                  steps={[
                    { label: "Upload" },
                    { label: "Select Orientation" },
                    { label: "Crop Prominent Area" },
                    { label: "Analyze Results" },
                  ]}
                  connectorStateColors={true}
                  activeStep={activeStep - 1}
                  styleConfig={{
                    activeBgColor: "#f96332",
                    completedBgColor: "#8fc73d",
                  }}
                  className="stepper-font-class"
                />
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  function orientationCallBack(orientation) {
    setSelectedOrientation(orientation);
  }

  function cropCallBack(croppedB64) {
    // console.log(
    //   "Received data from cropCallBAck : " +
    //     JSON.stringify(serverFilePath) +
    //     " | " +
    //     JSON.stringify(cropImgRef) +
    //     " | " +
    //     JSON.stringify(crop)
    // );
    setCroppedBase64(croppedB64);
  }

  return (
    <>
      {SwitchNavBar(queryParams.get("source"))}
      <div className="wrapper">
        <SubPageHeader title="Match and Identify Leopards" />

        <Container>
          <br />
          <br />

          <Wizard header={<WizHeader />}>
            <Step0 />
            <UploadTheImage
              uuid={uploadUUID}
              source={queryParams.get("source")}
            />
            <SelectOrientation callback={setSelectedOrientation} />
            <MarkInterestArea uuid={uploadUUID} callback={cropCallBack} />
            <MatchLeopardResults
              uuid={uploadUUID}
              croppedBase64={croppedBase64}
              orientation={selectedOrientation}
              source={queryParams.get("source")}
            />
          </Wizard>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default MatchLeopardWizard;
