import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

function AbouDWCParks_Udawalawa() {
  return (
    <>
      <Row>
        <Col>
          <h3 className="title">Udawalawa National Park</h3>
          <img
            src={require("assets/img/aboutDWC/parks/Udawalawa.png")}
            className="dwc-parks-image"
            alt="Udawalawa National Park"
          ></img>
          <h4>Introduction</h4>
          <p>
            The Udawalawe National park which has acreage of 30,821 hectares,
            was declared as a National Park in 30 th June 1972 under the Fauna
            and Flora Protection ordinance. The park lies in the Rathnepura
            District in Sabaragamuwa Province Monaragala District in Uva
            Province. It forms of the largest conservation areas within these
            districts and largely comprises the lower and the immediate
            catchments of Udawalawe. The walawe reservoir is situated in the
            park and surface area of it at full supply level is about 3405
            hectares
          </p>
          <h4>ACCESS</h4>
          <p>
            <ul>
              <li>
                The easiest and quickest aproch from Colombo is through
                Rathnapura Pelmadulla on the Ebilipity road. At Thimbolketiya
                turn left and Udawalaer junction to take the road to
                ThanamalWila. The main entrence to the park is situated at the
                Udawalawe, Thanamalwila road about 11km from Udawalawe Junction
                which is southern boundary of the park. The park is therefore
                only 115 miles from Colombo.
              </li>
            </ul>
          </p>
          <h4>PHYSICAL CHARACTERRISTICS</h4>
          <p>
            The most prominent feature is the Kalthote escarpment and
            spectacular Diyawinne fall to the north Ulagala and in the west of
            the park. The park is situated in the dry zone. There is a short dry
            period in February-March. Sometimes that period is prolonged from
            mid May to end of September. This is mences with intermonsoon in the
            month of September. This is followed by Northeast monsoon rain in
            November to mid January. Due to conventional activity, the rainfall
            can occur during April-May. The mean annual rainfall is about
            1524mm. The annual average temperature is about 32 ° C and it can
            uniform throughout the year.
          </p>
          <h4>VEGITATION</h4>
          <p>
            The soil and climatic conditions are responsible for classified
            vegetation types. The predominant ecosystem of the park area is the
            forest area with scattered grasslands and thorny-shrubs.
          </p>
          <h4>FAUNA</h4>
          <p>
            This National Park is very popular among visitor for elephants
            because, they can be observed even at mid day. Herds of elephants
            can ve seen along the river during the dry season which is usually
            between May and September. Birdlife is in plenty, but once again
            difficult to observe when on the ground due to the tall grass.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default AbouDWCParks_Udawalawa;
