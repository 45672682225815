import React from "react";

// reactstrap components
import { Card, CardBody, CardHeader, Row, Col, Button } from "reactstrap";

import "react-dropzone-uploader/dist/styles.css";

import { useWizard } from "react-use-wizard";
import { ToastContainer, toast } from "react-toastify";
import ReactCrop from "react-image-crop";
import WizardFooter from "components/detect-leopard-components/WizardFooter";

import config from "../../config.json";

function SelectOrientation(props) {
  const { handleStep } = useWizard();
  const [selectedOrientation, setSelectedOrientation] = React.useState(null);

  function selectOrientation(orientation) {
    setSelectedOrientation(orientation);
  }

  handleStep(() => {
    props.callback(selectedOrientation);
  });

  React.useEffect(() => {});

  return (
    <>
      <div>
        <Row>
          <h4>
            Select the prominent section of the leopard image for identification{" "}
          </h4>
        </Row>
        <Row>
          <Col lg="4" md="4" sm="6" xs="6">
            <Card className="orientation-card">
              <div
                className={
                  "team-player " +
                  (selectedOrientation === "right"
                    ? "orientation-card-selected"
                    : "")
                }
                onClick={(e) => selectOrientation("right")}
              >
                <img
                  src={require(`assets/img/detect-leopard/leopard_head_orientation_right.jpeg`)}
                  alt=""
                ></img>
                <h5 className="title dwc-parks-view-button">Right Face</h5>
                <div className="dwc-parks-view-button">
                  <Button
                    name="right"
                    className="btn-round"
                    //color="info"
                    type="button"
                    // onClick={(e) => selectOrientation("right")}
                  >
                    Select &nbsp; <i className="far fa-hand-pointer"></i>
                  </Button>
                </div>
              </div>
            </Card>
          </Col>
          <Col lg="4" md="4" sm="6" xs="6">
            <Card className="orientation-card">
              <div
                className={
                  "team-player " +
                  (selectedOrientation === "front"
                    ? "orientation-card-selected"
                    : "")
                }
                onClick={(e) => selectOrientation("front")}
              >
                <img
                  src={require(`assets/img/detect-leopard/leopard_head_orientation_front.jpeg`)}
                  // className="dwc-parks-image-thumbnail"
                  alt=""
                ></img>
                <h5 className="title dwc-parks-view-button">Front Face</h5>
                <div className="dwc-parks-view-button">
                  <Button
                    name="front"
                    className="btn-round"
                    //color="info"
                    type="button"
                    // onClick={(e) => selectOrientation(e)}
                  >
                    Select &nbsp; <i className="far fa-hand-pointer"></i>
                  </Button>
                </div>
              </div>
            </Card>
          </Col>
          <Col lg="4" md="4" sm="6" xs="6">
            <Card className="orientation-card">
              <div
                className={
                  "team-player " +
                  (selectedOrientation === "left"
                    ? "orientation-card-selected"
                    : "")
                }
                onClick={(e) => selectOrientation("left")}
              >
                <img
                  src={require(`assets/img/detect-leopard/leopard_head_orientation_left.jpeg`)}
                  // className="dwc-parks-image-thumbnail"
                  alt=""
                ></img>
                <h5 className="title dwc-parks-view-button">Left Face</h5>
                <div className="dwc-parks-view-button">
                  <Button
                    name="left"
                    className="btn-round"
                    //color="info"
                    type="button"
                    // onClick={(e) => selectOrientation(e)}
                  >
                    Select &nbsp; <i className="far fa-hand-pointer"></i>
                  </Button>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <WizardFooter
          isNextAllowed={selectedOrientation ? true : false}
        ></WizardFooter>
      </div>
    </>
  );
}

export default SelectOrientation;
