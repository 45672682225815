import React from "react";

// reactstrap components
import {
  Container,
  Row,
  CardBody,
  Card,
  Col,
} from "reactstrap";

// core components
//import IndexNavbar from "components/Navbars/IndexNavbar.js";
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";

function AbouDWC(props) {
  const [pills, setPills] = React.useState("2");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const queryParams = new URLSearchParams(props.location.search);

  return (
    <>
      {SwitchNavBar(queryParams.get("source"))}
      <div className="wrapper">
        <SubPageHeader title="Department of Wildlife Conservation" />

        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h3 className="title">History</h3>
                  <p>
                    The first attempt to stop the destruction of wildlife
                    resources of Sri Lanka was made in 1889, by the Conservator
                    of Forests, Colonel Clark, R.A, He brought the disastrous
                    effects of the commercial exploitation of wildlife resources
                    in the country, to the notice of the Government of Ceylon
                    and advocated immediate legislation which would forbid the
                    destroying wild animals, for the export of hides. His
                    successor in office, Mr. A.F. Broun continued the agitation
                    and persuaded the Government to pass two Ordinances, titled
                    “An Ordinance to prevent the wanton destruction of
                    elephants, buffaloes and other game” and “An Ordinance to
                    readjust the customs duties leviable on firearms and to
                    impose an export duty on certain hides and horns”.
                  </p>
                  <br />
                  <p>
                    In the subsequent years, after a concentrated effort,
                    protectionists of the country managed to see a proclamation
                    which prohibited the export of deer hides for trade.
                    Further, it was decided to employ paid watchers within
                    selected and well defined localities, to put a stop to the
                    illegal practice of killing wild animals.
                  </p>
                  <br />
                  <p>
                    Around the turn of the century, on the advice of the
                    Conservator of Forests, two vast stretches of uninhabited
                    country for experimental purposes, Yala an area of 160 sq.
                    miles between Menik Ganga and Kumbukkan Oya and Wilpattu –
                    an area of 256 sq. miles in the North-Western and North
                    Central provinces, were proclaimed as Reserves under the
                    Forest Ordinance.
                  </p>
                  <p>
                    Towards the end of 1940’s, the Government realized the
                    necessity to establish a separate Department to enforce the
                    Fauna and Flora Protection Ordinance and to manage the
                    increasing number of wildlife reserves in the country.
                    Accordingly, the Wildlife Department was established in
                    October 1949. Conservator of Forests continued as the Acting
                    Warden, until the new Wildlife Warden, Mr. C.W. Nicolas, was
                    appointed on the 1st of October 1950.
                  </p>
                  <br />
                  <p>
                    Within the first decade, cadre of the department was
                    gradually expanded and deployed to protect the wildlife
                    protected areas in the country. New wildlife Protected Areas
                    in the GalOya basin and the Yala Block II also were
                    declared.
                  </p>
                  <br />
                  <p>
                    Most significant step taken within this period was the
                    appointment of a committee for the protection of the
                    wildlife in 1957. Subsequently, recommendations of this
                    committee on wildlife protected area development,
                    establishment of new protected areas, amendment of Fauna and
                    Flora Protection Ordinance with special reference to wild
                    elephants, establishment of corridors, improvement of
                    organization of Wildlife Department gave rise to a notable
                    progress in those fields.
                  </p>
                  <br />
                  <p>
                    The challenge in the hands of the Department of Wildlife
                    Conservation from 1960 onwards was to ensure the protection
                    of wildlife resources against the large scale multi-purpose
                    development projects implemented in the country. A string of
                    wildlife protected areas was established in the Mahaweli
                    region, with the objective of providing shelter for the wild
                    animal populations, especially the wild elephants, displaced
                    due to large scale forest clearings under the accelerated
                    Mahaweli Development Project. United States Agency for
                    International Development (USAID) provided financial
                    assistance through a special project titled Mahaweli
                    Environmental Project (MEP), for the establishment and
                    development of these protected areas.
                  </p>
                  <br />
                  <br />
                  <p>
                    Since 1993, a special project was launched under the title
                    of “Development of Wildlife Conservation and Protected Area
                    Management”, within the Department. Under this project,
                    funded by UNDP/FAO through Global Environmental Facility
                    (GEF), long term management plans were prepared for all
                    leading wildlife protected areas and an intensive human
                    resource development program was implemented. Increase of
                    the departmental cadre, research on wildlife, production of
                    public educational materials, formulation of a wild elephant
                    conservation strategy, were the other outcomes of this
                    project, which concluded by the end of 1999.
                  </p>
                  <br />
                  <h3 className="title">Organization Structure</h3>
                  <br />
                  <img
                    alt="Tala park map"
                    src={require("assets/img/aboutDWC/orgStructure.png")}
                  ></img>
                  <h3 className="title">Wildlife Policy</h3>
                  <p>
                    “Conservation of Wildlife Heritage for Present & Future
                    Generation”
                  </p>
                  <br />

                  <h3 className="title">Vision and Mission</h3>
                  <p>
                    The mission statement of the Department of Wildlife
                    Conservation (DWLC) is, “To conserve wildlife and nature by
                    the sustainable utilization of men, material and land
                    through participatory management, research, education and
                    law enforcement and ensure the maintenance of biodiversity
                    and forest cover as exist today”.
                  </p>
                  <br />
                  <h3 className="title">Wildlife Policy – Overview</h3>
                  <p>
                    The first National Policy on Wildlife Conservation was
                    approved by cabinet in June 1990. The present National
                    Wildlife Policy addresses many of the same issues in updated
                    form, while also adding some points that respond to the
                    evolving needs of Sri Lankan society and the additional
                    mandates of the Convention on Biological Diversity (CBD),
                    which Sri Lanka ratified in 1994. The convention is
                    important because it provides a new context for managing
                    wildlife resources, by emphasizing the three themes of
                    conservation, sustainable use and benefits sharing, and by
                    recognizing that these must be balanced and linked if
                    sustainable development is to be achieved.
                  </p>
                  <br />
                  <p>
                    The department redesigned the wildlife policy to suit the
                    modern conservation concepts and practices and the
                    redesigned policy was approved by the cabinet of ministers
                    (Cabinet Paper 00/1034/34/019). The cabinet memorandum was
                    submitted for approval on 9th June 2000 by the Hon. Minister
                    of Public Administration and Home Affairs, Ranthnasiri
                    Wikramanayake. The policy was designed by a
                    multidisciplinary task force which comprised of experts in
                    the areas of biodiversity as well as economics and
                    sociology. Renowned private sector personals also
                    contributed to this task.
                  </p>
                  <br />
                  <h3 className="title">
                    NATIONAL WILDLIFE POLICY FOR SRI LANKA
                  </h3>

                  <h4>PREAMBLE</h4>
                  <p>
                    The idea conservation has evolved over time to its
                    present-day meaning of managing the Earth’s living resources
                    for the benefit of people and others alive today, without
                    threatening the interests of those who will be alive
                    tomorrow. This is linked to the idea of sustainable
                    development, which can be described as seeking happiness and
                    prosperity for all in ways that show we intend to say on
                    Earth, rather than to loot it and move on. Thus, the key to
                    conservation is to have compassion for people living in the
                    future or far away, and for the other forms of life that
                    share our world, while also showing an enlightened
                    self-interest towards ourselves and our immediate society.
                  </p>
                  <br />
                  <p>
                    Conservation is important because the Earth’s living
                    resources are valuable. Natural ecosystems, for example’
                    help maintain supplies of fresh water, and protect people
                    from floods, land-slides, soil erosion, siltation and the
                    effects of climate change. Wild species depend on such
                    ecosystems and can be used as important sources of foods,
                    medicines and other subsistence and trade goods, while
                    providing opportunities for recreation, tourism, science and
                    education. Genetic resources are contained within wild
                    species and can be used to improve crop plants and livestock
                    breeds, for instance by making them resistant to pests and
                    diseases. For these reasons, a comprehensive and effective
                    approach to the sustainable management of protected areas
                    and wildlife resources is needed.
                  </p>
                  <br />
                  <br />
                  <p>
                    The first National Policy on Wildlife Conservation 1990 was
                    approved by cabinet in June 1990. The present National
                    Wildlife Policy addresses many of the same issues in updated
                    form, while also adding some points that respond to the
                    evolving needs of Sri Lankan society and the additional
                    mandates of the convention on Biological Diversity, which
                    Sri Lanka ratified in 1994. The convention is important
                    because it provides a new context for managing wildlife
                    resources, by emphasizing the three themes of conservation,
                    sustainable use and benefits sharing, and by recognizing
                    that these must be balanced and linked if sustainable
                    development is to be achieved.
                  </p>
                  <br />
                  <br />
                  <p>
                    The National Wildlife Policy the commitment of Government to
                    conserve wildlife resources for the benefit of present and
                    future and research in a transparent and equitable manner.
                    It does so by linking together the activities, interests and
                    perspectives of the people who use and benefit from wildlife
                    resources with those of professional wildlife managers and
                    scientists. It is the intention of Government to define a
                    strategy to implement this policy as soon as possible
                    through a Biodiversity Conservation Action Plan, supported
                    by such legislative measures as may be necessary to achieve
                    harmony and success among all those who seek to promote
                    conservation and sustainable development in Sri Lanka.
                  </p>
                  <br />

                  <h3 className="title">NATIONAL WILDLIFE POLICY IN DETAIL</h3>

                  <p>
                    <h4>
                      Objectives of the National Wildlife Policy are follows;
                    </h4>
                    <ul className="ul-why-identify">
                      <li>
                        To conserve wildlife resources, through protection,
                        research, education, sustainable use and benefit
                        sharing, for the benefit of present and future
                        generation.
                      </li>
                      <li>
                        To maintain ecological processes and life-sustaining
                        systems, with particular regard to primary production,
                        hydrological balance, nutrient cycles, and prevention of
                        erosion, siltation, drought and flood.
                      </li>
                      <li>
                        To manage all components of genetic diversity, as
                        resources to improve crop plant and farm animal, and to
                        develop in a fair and equitable manner new product and
                        processes through bio-prospecting.
                      </li>
                      <li>
                        To ensure sustainable use and equitable sharing of
                        benefits, arising from the direct and indirect use of
                        wildlife resources and ecosystems.
                      </li>
                      <li>
                        To conserve native and endemic species and their
                        habitats, so as to maintain the overall species richness
                        and ecological integrity of the country.
                      </li>

                      <li>
                        To encourage the development of biological repositories,
                        for the purposes of conservation education and science.
                      </li>
                      <li>
                        To encourage the private sector and communities to join
                        as a full partner in all aspects of the
                        wildlife-conservation process.
                      </li>
                    </ul>
                  </p>

                  <br />

                  <p>
                    <h4>
                      National Policy on Protected Area Management and wildlife
                      Conservation
                    </h4>
                    <ul className="ul-why-identify">
                      <li>
                        To develop national strategies, plans and program for
                        wildlife conservation, in line with appropriate national
                        and international standards.
                      </li>
                      <li>
                        To protect viable and representative samples of all
                        ecosystems, including sites of special scientific
                        interest, and wherever necessary to enlarge and connect
                        protected areas to create viable conservation units.
                      </li>
                      <li>
                        To take urgent steps to conserve all remaining natural
                        wet zone forests, which are under-represented in the
                        national systems of protected areas.
                      </li>
                      <li>
                        To identify, classify manage and monitor all protected
                        areas, on the basis of appropriate scientific studies
                        and agreed criteria.
                      </li>
                      <li>
                        To manage all protected areas according to approved
                        management plans, which will be reviewed and revised
                        regularly, and implemented by staff at the field level
                        who will be afforded such authority and resources as
                        they need to do so effectively.
                      </li>
                      <li>
                        To ensure that protected areas are internally zoned
                        according to accepted criteria, to reflect the different
                        resources within each zone and the most appropriate
                        sustainable use of resources.
                      </li>
                      <li>
                        To manage all protected areas the context of their
                        surrounding landscapes, taking into account the
                        ecological, social and economic links between natural
                        and human systems.
                      </li>
                      <li>
                        To promote active, ecosystem-based management of all
                        protected areas, including the eradication wherever
                        possible of alien and invasive species, subject to
                        though consideration of the environmental impacts of
                        these interventions.
                      </li>
                      <li>
                        To regulate the importation of alien organisms,
                        including genetically modified organisms, so as to
                        minimize risks to the integrity of Sri Lanka’s
                        biodiversity.
                      </li>
                      <li>
                        To prepare and implement species recovery plant for all
                        endangered species, using objective criteria for their
                        identification developed by a national task force or
                        other expert consultative mechanism.
                      </li>
                      <li>
                        To encourage and enable the sustainable the development
                        of communities living around protected areas, by
                        ensuring that local people are consulted in the process
                        of decision making, actively participate in
                        implementation, and receive direct benefits from the
                        management of protected areas.
                      </li>
                      <li>
                        To facilitate eco-tourism in protected areas, to the
                        extent that it provides benefits to local people and
                        does not damage the ecosystem concerned.
                      </li>
                    </ul>
                  </p>

                  <p>
                    <h4>
                      National Policy on institutional Support for Wildlife
                      Conservation
                    </h4>
                    <ul className="ul-why-identify">
                      <li>
                        To provide adequate support to wildlife resource
                        managers, and to reorient, strengthen and decentralize
                        their institutions as necessary to enable them to
                        accomplish their task effectively.
                      </li>
                      <li>
                        To amend or revise legislation as necessary to support
                        the implementation of this policy.
                      </li>
                      <li>
                        To promote research and education as valuable
                        contributors to the national effort on wildlife
                        conservation.
                      </li>
                      <li>
                        To value the traditional knowledge of sustainable
                        ecosystem use possessed by the people of Sri Lanka and
                        incorporate this as appropriate within
                        wildlife-management systems.
                      </li>
                      <li>
                        To encourage ex-situ conservation measures, where they
                        can be shown to contribute to wildlife conservation,
                        environmental education and the scientific understanding
                        of how to use biodiversity sustainably.
                      </li>
                    </ul>
                  </p>

                  <p>
                    <h4>National Policy on Inter-sectoral linkages</h4>
                    <ul className="ul-why-identify">
                      <li>
                        To monitor events and take action needed to maintain
                        consistency between the national wildlife policy and
                        other sectoral and inter-sectoral policies.
                      </li>
                      <li>
                        To promote co-operation among stakeholders through
                        participatory decision making at all levels.
                      </li>
                    </ul>
                  </p>

                  <br />
                  <h3 className="title">DEFINITIONS OF KEY CONCEPTS</h3>

                  <h4>Biological diversity: </h4>
                  <p>
                    The total variety of living things at the genetic, species
                    and ecosystem level.
                  </p>
                  <br />
                  <h4>Biological repository: </h4>
                  <p>
                    A collection of organisms or other living material, such as
                    a botanical or zoological garden, an aquarium, butterfly
                    farm, insect house, bird park, aviary, or a germ-plasm
                    collection, or a collection of preserved specimens
                    established for scientific research, such as a museum.
                  </p>
                  <br />
                  <h4>Bioprospecting: </h4>
                  <p>
                    A collection of organisms or other living material, such as
                    a botanical or zoological garden, an aquarium, butterfly
                    farm, insect house, bird park, aviary, or a germ-plasm
                    collection, or a collection of preserved specimens
                    established for scientific research, such as a museum.
                  </p>
                  <br />
                  <h4>Ecosystem: </h4>
                  <p>
                    All the organisms in a particular place and time, including
                    all the relationship amongst them, together with all the
                    physical features of light, heat moisture, wind and
                    chemistry that affect them.
                  </p>
                  <br />
                  <h4>Eco tourism: </h4>
                  <p>
                    Nature based tourism that is sustainable, includes
                    environmental education and supports conservation.
                  </p>
                  <br />
                  <h4>Endemic: </h4>
                  <p>
                    An organism that occurs naturally only in a named place.
                  </p>
                  <br />
                  <h4>Environment: </h4>
                  <p>Everything that surrounds an organism and effects it.</p>
                  <br />
                  <h4>Protected area: </h4>
                  <p>
                    An area set aside primarily to preserve natural ecosystems,
                    to maintain ecological processes and to manage populations
                    of native wildlife.
                  </p>
                  <br />
                  <h4>Species: </h4>
                  <p>
                    A group of organisms capable of interbreeding freely with
                    each other but not with members of other species, usually
                    identified using distinguishing physical characteristic.
                  </p>
                  <br />
                  <h4>Stakeholder: </h4>
                  <p>
                    Someone with an interest in what happens to a resource,
                    usually because there is something to be gained or lost in a
                    dispute over it.
                  </p>
                  <br />
                  <h4>Sustainable development: </h4>
                  <p>
                    A process by which the needs of current or local people are
                    met in ways that preserve or enhance the ability of future
                    or distant people to do the same.
                  </p>
                  <br />
                  <h4>Wildlife: </h4>
                  <p>
                    Plants and animals that owe their existence to natural
                    phenomena or to processes that occur autonomously.
                  </p>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default AbouDWC;
