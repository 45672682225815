import React from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Container,
  Row,
  Col,
  InputGroupText,
  Collapse,
  Badge,
} from "reactstrap";

import Select from "react-select";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import { useWizard } from "react-use-wizard";

import { ToastContainer, toast } from "react-toastify";
import { react_select_styles } from "../../utils/React-Select-Styling.js";
import { NationalParkList } from "../../variables/AllNationalParks.js";

import LoadingOverlay from "react-loading-overlay";
import PulseLoader from "react-spinners/PulseLoader";

import { SearchBySearchQuery } from "../../utils/SearchBySearchQueryPromise";
import { SearchByFilters } from "../../utils/SearchByFiltersPromise";
import { GetAllLocationsForPark } from "../../utils/GetAllLocationsForParkPromise";
import { AIAssistedMatch } from "../../utils/AIAssistedMatchPromise";

import config from "../../config.json";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";

function MatchLeopardResults(props) {
  const [searchResults, setSearchResults] = React.useState([]);

  const [curentResultIndex, setCurentResultIndex] = React.useState(0);
  const [curentResultImg, setCurentResultImg] = React.useState(null);
  const [allTerritoryLocations, setAllTerritoryLocations] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filterZone, setFilterZone] = React.useState("");
  const [filterGender, setFilterGender] = React.useState("");
  const [showLoadingView, setShowLoadingView] = React.useState(false);
  const [rightFocus, setRightFocus] = React.useState(false);
  const national_parks_list = NationalParkList;
  const [park, setPark] = React.useState("1");
  const [selectedAreaValue, setSelectedAreaValue] = React.useState(null);
  const [isAiAssisted, setAiAssisted] = React.useState(true);
  const [isSearchCompleted, setIsSearchCompleted] = React.useState(false);
  const [isFitersOpen, setIsFitersOpen] = React.useState(false);
  const [showLowConfidenceWarning, setShowLowConfidenceWarning] =
    React.useState(true);

  const { previousStep } = useWizard();

  function showLoading(isShown) {
    return (
      <LoadingOverlay
        active={isShown}
        spinner={<PulseLoader />}
        text=""
      ></LoadingOverlay>
    );
  }

  function showAlert(message) {
    //("Showing Alert : " + JSON.stringify(message));
    toast.error(message, { containerId: "SearchError" });
  }

  function changeSearchQuery(event) {
    setFilterGender(null);
    setFilterZone(null);

    // console.log("Changing the value of 'searchQuery' to " + event.target.value);

    setSearchQuery(event.target.value);
  }

  function getNextImageForMatching() {
    var nextIndex = curentResultIndex + 1;
    if (nextIndex >= searchResults.length) {
      return;
    }
    setCurentResultIndex(nextIndex);

    //console.log("Get me images of  : " + nextIndex);

    // Future reference why the hardcoded path :
    // https://stackoverflow.com/questions/41769385/react-cant-find-module-trying-to-pass-cell-image-path-variable#
    // Fix this

    setCurentResultImg(
      require("assets/img/identified-leopards/YALA_NP/" +
        searchResults[nextIndex].officialnamecode +
        "/thumbnail.jpg")
    );
  }

  function getPrevImageForMatching() {
    var prevIndex = curentResultIndex - 1;
    if (prevIndex <= -1) {
      return;
    }
    setCurentResultIndex(prevIndex);

    //console.log("Get me images of  : " + prevIndex);

    // Future reference why the hardcoded path :
    // https://stackoverflow.com/questions/41769385/react-cant-find-module-trying-to-pass-cell-image-path-variable#
    // Fix this

    setCurentResultImg(
      require("assets/img/identified-leopards/YALA_NP/" +
        searchResults[prevIndex].officialnamecode +
        "/thumbnail.jpg")
    );
  }

  function applySearchFilters(skipAiMatch) {
    setIsSearchCompleted(false);
    setCurentResultIndex(0);
    setCurentResultImg(require("assets/img/loading-icon.svg"));

    if (searchQuery != null && searchQuery != "") {
      SearchBySearchQuery(searchQuery, park)
        .then((response) => {
          // console.log("$$$ FROM FILTER 1 : " + JSON.stringify(response));
          return response;
        })
        .then((response) => {
          // console.log("$$$ FROM FILTER 2 : " + JSON.stringify(response));
          if (!skipAiMatch && isAiAssisted) {
            // call match endpoint here
            return AIAssistedMatch(response, props.uuid, props.orientation)
              .then((response) => {
                console.log("$$$ FROM PY : " + JSON.stringify(response));
                return response;
              })
              .catch(function (error) {
                console.log("Error: " + error.message);
                return [];
              });
          } else {
            console.log("NOT AI ASSISTED. RETURNING AS RECEIVED..");
            return response;
          }
        })
        .then((response) => {
          console.log("$$$ FROM FILTER 3 : " + JSON.stringify(response));
          setSearchResults(response);
          setCurentResultImg(
            require("assets/img/identified-leopards/YALA_NP/" +
              response[0].officialnamecode +
              "/thumbnail.jpg")
          );
          setIsSearchCompleted(true);
        })
        .catch(function (error) {
          showAlert("Error: " + error.message);
        });
    } else {
      SearchByFilters(null, filterZone, filterGender)
        .then((response) => {
          // console.log("$$$ FROM FILTER 2 : " + JSON.stringify(response));
          if (!skipAiMatch && isAiAssisted) {
            // call match endpoint here
            return AIAssistedMatch(response, props.uuid, props.orientation)
              .then((response) => {
                console.log("$$$ FROM PY : " + JSON.stringify(response));
                return response;
              })
              .catch(function (error) {
                console.log(" x x Error: " + error.message);
                showAlert("Error: " + error.message);
                return [];
              });
          } else {
            console.log("NOT AI ASSISTED. RETURNING AS RECEIVED..");
            return response;
          }
        })
        .then((response) => {
          console.log(" y y  response: " + JSON.stringify(response));
          if (response.length != 0) {
            console.log(" response is empty!!: " + JSON.stringify(response));

            response = [response[0]];

            setSearchResults(response);
            setCurentResultImg(
              require("assets/img/identified-leopards/YALA_NP/" +
                response[0].officialnamecode +
                "/thumbnail.jpg")
            );
          }
          setIsSearchCompleted(true);
        })
        .catch(function (error) {
          console.log(" Error occured :: " + error);
          //showAlert("Error: " + error.message);
        });
    }
  }

  function getAllLocations(parkid) {
    GetAllLocationsForPark(parkid)
      .then((response) => {
        setAllTerritoryLocations(response);
      })
      .catch((err) => {
        //console.log("Error captured!!! : " + err);

        showAlert(
          "Unable to fetch all seen area locations. Error: " + err.message
        );
      });
  }

  function getAndSaveMatchedImg() {
    setShowLoadingView(true);
    // console.log(
    //   "Current selected leopard : " +
    //     JSON.stringify(searchResults[curentResultIndex])
    // );
    fetch(
      config.APP_URLS.BACKEND_SERVER_API +
        "/web/identify-given-leopard/" +
        props.uuid +
        "/combine-images",
      {
        method: "POST",
        body: JSON.stringify({
          target_leopard_code:
            searchResults[curentResultIndex].officialnamecode,
          nickname: searchResults[curentResultIndex].nickname,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setShowLoadingView(false);
        // console.log("WATERMARKED BASE64 : " + JSON.stringify(json));

        // If no results found, show toast and exit
        if (json.status != "success") {
          showAlert("Unable to download the matched image");
          return;
        } else {
          const base64string = json.watermarkedImageBase64;
          const pageImage = new Image();
          pageImage.src = "data:image/jpg;base64," + base64string;
          pageImage.onload = function () {
            const canvas = document.createElement("canvas");
            canvas.width = pageImage.naturalWidth;
            canvas.height = pageImage.naturalHeight;

            const ctx = canvas.getContext("2d");
            ctx.imageSmoothingEnabled = false;
            ctx.drawImage(pageImage, 0, 0);
            // console.log(canvas, pageImage);

            let fileName = props.uuid;
            const link = document.createElement("a");
            link.download = fileName + ".jpg";
            //console.log(canvas);
            canvas.toBlob(function (blob) {
              // console.log(blob);
              link.href = URL.createObjectURL(blob);
              link.click();
            });
          };
        }
      })
      .catch((err) => {
        setShowLoadingView(false);
        //console.log("Error captured!!! : " + err);

        showAlert(
          "Unable to fetch all seen area locations. Error: " + err.message
        );
      });
  }

  function getBadgeForConfidence(resultIndex, confidence, matchedpoints, officialnamecode, nickname) {
    console.log("SEARCH RES : " + JSON.stringify(searchResults));
    console.log("Confidence : " + confidence);
    if (confidence == "Low") {
      return (
        <div>
          <Row>
            <Badge color="info" className="mr-1">
              {"Match #" + (resultIndex + 1)}
            </Badge>
          </Row>
          <Row>
            <Badge color="primary" className="mr-1">
              {"Confidence : " + confidence}
            </Badge>
          </Row>
          <Row>
            <Badge color="info" className="mr-1">
              {"Matched Points : " + matchedpoints}
            </Badge>
          </Row>
          <Row>
            <Badge color="primary" className="mr-1">
              {officialnamecode +" - "+nickname}
            </Badge>
          </Row>
        </div>
      );
    } else if (confidence == "High") {
      return (
        <div>
          <Row>
            <Badge color="info" className="mr-1">
              {"Match #" + (resultIndex + 1)}
            </Badge>
          </Row>
          <Row>
            <Badge color="success" className="mr-1">
              {"Confidence : " + confidence}
            </Badge>
          </Row>
          <Row>
            <Badge color="info" className="mr-1">
              {"Matched Points : " + matchedpoints}
            </Badge>
          </Row>
          <Row>
            <Badge color="success" className="mr-1">
              {officialnamecode +" - "+nickname}
            </Badge>
          </Row>
        </div>
      );
    }
  }

  function getLocationsForNP(selectedNP) {
    // console.log(">>>>>>>>> SELECTED NP : " + JSON.stringify( selectedNP))
    setSelectedAreaValue(null);
    setFilterZone(null);
    setPark(selectedNP);
    getAllLocations(selectedNP);
  }

  function openCloseFilters() {
    if (isFitersOpen) {
      setIsFitersOpen(false);
    } else {
      setIsFitersOpen(true);
    }
  }

  React.useEffect(() => {
    console.log("PROPS FROM upload : " + JSON.stringify(props));
    getAllLocations(park);
    applySearchFilters(false);
  }, []);

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <div>
        <ToastContainer
          enableMultiContainer
          containerId={"SearchError"}
          position={toast.POSITION.TOP_RIGHT}
        />
        <div>
          <p>
            The leopard image is being matched with the entire database with the
            help of artificial intelligence.{" "}
          </p>
          <p>
            You can filter the database to narrow down the search or turn off
            the AI assisted match to do a manual match.{" "}
          </p>

          <label>{"Show More Filters : "}&nbsp;</label>
          <Switch
            onChange={(val, event) => openCloseFilters(val)}
            onClick={(val, event) => openCloseFilters(val)}
            disabled={false}
            checkedChildren="On"
            unCheckedChildren="Off"
            defaultChecked={isFitersOpen}
            checked={isFitersOpen}
            className="switch-width-control"
          />

          <Collapse isOpen={isFitersOpen}>
            <hr />
            <FormGroup>
              <Row>
                <Col md="12" lg="12" sm="12">
                  <InputGroup className={rightFocus ? "input-group-focus" : ""}>
                    <Input
                      placeholder="Search By Nick Name or Official Codename"
                      type="text"
                      autoFocus
                      onFocus={() => setRightFocus(true)}
                      onBlur={() => setRightFocus(false)}
                      onChange={(e) => changeSearchQuery(e)}
                    ></Input>
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_zoom-bold"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4" lg="4" sm="6">
                  <FormGroup>
                    <label>National Park</label>
                    <Select
                      //Value={park}
                      defaultValue={national_parks_list[0]}
                      name="zone"
                      onChange={(e) => getLocationsForNP(e.value)}
                      className="searchable-dropdown basic-single"
                      classNamePrefix="select"
                      isSearchable={false}
                      options={national_parks_list}
                      // rounded corners to match others
                      styles={react_select_styles}
                    />
                    {/* <Input placeholder="Yala National Park" disabled>
                        Yala National Park
                      </Input> */}
                  </FormGroup>
                </Col>
                <Col md="4" lg="4" sm="6">
                  <FormGroup>
                    <label>Seen Area</label>
                    <Select
                      onChange={(e) => setFilterZone(e.value)}
                      className="searchable-dropdown basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      name="seen-area"
                      options={allTerritoryLocations}
                      isDisabled={
                        searchQuery != null && searchQuery != "" ? true : false
                      }
                      // rounded corners to match others
                      styles={react_select_styles}
                    />
                  </FormGroup>
                </Col>

                <Col md="4" lg="4" sm="6">
                  <FormGroup>
                    <label>Gender</label>
                    <Select
                      name="filterGender"
                      onChange={(e) => setFilterGender(e.value)}
                      className="searchable-dropdown basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      options={[
                        { value: "", label: "Unknown" },
                        { value: "male", label: "Male" },
                        { value: "female", label: "Female" },
                      ]}
                      // rounded corners to match others
                      styles={react_select_styles}
                      isDisabled={
                        searchQuery != null && searchQuery != "" ? true : false
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4" lg="4" sm="6">
                  <FormGroup>
                    <label>{"AI Assisted Match "}&nbsp;</label>
                    <Switch
                      onChange={(val, event) => setAiAssisted(val)}
                      onClick={(val, event) => setAiAssisted(val)}
                      disabled={false}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                      defaultChecked={isAiAssisted}
                      checked={isAiAssisted}
                      className="switch-width-control"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col className="text-center">
                  <Button
                    className="btn-round"
                    color="info"
                    type="button"
                    size="lg"
                    onClick={() => applySearchFilters()}
                  >
                    Filter
                  </Button>
                </Col>
              </Row>
              <br />
            </FormGroup>
          </Collapse>
        </div>

        <hr />

        <div className="text-center">
          {!isAiAssisted && (
            <Row>
              <Col lg="6" md="6" sm="6" xs="6">
                <Button
                  className="btn-round"
                  //color="info"
                  type="button"
                  onClick={() => getPrevImageForMatching()}
                >
                  <i class="fas fa-angle-double-left"></i>
                  {"  Prev."}
                </Button>
              </Col>
              <Col lg="6" md="6" sm="6" xs="6">
                <Button
                  className="btn-round"
                  // color="info"
                  type="button"
                  onClick={() => getNextImageForMatching()}
                >
                  {"Next  "}
                  <i class="fas fa-angle-double-right"></i>
                </Button>
              </Col>
            </Row>
          )}

          {/* {searchResults.length > 0 && ( */}
          <div>
            <Row>
              <Col
                lg="6"
                md="6"
                sm="12"
                xs="12"
                className="match-leopard-sidebyside-column"
              >
                <img
                  src={"data:image/png;base64," + props.croppedBase64}
                  alt="Your Image"
                  className="match-leopard-sidebyside-img"
                />
              </Col>
              <Col
                lg="6"
                md="6"
                sm="12"
                xs="12"
                className="match-leopard-sidebyside-column"
              >
                <img
                  src={curentResultImg}
                  alt="Searched Leopard"
                  className="match-leopard-sidebyside-img"
                />
                {isSearchCompleted && searchResults.length > 0 && (
                  <div>
                   {/* <div className="match-leopard-sidebyside-bottom-right-text">
                      {searchResults[curentResultIndex].officialnamecode+
                        " - " +
                        searchResults[curentResultIndex].nickname}
                      </div>*/}
                    
                    {isAiAssisted && (
                      <div className="match-leopard-sidebyside-top-left-confidence">
                        {getBadgeForConfidence(
                          curentResultIndex,
                          searchResults[curentResultIndex].confidence,
                          searchResults[curentResultIndex].matchedpoints,
                          searchResults[curentResultIndex].officialnamecode,
                          searchResults[curentResultIndex].nickname
                        )}
                      </div>
                    )}
                  </div>
                )}

                {showLowConfidenceWarning &&
                  searchResults.length > 0 &&
                  searchResults[0].confidence != "High" && (
                    <div class="low-result-overlay">
                      <Row>
                        <Col lg="12" md="12" sm="12" xs="12">
                          <p>
                            The probability of accuracy in identifying the
                            Leopard of this image is lower than the standard.
                            Please click the "Show Low accuracy Identification
                            result" if you consent to view it.
                          </p>
                        </Col>
                        <Col lg="12" md="12" sm="12" xs="12">
                          <Button
                            className="btn-round"
                            color="danger"
                            onClick={() => setShowLowConfidenceWarning(false)}
                          >
                            Show Low accuracy Identification Results
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}
              </Col>
            </Row>
          </div>
        </div>
        <div className="text-center">
          <Row>
            <Col lg="6" md="6" sm="6" xs="6">
              <Button
                className="btn-round"
                // size="lg"
                color="warning"
                onClick={() => previousStep(0)}
              >
                <i className="fab fas fa-undo-alt"></i>
                {" Restart"}
              </Button>
            </Col>
            <Col lg="6" md="6" sm="6" xs="6">
              {props.source != "leopardcenter" && (
                <Button
                  className="btn-round"
                  // size="lg"
                  color="info"
                  onClick={() => getAndSaveMatchedImg()}
                  disabled={
                    searchResults.length > 0 &&
                    !showLoadingView &&
                    searchResults[0].confidence === "High"
                      ? false
                      : true
                  }
                >
                  {showLoadingView ? "Saving  " : "Save Match  "}
                  {showLoadingView && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {!showLoadingView && (
                    <i className="fab fas fa-arrow-circle-down"></i>
                  )}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default MatchLeopardResults;
