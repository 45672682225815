import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import ImageCarousel from "components/projects-components/ImageCarousel.js";

//mport config from "../../config.json";

import { SwitchNavBar } from "../../../utils/SwitchNavBar.js";

function HillCountryLeopard() {
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      {SwitchNavBar()}
      <div className="wrapper">
        <SubPageHeader title="Hill Country Leopard Conservation initiative." />

        <Container>
          <ImageCarousel
            imagepaths={[
              "about-projects/hill-country-leopards/Hl1.jpg",
              "about-projects/hill-country-leopards/HL2.jpg",
              "about-projects/hill-country-leopards/HL3.jpg",
              "about-projects/hill-country-leopards/HL4.jpg",
              "about-projects/hill-country-leopards/HL5.jpg",
              "about-projects/hill-country-leopards/HL6.jpg",
              "about-projects/hill-country-leopards/HL7.jpg",
              "about-projects/hill-country-leopards/HL8.jpg",
              "about-projects/hill-country-leopards/HL9.jpg",
              "about-projects/hill-country-leopards/HL10.jpg",
              "about-projects/hill-country-leopards/HL11.jpg",
              "about-projects/hill-country-leopards/HL12.jpg",
              "about-projects/hill-country-leopards/HL13.jpg",
              "about-projects/hill-country-leopards/HL14.jpg",
              "about-projects/hill-country-leopards/HL15.jpg",
              "about-projects/hill-country-leopards/HL16.jpg",
              "about-projects/hill-country-leopards/HL17.jpg",

            ]}
          />

          <h3 className="title"> About </h3>
          <p>
          The Hill Country Leopard Conservation Initiative was started by Dushyantha Silva,  Milinda Wattegedara,  Raveendra Siriwardene and Mevan Piyasena. Renowned environmental Scientist and former Director General of the Department of wildlife Conservation (DWC),  Dr. Sumith Pilapitiya guides the initiative. The initiative was set up with the blessings of the DWC with the objective of Conserving the Leopard who roam in the Hill country Tea estates and the adjacent forests. {" "}
          </p>

          <p>
          The initiative has done many awareness programmes on the aspects of How to handle a situation if a Leopard is sighted, How to handle a situation if a Leopard is caught in a snare and the importance of Desnaring. The initiative has covered 90% of the Hill country Tea estates in Sri Lanka during the year and will continue the awareness programmes. It has also set up quick communications between the hierarchy of the DWC, the regional offices of the DWC, the Park wardens and rangers of the DWC and the chain of command of the Plantation companies , which includes the CEO down to the Assistant managers of the respective estates of the Plantation companies through Digital communication platforms. Quick communications between the stakeholders have saved many Leopard lives in the Hill country. There is a significant reduction in the number of Leopard deaths due to snaring in the Hill country as a result of the initiative. 
          </p>

          <p>The inititative also has emphasized the need for a quick reaction team of the DWC to handle Leopard related incidents in the Hill country, and the DWC has obliged by taking the first steps in establishing the new range office at Gampola, which is a central location in the Hill country.</p>
          <p>The Hill country Leopard Conservation initiative is self funded by the initiators. The initiative will continue to strive for greater Conservation of the hill country Leopard by means of meaningful and practical strategies.</p>
          <p>
            <h5> This is an on-going acivity</h5>
          </p>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default HillCountryLeopard;
