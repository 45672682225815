import React from "react";

// reactstrap components
import {
 
  Row,

  Col,
} from "reactstrap";

function AbouDWCParks_Lahugala() {
  return (
    <>
      <Row>
        <Col>
          <h3 className="title">Lahugala Kithulana National Park</h3>
          <img
            src={require("assets/img/aboutDWC/parks/Lahugala.png")}
            className="dwc-parks-image"
            alt="Lahugala Kithulana National Park"
          ></img>
          <h4>Introduction</h4>
          <p>
            Biogeographical Province 4.13.04 ( Ceylonese Monsoon Forest)
            Geographical Location Lies in the basin of the Heda Oya , 16k inland
            from the coastal town of Pottuvil in Eastern province . The Pottuvil
            – Monaragala trunk road runs through the south eastern sector of the
            park . 6 53’- 6 55’N, 81 40’-8142’E Date and History of
            Establishment Created a national park on 31 October 1980, having
            originally been established as a sanctuary on 1 July 1966.
          </p>
          <h4>ACCESS</h4>
          <p>
            <ul>
              <li>Colombo - Nuwara eliya - Badulla -Monaragala - Lahugala</li>
              <li>
                Colombo - Galle - Matara- Hambantota - Wellawaya -Monaragala -
                Lahugala
              </li>
            </ul>
          </p>
          <h4>PHYSICAL CHARACTERRISTICS</h4>
          <p>
            The terrain is flat with occasional rock outcrops . The park
            features three tanks, lahugala (243ha) , Kitulana and Senagamuwa ,
            which ultimately drain in to the Heda Oya . These tank s are largely
            silted up and support an abundance of beru grass. Climate Mean
            annual rainfall is 1650mm (McKay, 1973). There are two dry periods:
            from May to October and from mid- January to March . The north –east
            monsoon lasts from November to the end of December (Banks and Banks
            , 1985).
          </p>
          <h4>VEGITATION</h4>
          <p>
            Lying in the dry zone , the vegetation surrounding the tank is dry
            mixed evergreen forest with scrub. Common species include weera
            Drypetes sepiaria , palu Manilkara hexandra, halmilla Berrya
            cordifolia, milla Vitex pinnata, satin Chloroxylon swietenia and
            ehala Cassia fistula. Beru Sacciopelsis interrupta, a tall reedy
            grass , covers the tanks. Rivers are fringed by gallery forest.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default AbouDWCParks_Lahugala;
