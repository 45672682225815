export const ObservationList = [
  {
    DocumentType: "IMAGE",
    Path: "7.jpg",
    Name: "Panel 7",
  },
  {
    DocumentType: "IMAGE",
    Path: "8.jpg",
    Name: "Panel 8",
  },
  {
    DocumentType: "IMAGE",
    Path: "9.jpg",
    Name: "Panel 9",
  },
  {
    DocumentType: "IMAGE",
    Path: "10.jpg",
    Name: "Panel 10",
  },
  {
    DocumentType: "IMAGE",
    Path: "11.jpg",
    Name: "Panel 11",
  },
  {
    DocumentType: "IMAGE",
    Path: "12.jpg",
    Name: "Panel 12",
  },
];
