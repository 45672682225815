import React, { useEffect } from "react";

function WizardCheckBox(props) {
  console.log("Received props to WizardCheckBox  : " + JSON.stringify(props));

  function checkCheckboxStatus() {
    if (props.checked) {
      return <i class="fas fa-check-circle wizard-checkbox-checked"></i>;
    } else {
      return <i class="far fa-arrow-alt-circle-right"></i>;
    }
  }

  useEffect(() => {}, []);

  return <>{checkCheckboxStatus()}</>;
}

export default WizardCheckBox;
