import React from "react";

// reactstrap components
import {
 
  Container,

} from "reactstrap";

// core components
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import ImageCarousel from "components/projects-components/ImageCarousel.js";

import config from "../../../config.json";
//import GoogleMapReact from "google-map-react";

import { SwitchNavBar } from "../../../utils/SwitchNavBar.js";

function ProjectYalaLeopardCenter() {
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      {SwitchNavBar()}
      <div className="wrapper">
        <SubPageHeader title="Yala Leopard Center" />

        <Container>
          <ImageCarousel
            imagepaths={[
              "about-projects/yala-leopard-center/img1.jpg",           
              "about-projects/yala-leopard-center/img2.jpg",
              "about-projects/yala-leopard-center/img3.jpg",
              "about-projects/yala-leopard-center/img4.jpg",
              "about-projects/yala-leopard-center/img5.jpg",
              "about-projects/yala-leopard-center/img6.jpg",
              "about-projects/yala-leopard-center/img7.jpg",
              "about-projects/yala-leopard-center/img8.jpg",
              "about-projects/yala-leopard-center/img9.jpg",
              "about-projects/yala-leopard-center/img11.jpg",
              "about-projects/yala-leopard-center/img10.jpg"
            ]}
          />

          <h3 className="title"> About </h3>
          <p>
            The Yala Leopard Center commissioned at the Dr. Ravi Samarasinghe
            memorial hall at the Yala National Park was opened by the Director
            general of the Department of wildlife Conservation, Mr. C.
            Sooriyabandara and Leading Environmental Scientist, Dr. Sumith
            Pilapitiya. The center was handed over to the Department of Wildlife
            Conservation by its initiators, Dushyantha Silva, Milinda
            Wattegedara, Raveendra Siriwardene and Mevan Piyasena on 26.06.2020.
            The center was funded by the chairman of the Navesta Pharmaceuticals
            company, Mr. Sanjaya Jayaratne. The Information touch screen was
            donated by the e-wis company. The Artwork for the center was done by
            Pasan Seneviratne. The information application was developed by
            Pramod Sendanayake and Chathura Dushmantha, facilitated by Heshan
            Peiris. We would like to thank the Family members of the late Dr.
            Ravi Samarasinghe, a pioneer leopard researcher, for their support.
          </p>
          <p>
            This center contains information about the Leopards of Yala and
            their behaviour.
          </p>

          <p>
            The Center is housed in 2 adjacent buildings named as Whiskers Wing
            and Rosettes Wing. Whiskers Wing shows a collection of photos of
            Leopard Behaviours. It also has a digital touch screen that shows a
            range of information including the identification and other data of
            the Leopards of the Yala National Park. It also has many research
            articles on Leopards.
          </p>

          <p>
            Rosettes Wing has many information panels containing information of
            Leopards. It also has a continous display of video recordings of
            Leopard behaviour.
          </p>

          <p>
            The Aim of the center is to help the DWC to ensure enhanced
            conservation of Leopards and to make the public aware of Leopard
            behaviour at Yala National Park
          </p>

          <p>
          <h5> Handed over to public on 26th June 2020 </h5>
          </p>

          {config.MAP_API_PROVIDER === "GOOGLE" && (
            <div>
              <iframe
                width="100%"
                height="600"
                src="https://www.youtube-nocookie.com/embed/mqdUrojS77I"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="https://www.youtube-nocookie.com/embed/mqdUrojS77I"
              ></iframe>

              <iframe
                src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fpulse.lk%2Fvideos%2F2616622201920942&show_text=false&width=734&height=734&appId"
                width="100%"
                height="734"
                style={{ "border": "none", "overflow": "hidden" }}
                scrolling="no"
                frameborder="0"
                allowTransparency="true"
                allow="encrypted-media"
                allowFullScreen="true"
                title="https://www.facebook.com/plugins/video.php"
              ></iframe>
              <iframe
                src="https://www.youtube-nocookie.com/embed/iER-5_oC_rs" 
                frameborder="0"
                width="100%"
                height="734"
                style={{ "border": "none", "overflow": "hidden" }}
                scrolling="no"
                allowTransparency="true"
                allow="encrypted-media"
                allowFullScreen="true"
                title="https://www.youtube-nocookie.com/embed/iER-5_oC_rs"
              ></iframe>
            
            </div>
          )}

          <h3 className="title"> Directions </h3>

          {config.MAP_API_PROVIDER === "GOOGLE" && (
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7355.286368804325!2d81.39993922414597!3d6.278633694793964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae683cf05876bd9%3A0xd688dcf4fa3bcddc!2sYala%20Leopard%20Center!5e1!3m2!1sen!2slk!4v1602503075678!5m2!1sen!2slk"
              width="100%"
              height="450"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
              title="Google Map Location"
            ></iframe>
          )}
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default ProjectYalaLeopardCenter;
