// Documentation : https://react-select.com/styles#styles

export const react_select_styles = {
  control: (styles, state) => ({
    ...styles,
    borderRadius: 30,
    boxShadow: "#f96332",
    borderColor: state.isFocused ? "#f96332" : "hsl(0,0%,80%)",
    "&:hover": {
      borderColor: "#f96332",
    },
  }),
  multiValue: (styles) => ({ ...styles, borderRadius: 30 }),
};
