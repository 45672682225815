import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

import "react-dropzone-uploader/dist/styles.css";

import { useWizard } from "react-use-wizard";
import { ToastContainer, toast } from "react-toastify";
import ReactCrop from "react-image-crop";
import WizardFooter from "components/detect-leopard-components/WizardFooter";

import config from "../../config.json";

function MarkInterestArea(props) {
  const { handleStep } = useWizard();
  const [uploadedImgBase64, setUploadedImgBase64] = React.useState("");
  const [serverFilePath, setServerFilePath] = React.useState("");
  const [cropImgRef, setCropImgRef] = React.useState(null);
  const [showLoadingView, setShowLoadingView] = React.useState(false);
  const [isNextActionAllowed, setIsNextActionAllowed] = React.useState(false);

  const [crop, setCrop] = React.useState({
    x: 10,
    y: 10,
    width: 80,
    height: 80,
    aspect: 1 / 1,
  });
  const onImageLoaded = (image) => {
    //console.log("onImageLoaded", image);
    var coords = {};
    coords.clientHeight = image.clientHeight;
    coords.clientWidth = image.clientWidth;
    coords.naturalWidth = image.naturalWidth;
    coords.naturalHeight = image.naturalHeight;

    setCropImgRef(coords);
    //console.log("onImageLoaded cords", coords);
  };
  const onCropChange = (crop) => {
    setCrop(crop);
  };

  handleStep(() => {
    setShowLoadingView(true);
    return fetch(
      config.APP_URLS.BACKEND_SERVER_API +
        "/web/identify-given-leopard/" +
        props.uuid +
        "/crop",
      {
        method: "POST",
        body: JSON.stringify({
          serverFilePath: serverFilePath,
          imageCrop: crop,
          scaleFactors: cropImgRef,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Connection: "Keep-Alive",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setShowLoadingView(false);
        // If no results found, show toast and exit
        // console.log("CROPPED RESPONSE : " + JSON.stringify(json));
        if (json["status"] == "success") {
          // console.log("CROPPED IMAGE : " + json["croppedImageBase64"]);
          return json["croppedImageBase64"];
        }
      })
      .then((croppedB64) => {
        props.callback(croppedB64);
        setShowLoadingView(false);
      })

      .catch((err) => {
        setShowLoadingView(false);
        console.log("Error captured!!! : " + err);
        // showAlert("Unable to wait for image to upload. Error: " + err.message);
      });
  });

  function waitForFileToBeUploaded() {
    setShowLoadingView(true);
    fetch(
      config.APP_URLS.BACKEND_SERVER_API +
        "/web/get-uploaded-file/" +
        props.uuid,
      {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Connection: "Keep-Alive",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setShowLoadingView(false);
        // If no results found, show toast and exit
        if (json["status"] == "success") {
          //console.log("Uploaded img base64 : ");
          //console.log(json["uploadedImageBase64"]);
          setUploadedImgBase64(
            "data:image/png;base64," + json["uploadedImageBase64"]
          );
          setServerFilePath(json["filePathInServer"]);
        }
      })
      .catch((err) => {
        setShowLoadingView(false);
        //console.log("Error captured!!! : " + err);

        showAlert("Unable to wait for image to upload. Error: " + err.message);
      });
  }

  function showAlert(message) {
    //("Showing Alert : " + message);
    toast.error(message, { containerId: "LocationError" });
  }

  React.useEffect(() => {
    waitForFileToBeUploaded();
  }, []);

  return (
    <>
      <div>
        <Row>
          <h4>
            Mark the prominent area of the leopard image to be identified. Use
            the corners of the selection box to drag and adjust the area.
          </h4>
        </Row>
        <Row></Row>
        <Row>
          <Col className="text-center image-crop-div-background">
            {uploadedImgBase64 && (
              <div>
                <ReactCrop
                  src={uploadedImgBase64}
                  crop={crop}
                  onImageLoaded={onImageLoaded}
                  // onComplete={onCropComplete}
                  onChange={onCropChange}
                />
              </div>
            )}
            {!uploadedImgBase64 && <div>Loading...</div>}
          </Col>
          <br></br>
        </Row>
        <WizardFooter
          isNextAllowed={true}
          loading={showLoadingView}
          nextButtonText={showLoadingView ? " Loading.. " : "Next "}
        ></WizardFooter>
      </div>
    </>
  );
}

export default MarkInterestArea;
