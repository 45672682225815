import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import ImageCarousel from "components/projects-components/ImageCarousel.js";

//import config from "../../config.json";
//import GoogleMapReact from "google-map-react";

import { SwitchNavBar } from "../../../utils/SwitchNavBar.js";

function ProjectLeopardIdentification() {
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      {SwitchNavBar()}
      <div className="wrapper">
        <SubPageHeader title="Leopard Identification Guide" />

        <Container>
          <ImageCarousel
            imagepaths={[
              "about-projects/leopard-identification-guide/LI8.jpg",
              "about-projects/leopard-identification-guide/LI14.jpg",
              "about-projects/leopard-identification-guide/LI2.jpg",
              "about-projects/leopard-identification-guide/LI3.jpg",
              "about-projects/leopard-identification-guide/LI4.jpg",
              "about-projects/leopard-identification-guide/LI15.jpg",
              "about-projects/leopard-identification-guide/LI5.jpg",
              "about-projects/leopard-identification-guide/LI6.jpg",
              "about-projects/leopard-identification-guide/LI7.jpg",
              "about-projects/leopard-identification-guide/LI1.jpg",
              "about-projects/leopard-identification-guide/LI16.jpg",
              "about-projects/leopard-identification-guide/LI9.jpg",
              "about-projects/leopard-identification-guide/LI10.jpg",
              "about-projects/leopard-identification-guide/LI11.jpg",
              "about-projects/leopard-identification-guide/LI12.jpg",
              "about-projects/leopard-identification-guide/LI13.jpg",
            ]}
          />

          <h3 className="title"> About </h3>
          <p>
            A Milestone for the Team Yala Leopard Diary. The Department of
            Wildlife Conservation (DWC) recognized our identification of
            Leopards and entrusted us with publishing the official Leopard
            Identification guide of the Yala National Park (Block 1) together
            with the DWC.
          </p>
          <p>
            Today our team officially handed over our guide booklet named "
            Leopard Diary- Yala National Park- Block-01 (Volume 1)" to the
            Director-General of the Wildlife Department, Mr. Chandana
            Sooriyabandara in the presence of the Deputy Director, Research and
            training, Dr. Lakshman Peiris and other senior management of the DWC
            head office, the Assistant Director(Southern and Uva), Mr. Prasantha
            Wimaladasa, The Park warden of the Yala National Park, Mr. Pradeep
            Siyasinghe and The Deputy Park warden of the Yala National Park, Mr.
            Ranjith Sisirakumara. This guide booklet has the identities of 103
            Leopards we have identified from 2013 to 2019 August. In addition,
            we have also compiled the year of which each of these leopards was
            recorded by our team, the areas frequented by each Leopard, the
            mother - cub relationship, and any other special observations made
            by us.
          </p>

          <p>
            This guide book will be given to all field staff of the Yala
            National park which will help them identify Leopards they see in the
            park more accurately. This booklet will also help the cause of
            Leopard conservation. In order to protect and conserve, we believe
            It is imperative to obtain accurate and useful data. We have
            compiled this booklet in a user-friendly manner, the staff can make
            use of it without a hassle. We are honored and proud to pioneer the
            official Identification guide which we believe is the first in the
            world for Leopards.
          </p>
          <p>
            <h6> Published to public on 06th November 2019</h6>
            <h7>
              Leopard Diary Identification Guide (Volume 1) is available for
              download at :{" "}
              <a target="_blank" href="https://bit.ly/3sqVITv">
                <b>https://bit.ly/3sqVITv</b>
              </a>{" "}
              (400MB)
            </h7>
          </p>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default ProjectLeopardIdentification;
