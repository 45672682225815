import React from "react";

// reactstrap components
import {

  Row,
  Col,
} from "reactstrap";

function AbouDWCParks_Kaudulla() {
  return (
    <>
      <Row>
        <Col>
          <h3 className="title">Kaudulla National Park</h3>
          <img
            src={require("assets/img/aboutDWC/parks/Kaudulla.png")}
            className="dwc-parks-image"
            alt="Kaudulla National Park"
          ></img>
          <h4>Introduction</h4>
          <p>
            Kaudulla is the newly established national park in the heart of the
            cultural triangle. Kaudulla in the country and has excellent
            potential for viewing elephant and other species of wildlife. The
            location is situated in 30km away from Polonnaruwa District in North
            central province.
          </p>
          <h4>ACCESS</h4>
          <p>
            <ul>
              <li>Via Habarana, Hatharaskotuwa and Galoua Junction (197km)</li>
              <li>
                Via Habarana, 45 th km post along Habarana Polonnaruwa road
                Rotawewa.
              </li>
            </ul>
          </p>
          <h4>PHYSICAL CHARACTERRISTICS</h4>
          <p>
            Topography is varied with hills, lowlands, forest and scrub land.
            Kaudulla is an ancient irrigation tank with a capacity of 104,000
            feet acres. The main source of water is giant canal which is around
            the tank. The main annual rainfall is about 1500-2000 mm; mean
            annual temperature is 20°C.
          </p>
          <h4>VEGITATION</h4>
          <p>
            The national park vegetation consists of tropical dry mixed
            evergreen forests, abandoned chena cultivated lands, grasslands and
            wetlands. Tropical dry mixed evergreen forest predominated.
          </p>
          <h4>FAUNA</h4>
          <p>
            Twenty four species of mammals in Sri Lanka have been reported from
            this park.. The most important of them is the wild elephant the
            population of which is about 200. Twenty six species of fish are
            found in the reservoir, this fish are economically important. In
            this park 435 species of birds reported to be them endemic birds to
            Sri Lanka.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default AbouDWCParks_Kaudulla;
