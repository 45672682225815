import React from "react";
import { useWizard } from "react-use-wizard";
import { Row, Col, Button } from "reactstrap";

function WizardFooter(props) {
  // console.log("Received props to Wizard footer  : " + JSON.stringify(props));

  const { previousStep, nextStep } = useWizard();

  return (
    <>
      <Row>
        <Col lg="3" md="3" sm="1" xs="1"></Col>
        <Col lg="3" md="3" sm="5" xs="5">
          <Button
            onClick={() => previousStep()}
            className="btn-round"
            color="warning"
          >
            <i className="fab fas fa-arrow-circle-left"></i>
            {" Previous "}
          </Button>
        </Col>
        <Col lg="3" md="3" sm="5" xs="5">
          <Button
            color="info"
            //size="lg"
            className="btn-round"
            onClick={() => nextStep()}
            disabled={!props.isNextAllowed}
          >
            {props.nextButtonText ? props.nextButtonText : " Next  "}
            {props.loading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {!props.loading && (
              <i className="fab fas fa-arrow-circle-right"></i>
            )}
          </Button>
        </Col>

        <Col lg="3" md="3" sm="1" xs="1"></Col>
      </Row>
    </>
  );
}

export default WizardFooter;
