import React from "react";

// reactstrap components
import {

  Container, Card, CardHeader, CardBody, Col

} from "reactstrap";

// core components
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { VideoList } from "../../variables/AllVideos.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";
import Row from "reactstrap/lib/Row";

function LeopardVideos() {

  const [videoList, setVideoList] = React.useState(VideoList);

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      {SwitchNavBar()}
      <div className="wrapper">
        <SubPageHeader title="Leopard Videos" />
        <Container>
          <br />
          <Row>
            {videoList.map((video, index) => (
              <Col lg="6" md="6" sm="6" xs="12" key={"vids-" + index}>
                <Card>
                  <div>
                    <iframe
                      src={video.EmbedURL}
                      frameborder="0"
                      width="100%"
                      height="500px"
                      style={{ "border": "none", "overflow": "hidden" }}
                      scrolling="no"
                      allowTransparency="true"
                      allow="encrypted-media"
                      allowFullScreen="true"
                      title={video.Name}
                    ></iframe>
                    <p className="video-card-description">
                      {video.Name}
                    </p>
                  </div>
                </Card>
              </Col>
            ))}

          </Row>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default LeopardVideos;
