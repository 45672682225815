import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  CardHeader,
  Card,
  CardBody,
  Col,
} from "reactstrap";

// core components
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { SwitchNavBar } from "../../../utils/SwitchNavBar.js";
import { Link } from "react-router-dom";

function OurResearch() {
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      {SwitchNavBar()}
      <div className="wrapper">
        <SubPageHeader title="Our research" />

        <Container>
          <h3 className="title"> Our Research on Leopards </h3>
          <Row>
            <p>
              <ul>
                <li>
                  Our research team conducts continuous research at the Yala
                  National Park under a research permit issued by the Department
                  of Wildlife Conservation. The research is done with
                  supervision of the research and training department of the
                  Department of Wildlife Conservation.
                </li>
                <li>
                  The research team aims to promote greater conservation through
                  gaining greater knowledge through greater study and broad
                  basing and simplifying the knowledge gained on Leopards for
                  the benefit of all stakeholders of Conservation.
                </li>
                <li>
                  The research team as of now has presented 3 of the research
                  works done to at the Wild Lanka symposium, which is the
                  scientific forum to present research work done on the field of
                  Wildlife and is internationally acclaimed. The research team
                  will present more research work at the future symposiums and
                  scientific publications.
                </li>
                <li>
                  The research work of the team is funded through the teams’ own
                  finances.
                </li>
                <li>
                Download multi point identification research from&nbsp;
                  <a href="https://www.mdpi.com/2076-2615/12/5/660/pdf?version=1646539556">
                  <b>here.</b>
                  </a>
                </li>
              </ul>
            </p>
          </Row>
          <h5 className="title"> Research Abstracts </h5>
          <Row>
          <img
              src={require("assets/img/about-projects/research/abstract4.jpg")}
              alt="Research Abstract 3"
            ></img>
            <img
              src={require("assets/img/about-projects/research/abstract1.jpg")}
              alt="Research Abstract 1"
            ></img>
            <img
              src={require("assets/img/about-projects/research/abstract2.jpg")}
              alt="Research Abstract 2"
            ></img>
            
          </Row>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}
export default OurResearch;
