import React from "react";

// reactstrap components
import {

  Container,
  Row,
  Col,
} from "reactstrap";

// core components
//import IndexNavbar from "components/Navbars/IndexNavbar.js";
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";

function AboutYala(props) {
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const queryParams = new URLSearchParams(props.location.search);

  return (
    <>
      {SwitchNavBar(queryParams.get("source"))}
      <div className="wrapper">
        <SubPageHeader title="Yala National Park" />

        <Container>
          <h3 className="title">History</h3>
          <p>
            Yala (යාල) National Park is the most visited and second largest
            national park in Sri Lanka, bordering the Indian Ocean. The park
            consists of five blocks, two of which are now open to the public,
            and also adjoining parks. The blocks have individual names such as,
            Ruhuna National Park (Block 1), and Kumana National Park or 'Yala
            East' for the adjoining area. It is situated in the southeast region
            of the country, and lies in Southern Province and Uva Province. The
            park covers 979 square kilometres (378 sq mi) and is located about
            300 kilometres (190 mi) from Colombo. Yala was designated as a
            wildlife sanctuary in 1900, and, along with Wilpattu was one of the
            first two national parks in Sri Lanka, having been designated in
            1938. The park is best known for its variety of wild animals. It is
            important for the conservation of Sri Lankan elephants, Sri Lankan
            leopards and aquatic birds. There are six national parks and three
            wildlife sanctuaries in the vicinity of Yala. Among the largest is
            Lunugamvehera National Park. The park is situated in the dry
            semi-arid climatic region and rain is received mainly during the
            northeast monsoon. Yala hosts a variety of ecosystems ranging from
            moist monsoon forests to freshwater and marine wetlands. It is one
            of the 70 Important Bird Areas (IBAs) in Sri Lanka. Yala harbours
            215 bird species including six endemic species of Sri Lanka. The
            number of mammals that has been recorded from the park is 44, and it
            has one of the highest leopard densities in the world. The area
            around Yala has hosted several ancient civilizations. Two important
            pilgrim sites, Sithulpahuwa and Magul Vihara, are situated within
            the park. The 2004 Indian Ocean tsunami caused severe damage on the
            Yala National Park and 250 people died in its vicinity. The number
            of visitors has been on the rise since 2009, after the security
            situation in the park improved.
          </p>
          <br />
          <p>
            In 1560 Spanish cartographer Cipriano Sanchez noted Yala in his map
            "is abandoned for 300 years due to insalubrious conditions."[1]
            Chief Justice Sir Alexander Johnston wrote a detailed account on
            Yala in 1806 after travelling from Trincomalee to Hambantota. On
            March 23, 1900 the government proclaimed Yala and Wilpattu reserves
            under the Forest Ordinance.[2] Initially the extent of the reserve
            was 389 square kilometres (150 sq mi) between the Menik and
            Kumbukkan Rivers. At that time the reserve did not bear the name
            Yala. The Game Protection Society (now the Wildlife and Nature
            Protection Society) was instrumental in establishing the reserve.
            The forest area between Palatupana and Yala was declared a hunting
            site reserved only for the resident sportsmen.[2] Henry Engelbrecht
            was appointed as the first park warden. On 1 March 1938, Yala became
            a national park when the Flora and Fauna Protection Ordinance was
            passed into law by D. S. Senanayake, the minister of agriculture.
            The park consists of five blocks.[3] Subsequently, four other blocks
            were incorporated to the park. There are six national parks and
            three wildlife sanctuaries in the vicinity of Yala. Kumana National
            Park, Yala Strict Nature Reserve and Kataragama, Katagamuwa, and
            Nimalawa sanctuaries are continuous with the park.
          </p>
          <br />

          <h3 className="title">Extent of the Yala National park</h3>

          <Row>
            <Col md="36">
              <table className="tablesorter" responsive>
                <thead>
                  <tr>
                    <th width="200">Block</th>
                    <th width="300">Extent</th>
                    <th>Date added to the park</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="200">
                      <p>Block I</p>
                    </td>
                    <td width="300">
                      <p>14,101 hectares (54.44 sq mi)</p>
                    </td>
                    <td>
                      <p>1938</p>
                    </td>
                  </tr>

                  <tr>
                    <td width="200">
                      <p>Block II</p>
                    </td>
                    <td width="375">
                      <p>9,931 hectares (38.34 sq mi)</p>
                    </td>
                    <td>
                      <p>1954</p>
                    </td>
                  </tr>
                  <tr>
                    <td width="200">
                      <p>Block III</p>
                    </td>
                    <td width="375">
                      <p>40,775 hectares (157.43 sq mi) </p>
                    </td>
                    <td>
                      <p>1967</p>
                    </td>
                  </tr>
                  <tr>
                    <td width="200">
                      <p>Block IV</p>
                    </td>
                    <td width="375">
                      <p>26,418 hectares (102.00 sq mi)</p>
                    </td>
                    <td>
                      <p>1969</p>
                    </td>
                  </tr>
                  <tr>
                    <td width="200">
                      <p>Block V</p>
                    </td>
                    <td width="375">
                      <p>6,656 hectares (25.70 sq mi)</p>
                    </td>
                    <td>
                      <p>1973</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <p>Source: Sri Lanka Wetlands Information and Database</p>

          <br />

          <img
            alt="Tala park map"
            src={require("assets/img/about-yala/AboutYalaMap.png")}
          ></img>

          <br />
          <br />

          <h3 className="title">Physical features</h3>

          <p>
            The Yala area is mostly composed of metamorphic rock belonging to
            the Precambrian era and classified into two series, Vijayan series
            and Highland series. Reddish brown soil and low humic grey soil are
            prominent among six soil types. Yala is situated in the lowest
            peneplain of Sri Lanka, which extends from Trincomalee to
            Hambantota. Topographically the area is a flat and mildly undulating
            plain that runs to the coast with elevation is 30 metres (98 ft)
            close to the coast while rising in the interior to 100–125 metres
            (328–410 ft). The national park is situated in the dry semi-arid
            climatic region and rain is received mainly during the northeast
            monsoon. The mean annual rainfall ranges between 500–775 millimetres
            (19.7–30.5 in) while the mean temperature ranges between 26.4 °C
            (79.5 °F) in January to 30 °C (86 °F) in April. It is windier in
            Yala, during the southwest monsoon compared to the wind during the
            northeast monsoon with wind speeds from 23 kilometres per hour (14
            mph) to 15 kilometres per hour (9.3 mph).
          </p>
          <br />
          <p>
            Water is abundant after the northeast monsoon, but during the dry
            season surface water becomes an important factor. The bodies of
            surface water appear in the forms of streams, tanks, waterholes,
            rock pools, and lagoons. Waterholes occur in low lying places while
            rock pools of varying size are capable of containing water
            year-round, and are hence an important source of water for
            elephants. For many water birds and water buffaloes natural
            waterholes are ideal habitats. Such reservoirs are largely
            concentrated to the Block I followed by Block II. Several tanks are
            there including, Maha Seelawa, Buthawa, Uraniya, and Pilinnawa
            tanks.[2] Many rivers and streams flow in a southeasterly direction,
            originating in the highlands of adjacent Uva and central hills.
            Kumbukkan Oya in the east and Menik River and its tributaries in the
            west flow across the park, and provide an important water source in
            the dry season to wild animals of the park. Normally the streams of
            the park are dry during the drought season. These rivers and streams
            exhibit a degree of runoff fluctuations between wet and dry seasons.
            Kumbukkan Oya discharges seven times as much water in the rainy
            season than in the dry season. A number of lagoons are situated
            along the coast line of the park.[3] There are several routes to get
            to Yala from Colombo, while the route via Ratnapura and
            Tissamaharama is the shortest with 270 kilometres (170 mi).
          </p>

          <br />
          <h3 className="title">Impact of the 2004 tsunami</h3>

          <p>
            Yala lay in the direct path of the 2004 Indian Ocean tsunami, which
            impacted Sri Lanka 90 minutes after its generation.[4] The tsunami
            caused severe but localized damage on the park,[5] with around 250
            people being killed.[6] The tsunami wave was reported to be 20 feet
            (6.1 m) high. The tsunami waves reached Physical features Surface
            water becomes critical in the dry season Impact of the 2004 tsunami
            inland only through the river-mouth gaps in the coastal dunes. [7]
            Inundation distances from ranged up to 392 to 1,490 metres (429 to
            1,629 yd). The main habitats affected are scrub forest and
            grasslands. About 5,000 hectares (19 sq mi) of grassland, forest,
            and wetland were directly affected by the tsunami. The satellite
            images revealed that mean Normalized Difference Vegetation Index
            (NDVI) range from 0.245– 0.772 in the Block I and II. After the
            disaster the NDVI value fell dramatically to 0.2111. Around 60% of
            the area along the coastline has changed. The damage was worse
            closer to the sea.[5] The movement patterns of two radio collared
            elephants were analyzed. The study found out that their movements
            were consistent with behaviour prompted by immediate cues generated
            by the tsunami waves rather than a response to a "sixth sense".[8]
          </p>
          <br />
          <br />
          <h3 className="title">Flora</h3>

          <p>
            Yala National Park has a variety of ecosystems including moist
            monsoon forests, dry monsoon forests, semi deciduous forests, thorn
            forests, grasslands, marshes, marine wetlands, and sandy beaches.[3]
            The area under forest cover mainly consists of Block I and
            rangelands of open parkland (Pelessa grasslands) including some
            extensive grasslands. The forest area is restricted to around the
            Menik River while rangelands are found towards the sea side. Other
            habitat types of the Block I are tanks and water holes, lagoons and
            mangroves and chena lands. The mangrove vegetation in the Buthuwa
            lagoon is largely Rhizophora mucronata while Avicennia spp. and
            Aegiceras spp. are less abundant. The vegetation of Block II is
            similar to those of Block I, and Yalawela, once a fertile paddy
            field, represents Pitiya grasslands. The mangroves of Block II occur
            around the estuary of Menik River, which extent to 100 hectares
            (0.39 sq mi). The common mangrove plants are Rhizophora mucronata,
            Sonneratia caseolaris, Avicennia spp., and Aegiceras corniculatum.
            The lagoons of Pilinnawa, Mahapothana, and Pahalapothana are also
            located in this block.[3] The other common mangrove species are
            Sonneratia caseolaris, Acanthus ilicifolius, Excoecaria agallocha,
            and Lumnitzera racemosa. In the bare sand Crinum zeylanicum is
            found.[9]
          </p>

          <p>
            In the Blocks III, IV, and V, forests are more widespread. The
            canopy of the forest mainly contains Drypetes sepiaria and Manilkara
            hexandra plant species. The Pitiya grasslands are important for
            grazing animals. Cynodon barberi is the common grass in Pitiya
            grasslands while Zoysia matrella becomes dominant near the beach.
            Among 300 odd floral species are Manilkara hexandra, Drypetes
            sepiaria, Ceylon Satinwood, Terminalia arjuna, limonia, Berrya
            cordifolia, Randia dumetorum, Pleurostylia opposita, Gymnema
            sylvestre, Bell mimosa, Neem, Banyan, Toothbrush tree, Schleichera
            oleosa, Vitex pinnata, Indian blackberry, Gmelina asiatica, Carissa
            spinarum, Euphorbia antiquorum, and Acacia eburnea. [2] In the
            seasonally flooded areas of Block II, a wild species of rice is
            found. Glenniea unijuga is an endemic plant species found around the
            wetlands of the park. Munronia pumila, Salacia reticulata, and
            Asparagus racemosus are some medicinal plants. [3]
          </p>
          <br />
          <br />
          <h3 className="title">Fauna</h3>
          <br />

          <h5>Birds</h5>

          <p>
            Yala is one of the 70 Important Bird Areas (IBAs) in Sri Lanka.[10]
            Of 215 bird species of the park, seven are endemic to Sri Lanka.[2]
            They are Sri Lanka grey hornbill, Sri Lanka junglefowl, Sri Lanka
            wood pigeon, crimson-fronted barbet, black-capped bulbul,
            blue-tailed bee-eater and brown-capped babbler. The number of
            waterbirds inhabiting wetlands of Yala is 90 and half of them are
            migrants. [3] Waterfowl (lesser whistling duck, garganey),
            cormorants (little cormorant, Indian cormorant), large waterbirds
            (grey heron, black-headed ibis, Eurasian spoonbill, Asian openbill,
            painted stork), medium-sized waders Tringa spp., and small waders
            Charadrius spp. are among the most common waterbirds. Black-necked
            stork and lesser adjutant are many of the rare birds that can be
            seen in the park. The migrant great white pelican and resident
            spot-billed pelican are also have been recorded. Other waterbirds
            attracted to the Yala lagoons include lesser flamingo, pelicans, and
            rare species such as purple heron, night herons, egrets, purple
            swamphen, and Oriental darter. Thousands of waterfowls migrate to
            the lagoons of Yala during the northeast monsoon. They are northern
            pintail, white-winged tern, Eurasian curlew, Eurasian whimbrel,
            godwits, and ruddy turnstone. The visiting species mingled with
            residing lesser whistling duck, yellow-wattled lapwing, redwattled
            lapwing, and great stone-curlew. Rock pigeon, barred buttonquail,
            Indian peafowl, black stork, black-winged stilt, and greater
            flamingo are among the other bird species. Crested serpent eagle and
            white-bellied sea eagle are the raptors of the park. The forest
            birds are orange-breasted green pigeon, hornbills, Old World
            flycatchers, Indian paradise flycatcher, Asian barbets, and orioles.
            [9]
          </p>
          <br />

          <h5>Mammals</h5>
          <p>
            Including Sri Lankan elephant, 44 species of mammals are resident in
            Yala National Park,[2] and it has one of the highest leopard
            densities in the world.[11] 25 individual leopards are estimated to
            roam in Block I.[12] The elephant herd of Yala contains 300–350
            individuals.[13] The Sri Lankan sloth bear, leopard, elephant, and
            wild water buffalo are all threatened mammals that Yala harbours.
            Although water buffaloes are indigenous to Sri Lanka, most
            populations contain genes of the domestic stock or have descended
            from feral populations. Toque macaque, golden palm civet, red
            slender loris, and fishing cat are among the other mammals that can
            be seen in Yala. The elephant population of the park varies
            seasonally. [9]
          </p>
          <br />
          <h5>Reptiles</h5>
          <p>
            The reptile fauna recorded from the park is 47 and six of them are
            endemic. Sri Lankan krait, Boulenger's keelback, Sri Lankan flying
            snake, painted-lip lizard, Wiegmann's agama, and Bahir's
            fan-throated lizard are the endemic species.[2][14] The coastal line
            of the park is visited by the all five globally endangered sea
            turtles (leatherback turtle, olive ridley, loggerhead sea turtle,
            hawksbill turtle, and green turtle) that The great stone-curlew
            (great thickknee) is a waterbird found in the park Mammals Streams
            in the park can sustain a large herd of Sri Lankan elephants
            Reptiles visit Sri Lanka.[2][3] The two breeding crocodile species
            of Sri Lanka, mugger crocodile and saltwater crocodile, inhabit the
            park. The Indian cobra and Russell's viper are among the other
            reptiles.[9]
          </p>
          <br />
          <h5>Amphibians</h5>
          <p>
            There are 18 amphibian species that have been recorded from Yala,
            while Bufo atukoralei and Adenomus kelaartii are endemic to Sri
            Lanka.
          </p>
          <br />
          <br />
          <h5>Fish</h5>
          <p>
            In the water courses of Yala, 21 freshwater fish are found.[2] The
            fish population in the perennial reservoirs contain mostly exotic
            food fish Mozambique tilapia. [3] The stone sucker and Esomus
            thermoicos are endemic among other species. The blackspot barb,
            olive barb, orange chromide and common spiny loach are the common
            fish species.
          </p>
          <br />
          <br />
          <h5>Invertebrates</h5>
          <p>
            Crabs and prawns include the fauna in the lagoons of the park.[9] A
            variety of butterfly species is found here. The common bluebottle,
            common lime butterfly, crimson rose, common Jezebel, and common
            Mormon are the common species.[2]
          </p>

          <br />
          <br />
          <h3 className="title">Cultural importance</h3>
          <br />
          <p>
            Yala had been a center of past civilisations.[9] King Ravana, the
            mythical Hindu anti-hero is believed to have established his kingdom
            here with Ravana Kotte, now submerged in the sea, as its boundary.
            Seafaring traders brought Indo-Aryan civilisation with them, as Yala
            is situated in their trading route. A large number of ancient
            although disrepaired tanks are the evidence of a rich hydraulic and
            agricultural civilisation dating back to 5th century BC.[3]
            Situlpahuwa, which was the home for 12,000 arahants, is situated
            within the park area along with Magul Vihara, which built in 87 BC
            and Akasa Chaitiya, which constructed in 2nd century BC. Agriculture
            flourished in area during the period of Ruhuna Kingdom. According to
            Mahavamsa, the Kingdom of Ruhuna began to decline by the end of the
            13th Century AD. During the colonial period Yala became a popular
            hunting ground. Yala is annually visited by 400,000 pilgrims.[15]
          </p>
          <br />
          <h3 className="title">Threats and conservation</h3>

          <br />
          <p>
            Poaching, gem-mining, logging, encroachment by agriculture, and
            free-roaming domestic livestock are the main threats to the park.[9]
            Three wardens have been killed in clashes with poachers. Gems are
            mined along the Menik River and holes created by gem mining, which
            extend up to 30 metres (98 ft), can be seen along the Kumbukkan Oya.
            In Blocks III and IV, the encroachment is severe as chena
            cultivation and burning, to provide grazing in the dry season,
            collides with the boundary. A large grove of Sonneratia caseolaris
            is faced with forest dieback in the Menik River's estuary.
            Cultivation of tobacco, noise and air pollutions caused by
            uncontrolled tourism are the other conservation issues. The growth
            of invasive alien species such as Lantana camara, Opuntia dillenii,
            Chromolaena odorata is threatening the native plants.[3] Deep within
            the forest, Ganja is cultivated in cleared areas.[9] The wildlife is
            poached and disturbed by the fishermen at Patanangala. The turtles
            are caught in fishing nets and the fishermen also litter the beach
            with debris. They have also set traps inland and dig up turtle
            nests. In the absence of hand-weeding, which was practiced until the
            1950s, the transformation of interior grasslands to scrub jungle is
            unavoidable. The tourism has created problems in the past, such as
            vehicles harassing wild animals. The issue is most severe in
            Sithulpahuwa where thousands of pilgrims visit, leading to a great
            degree of commercialisation. Department of Wildlife Conservation has
            taken some conservation measures such as management of grazing
            lands, conservation of small water ponds, and eradication of
            invasive alien species. A 40 kilometres (25 mi) long electric fence
            was erected to prevent elephants from moving into nearby
            villages.[3]
          </p>
          <br />
          <br />
          <h3 className="title">Tourism</h3>

          <br />
          <p>
            The Yala National Park is the most visited park in Sri Lanka.[3] In
            2002 around 156,867 tourists visited the park. Foreigners,
            especially Europeans, account for 30% of total visitors.[16] Block I
            is the main area for visits. Block III (main gate in Galge area, on
            Buttala-Kataragama Road) and the adjoining Kumana Park or 'Yala
            East' (main gate at Okanda, on the east coast not far from Pottuvil)
            however are becoming popular in their own right too.
          </p>
          <p>
            Note that the Situlpahuwa pilgrimage site, geographically in Block
            III, has kind of an 'enclave' status and is accessible FOC through
            separate roads from Tissa and Kataragama. Most of the visitors
            stated that reasons for their visit is to see wild animals, and
            elephant is the most preferred animal. The visitors like to see
            bears, leopards, birds as well. In 2000 the income from visitors
            including lodge fees was approximately US$468,629.[15] Due to
            security conditions revenue was lost.[17] The Yala National Park has
            been susceptible to terrorist attacks. On 17 October 2007 a group of
            LTTE cadres attacked an army detachment in Thalgasmankada in the
            park.[18] The attack killed six army soldiers and another was caught
            up in a landmine explosion. On 11 July 2008 four people died in an
            attack launched by the LTTE.[19] The cadres opened fire at a bus
            carrying pilgrims to Kataragama. Since the end of the civil war, May
            2009, no violence has occurred in Yala area also and it is fully
            safe for visitors; this was also the main factor in opening blocks
            III and V for tourists. From January to June in 2008, 9,078 local
            tourists and 7,532 foreigners have visited Yala. For the same period
            of time in 2009 the arrivals have risen to 18,031 locals and
            foreigners to 10,439. Accordingly, the revenue increased to Rs. 27
            million (US$235,000) in 2009 from Rs. 16.6 million (US$154,000) in
            2008. Tourism Tourism generates noise and air pollution in the park,
            but also generates money and therefore helps to justify the National
            Park The visitors are allowed to see the wild animals from 5.30 am
            to 6.30 pm.[2] Due to droughts the park used to be closed to
            tourists from 1 September, to 15 October annually; however in 2009
            and 2010 the closure was skipped and lakes filled with water bowsers
            for drinking water for the animals, a future strategy on drought
            handling is not yet clear.
          </p>
          <br />
          <br />
          <h3 className="title">References</h3>
          <br />
          <p>
            <a>
              1. Brohier, R. L. (2001). Hewawasam, Abhaya (ed.). Brohier dutu
              Lankawa [The Ceylon that Brohier seen] (in Sinhala) (2nd ed.).
              Sooriya Publishers. pp. 312–320. ISBN 978-955-9348- 63-4.
            </a>
          </p>
          <p>
            2. Senaratna, P.M. (2009). "Yala". Sri Lankawe Jathika Vanodhyana
            (in Sinhala) (2nd ed.). Sarasavi Publishers. pp. 22–69. ISBN
            978-955-573-346-5.
          </p>
          <p>
            {" "}
            <a
              href="http://dw.iwmi.org/wetland_profile/
  Ruhuna.asp"
            >
              3. "Ruhuna National Park". Sri Lanka Wetlands Information and
              Database. International Water Management Institute. Archived from
              the original on 26 July 2011. Retrieved 15 March 2010.
            </a>
          </p>
          <p>
            {" "}
            <a
              href="http://www.ias.ac.in/c
  urrsci/jun102006/1531.pdf"
            >
              4. Fernando, Prithiviraj; Wikramanayake, Eric D.; Pastorini,
              Jennifer (10 June 2006). "Impact of tsunami on terrestrial
              ecosystems of Yala National Park, Sri Lanka". Current Science. 90
              (11): 1531–1534.
            </a>
          </p>
          <p>
            <a
              href="http://www.ceg.ncl.ac.uk/rspsoc2007/paper
  s/77.pdf"
            >
              5. L. Ratnayake, Ranitha. "Impact of tsunami disaster on the
              integral Yala National Park (Sri Lanka) vegetation cover using
              remote sensing". Sri Lanka.
            </a>
          </p>
          <p>
            <a
              href="https://web.archive.org/web/20090220163330/htt
  p://www.noticias.info/Archivo/2004/200412/20041228/20041228_43464.shtm"
            >
              6. "Earthquake: Tsunami closes Yala Park". noticias.info. 28
              December 2004.{" "}
            </a>
            <a href="http://www.noticias.info/Archivo/2004/200412/20041228/20041228_43464.shtm">
              Archived from the originalon 20 February 2009. Retrieved 18 March
              2010.
            </a>
          </p>

          <p>
            {" "}
            <a
              href="https://ui.adsa
  bs.harvard.edu/abs/2007AGUFMOS31A0168M"
            >
              7. Moore, A. L.; McAdoo, B. G.; Ranasinghe, N. (December 2007).
              "2004 South Asia tsunami left little record of its trace near
              Yala, southeastern Sri Lanka". American Geophysical Union, Fall
              Meeting. 31: OS31A–0168. Bibcode:2007AGUFMOS31A0168M.
            </a>
          </p>
          <p>
            <a
              href="http://si-pd
  dr.si.edu/dspace/bitstream/10088/6047/1/41A3412A-04F5-48D1-86A7-8BF03813F28B.pdf"
            >
              8. Wikramanayake, Eric; Fernando, Prithiviraj; Leimgruber, Peter
              (2006). "Behavioral Response of Satellite-collared Elephants to
              the Tsunami in Southern Sri Lanka."
            </a>
            <a
              href="https://doi.org/1
  0.1111%2Fj.1744-7429.2006.00199.x"
            >
              {" "}
              Biotropica. 38 (6): 775–777. doi:10.1111/j.1744-7429.2006.00199.x.
            </a>
          </p>
          <p>
            <a
              href="https://archiv
  e.org/details/iucndirectoryofs90gree"
            >
              9. Green, Micahael J. B. (1990). IUCN directory of South Asian
              protected areas. IUCN. pp. 242–246. ISBN 978-2-8317-0030-4.
            </a>
          </p>
          <p>
            <a
              href="http://www.birdlife.org/datazone/sites/inde
  x.html?action=SitHTMDetails.asp&sid=15329&m=0"
            >
              10."Important Bird Area factsheet: Yala, Sri Lanka". birdlife.org.
              BirdLife International. 2009. Retrieved 18 March 2010.
            </a>
          </p>
          <p>
            <a
              href="http://sundaytimes.lk/09051
  0/Plus/sundaytimesplus_12.html"
            >
              11. Kittle, Andrew (May 10, 2009). "Tracking the Lankan Leopard".
              The Sunday Times. Retrieved 27 February 2010.
            </a>
          </p>
          <p>
            <a
              href="https://doi.org/10.1016%2
  F0006-3207%2882%2990050-7"
            >
              12. Santiapillai, Charles; Chambers, M.R.; Ishwaran, N. (May
              1982). "The leopard Panthera pardus fusca (meyer 1794) in the
              ruhuna national park, Sri Lanka, conservation". Biological
              Conservation. 23 (1): 5–14. doi:10.1016/0006-3207(82)90050-7.
            </a>
          </p>
          <p>
            <a href="http://www.vet.cmu.ac.th/elephant/file/9%2014-22%20Oswin.pdf">
              13. Perera, B. M. A. Oswin (2007). "Status of Elephants in Sri
              Lanka and the Human-Elephant Conflict".
            </a>
          </p>
          <p>
            <a href="https://web.archive.org/web/20061102091806/http://www.sab.ac.lk/Faculty/Management/tourism/Research/sdarticle.pdf">
              14. Buultjens, J.; Ratnayake, I.; Gnanapala, A.; Aslam, M (2005).
              "Tourism and its implications for management in Ruhuna National
              Park (Yala), Sri Lanka".
            </a>{" "}
            <a
              href="https://d
  oi.org/10.1016%2Fj.tourman.2004.03.014"
            >
              Tourism Management. 26 (5): 733–742.
              doi:10.1016/j.tourman.2004.03.014.
            </a>
            <a
              href="http://www.sab.ac.lk/
  Faculty/Management/tourism/Research/sdarticle.pdf"
            >
              Archived from the original on 2006-11-02.
            </a>
          </p>
          <p>
            <a href="http://www.fao.org/DOCREP/ARTICLE/WFC/XII/0116-A1.HTM">
              15. Weerasinghe, U. M. I. R. K.; Kariyawasm, Dayananda; De Zoysa,
              Mangala. "Ruhuna (Yala) National Park in Sri Lanka: visitors,
              visitation and Eco-tourism" fao.org. Food and Agriculture
              Organization. Retrieved 17 March 2010.
            </a>
          </p>
          <p>
            <a
              href="http
  s://web.archive.org/web/20110605233821/http://www.sundayobserver.lk/2009/07/26/imp10.
  asp"
            >
              16. Prematunge, Sajitha (26 July 2009). "Revamping national parks
              in a post-war setting". Sunday Observer. Archived from the
              original (http://www.sundayobserver.lk/2009/07/2 6/imp10.asp) on 5
              June 2011. Retrieved 17 March 2010.
            </a>
          </p>
          <p>
            <a
              href="https://web.archive.org/web/20080123124048/http://www.sunda
  yobserver.lk/2007/10/21/fea02.asp"
            >
              17. "Not a feather in Tiger cap". Sunday Observer. 21 October
              2007.
            </a>{" "}
            <a href="http://www.sundayobserver.lk/2007/10/21/fea02.asp">
              Archived from the original on 23 January 2008. Retrieved 18 March
              2010.
            </a>
          </p>
          <p>
            <a
              href="https://
  web.archive.org/web/20110604213030/http://www.dailynews.lk/2008/07/12/sec01.asp"
            >
              18. Wijayapala, Ranil; Yatawara, Daneshi (12 July 2008). "Tigers
              fire at bus killing four".
            </a>
            <a href="http://www.dailynews.lk/2008/07/12/sec01.asp">
              Daily News. Archived from the original on 4 June 2011. Retrieved
              18 March 2010.
            </a>
          </p>

          <br />
          <h3 className="title">External links</h3>
          <br />
          <p>
            {" "}
            <a href="http://www.yalasrilanka.lk">
              Official website for Yala National Park
            </a>
          </p>
          <p>
            <a href="https://en.wikipedia.org/w/index.php?title=Yala_National_Park&oldid=939682181">
              Media related to Yala National Park at Wikimedia Commons Retrieved
              via Wikimedia. Wikipedia® is a registered trademark of the
              Wikimedia Foundation, Inc., a non-profit organization.
            </a>
          </p>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default AboutYala;
