import React from "react";

// reactstrap components
import {
  
  Container,
 
} from "reactstrap";

// core components
//import IndexNavbar from "components/Navbars/IndexNavbar.js";
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";

function AboutWorldLeopards() {
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      {SwitchNavBar()}
      <div className="wrapper">
        <SubPageHeader title="Leopards Across The World" />

        <Container>
          <h3 className="title">Overview</h3>
          <p>
            Leopards have the greatest geographic distribution of the world's
            cats and are found throughout the continents of Africa, Asia and in
            the Far East and occupy a wide variety of habitat, from the Congo
            rainforest to deserts of the Middle East. Despite their remarkable
            adaptability, Leopard populations are in the steep decline across
            Africa and Asian.
          </p>
          <p>
            The leopard is a symbol of power among many African tribes and its
            skin is often used for the cloaks worn by their kings. Besides the
            Cape buffalo, the leopard is regarded as the second most dangerous
            animal in Africa. Its name is derived from the Greek word leopardos
            after leo for lion and pardus for panthe
          </p>
          <h3 className="title">Population</h3>
          <p>
            <ul>
              <li>
                The leopard’s range spans roughly 75 countries across much of
                Africa and Eurasia.{" "}
              </li>
              <li>
                Likely the most persecuted big cat, leopards are listed as
                “Vulnerable” on the International Union for the Conservation of
                Nature (IUCN) Red List of Threatened Species.
              </li>
              <li>
                The leopard is classified as “Endangered” in Central Asia and
                Sri Lanka and “Critically Endangered” in the Middle East,
                Russia, and on the Indonesian island of Java{" "}
              </li>
            </ul>
          </p>

          <h3 className="title">Taxonomy</h3>
          <p>
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <p>Kingdom:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Animal">
                        ANIMALIA
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Phylum:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Chordate">
                        CHORDATA
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Super cohort:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Laurasiatheria">
                        LAURASIATHERIA
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Cohort:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Ferungulata">
                        FERUNGULATA
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Superorder:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Ferae">FERAE</a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Order:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Carnivora">
                        CARNIVORA
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Suborder:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Feliformia">
                        FELIFORMIA
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Family:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Felidae">
                        FELIDAE
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Subfamily:</p>
                  </td>
                  <td>
                    <p>
                      <a href="https://en.wikipedia.org/wiki/Pantherinae">
                        PANTHERINE
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Genus:</p>
                  </td>
                  <td>
                    <p>
                      <em>
                        <a href="https://en.wikipedia.org/wiki/Panthera">
                          PANTHERA
                        </a>
                      </em>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Species:</p>
                  </td>
                  <td>
                    <p>
                      <em>
                        <a href="https://en.wikipedia.org/wiki/Leopard">
                          PARDUS
                        </a>
                      </em>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </p>
          <h3 className="title">Leopard Size Comparison</h3>

          <div className="div-content-center">
            <img
              alt="Leopard size comparison"
              src={require("assets/img/about-leopard/Leopard size comparison.png")}
            ></img>
            <p className="description">
              <strong>
                Source – Furstenburg, D, “Focus on Leopard (Panthera pardus)”,
                2010,
              </strong>
            </p>
          </div>

          <h3 className="title">
            Leopard Species Identified Around The World{" "}
          </h3>

          <table className="table">
            <thead>
              <tr>
                <th>
                  <p>
                    <strong>Leopard</strong>
                  </p>
                </th>
                <th>
                  <p>
                    <strong>Scientific Name </strong>
                  </p>
                </th>
                <th>
                  <p>
                    <strong>Location </strong>
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>African leopard</p>
                </td>
                <td>
                  <p>
                    <em>Panthera pardus pardus</em>
                  </p>
                </td>
                <td>
                  <p>Africa</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Arabian leopard</p>
                </td>
                <td>
                  <p>
                    <em>Panthera pardus nimr</em>
                  </p>
                </td>
                <td>
                  <p>Arabia</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Persian leopard</p>
                </td>
                <td>
                  <p>
                    <em>Panthera pardus saxicolor</em>
                  </p>
                </td>
                <td>
                  <p>
                    Turkey, Caucasus, Turkmenistan, Uzbekistan, Iran, Iraq,
                    Afghanistan and Pakistan;
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Javan leopard</p>
                </td>
                <td>
                  <p>
                    <em>Panthera pardus melas</em>
                  </p>
                </td>
                <td>
                  <p>Java</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Sri Lankan leopard</p>
                </td>
                <td>
                  <p>
                    <em>Panthera pardus kotiya</em>
                  </p>
                </td>
                <td>
                  <p>Sri Lanka</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Indian leopard</p>
                </td>
                <td>
                  <p>
                    <em>Panthera pardus fusca</em>
                  </p>
                </td>
                <td>
                  <p>Indian subcontinent Myanmar</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Indochinese leopard</p>
                </td>
                <td>
                  <p>
                    <em>Panthera pardus delacouri</em>
                  </p>
                </td>
                <td>
                  <p>South-East Asia and probably southern China</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Amur leopard</p>
                </td>
                <td>
                  <p>
                    <em>Panthera pardus orientalis</em>
                  </p>
                </td>
                <td>
                  <p>Eastern Asia from Russian Far East to China</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>North Chinese leopard</p>
                </td>
                <td>
                  <p>
                    <em>Panthera pardus japonensis</em>
                  </p>
                </td>
                <td>
                  <p>China</p>
                </td>
              </tr>
            </tbody>
          </table>
          <h3 className="title">Leopard Distribution Across World</h3>

          <div className="div-content-center">
            <img
              alt="Leopard Distribution Across World"
              src={require("assets/img/about-leopard/Leopard World Distribution.png")}
            ></img>
            <p className="description">
              <strong>
                Source :
                <a href="https://www.britannica.com/animal/leopard">
                  https://www.britannica.com/animal/leopard
                </a>
              </strong>
            </p>
          </div>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default AboutWorldLeopards;
