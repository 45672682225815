import React from "react";
//import ReactImageZoom from "react-image-zoom";

// reactstrap components
import { Container, Row, Col, Button, Card, CardBody } from "reactstrap";

// core components
//import IndexNavbar from "components/Navbars/IndexNavbar.js";
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { Link } from "react-router-dom";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";

import config from "../../config.json";

function AboutLeopardIdentificationMethod(props) {
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const queryParams = new URLSearchParams(props.location.search);

  return (
    <>
      {SwitchNavBar(queryParams.get("source"))}
      <div className="wrapper">
        <SubPageHeader title="Our Identification Convention" />

        <Container>
          <br />
          <h3 className="title"> Our Identification Convention </h3>
          <Row>
            <p>
              We have used the latest Leopard identification method which was
              developed by our research team after thorough analysis, in
              identifying the Leopards of the Yala National Park- Block-1. Such
              identified Leopards have all been given a Gender based Code and a
              Nick name. Our naming Convention differs from the naming
              convention of the African national parks and private reserves (By
              using the 1 st row spots of the Mystacial area, Gender and a
              location of the territory). A unique naming convention was adopted
              by us due to the following reasons...
            </p>
            <p>
              <ul>
                <li>
                  The Leopards of Yala are not Solitary and are not Confined to
                  a Territory, as in Africa We believe that the reason for such
                  behavior is that the Leopard in Africa is not the Apex
                  predator and the availability of prey for African Leopards are
                  limited. The scenario of the Leopards of the Yala national
                  Park- Block-1 is quite different. They are the Apex predator
                  of the park . The Abundance of water and the prey base makes
                  the Leopard behavior in Yala different. Our research work has
                  proven that the Leopard of Yala share their home ranges
                  amongst individuals of both genders. Our research work has
                  also proven that there are positive preferential interactions
                  amongst Leopards in Yala.
                </li>
                <li>
                  Due to the sharing of home ranges, we find that there are many
                  Leopards who bear the same Mystacial spot count, in the same
                  area.
                </li>
                <li>
                  The Yala National Park, Block -1 is only 141 Sq Km’s and there
                  are many prominent locations which are named, close to one
                  another within this area. As an example, the Location Pan wala
                  and Kotabendi weva are 2 locations which is only 330 meters
                  apart. We do not see any Logic in naming a Leopard as per
                  nearby locations.
                </li>
                <li>
                  To make the Wildlife loving public more aware, interested and
                  conscious of the identified Leopards by adopting a simple nick
                  name in addition to the scientific coding we have given,
                  instead of a confusing naming convention. We passionately
                  believe that the public must be aware and conscious of what we
                  strive to protect and conserve. We believe that greater
                  conservation can be achieved through greater interest of all
                  stakeholders and simplifying identifications can make more
                  people interested and concerned of the Leopards they sight.
                </li>
              </ul>
            </p>
          </Row>

          <h3 className="title">A Multi-Point Identification Approach for the Recognition of Individual Leopards  (Panthera pardus kotiya)</h3>
          <Row>
            <p>
              <ul>
                <li>
                  Our research has established a new methodology in leopard identification
                  termed by us as the multi-point leopard identification method.
                <a href="/projects/research">
                    &nbsp;Please refer the abstracts published
            </a>
                </li>
                <li>
                  We have segregated the leopard’s coat to fifteen (15) points according to the spot and rosette formations of those areas for the purpose of identification and termed them as multi-point reference codes (MPC points).
              </li>
                <li>
                  We suggest that the spot and rosette formations of a minimum 9 MPC points out of the 15 MPC points must be thoroughly compared with existing images before classifying a leopard as a newly identified leopard and when establishing the identity of an already identified leopard.</li>
                <li>
                  Reasons for New identification method is to reduce misidentification errors that can occur due to the changes in spot and rosette formations. Errors in identification can lead to over estimation of populations and inaccurate data collection for research and management decisions.</li>
              </ul>
            </p>

          </Row>
          <Row>
            <Col md="12" sm="12" lg="12">
              <img
                src={require("assets/img/methodology/M1.jpg")}
                //zoomPosition="original"
                alt=""
              />
            </Col>
          </Row>



          <h3 className="title">Changes in Spot Markings and Rosette Patterns of a Leopard</h3>

          <Row>
            <p>
              <ul>
                <li>
                  Before the start of our study, it was an accepted fact that the spot markings and rosette patterns of a leopard do not change over time.
                </li>
                <li>
                  However, during our study, we observed that changes could occur in the spot markings and rosette patterns of leopards over time.
                </li>
                <li>
                  As per our findings, these changes occur through injuries sustained by the leopards but may also occur without the presence of an injury.
                </li>
                <li>
                  Out of 137 leopards, we continuously monitored 59 leopards and observed 29 instances of injuries in 16 of them.
                </li>
                <li>
                  We closely observed these identified leopards. Some of them were observed with injuries on recaptures, for which we compared them with documented photos taken of those leopards prior to such injury, documented the leopards during the injury, and continuously observed the leopard after the injury.
                </li>
                <li>
                  We have also observed Leopards with changes to their markings at the recaptures, without documenting injuries.
                 </li>
                <li>
                  Due to these changes, we had documented these 59 Leopards as a total of 68 separate leopards, representing an error rate of 15.25%.
                   </li>
                <li>
                  We had misidentified 9 Leopards as new Leopards though they had been in fact the same. We have presented 14 instances of the documented 29 instances of Change in this presentation.
                </li>
                <li>Changes in the spots and rosettes were observed to take two forms. They are termed by us as Obliterate and Rejig changes.
                </li>
              </ul>
            </p>
          </Row>
          <h4 className="title">Obliterate  Change</h4>
          <Row>

            <p>
              <ul>
                <li>
                  When obliterate changes take place, a spot or rosette pattern change that was originally present is subsequently not visible. The spots are succeeded by the yellowish skin coat in the areas where obliterate changes have occurred. The rust yellow color of the skin coat may be lighter in places where obliterate changes have occurred.
</li>
                <li>
                  The change in the number of spots when a Obliterate change occurs have been counted. However, during our study, we observed that changes could occur in the spot markings and rosette patterns of leopards over time.
                </li>

              </ul>
            </p>

            <Col md="12" sm="12" lg="12">
              <img
                src={require("assets/img/methodology/O1.jpg")}
                //zoomPosition="original"
                alt=""
              />
            </Col>


          </Row>

          <h4 className="title">Rejig Change</h4>
          <Row>

            <p>
              <ul>
                <li>
                  A rejig change is when the prominence, shape, size, and appearance of the original spot or the rosette pattern changes. During our study of the 59 leopards, the majority of the spot alterations were identified in the forehead areas, Mystacial area, left and right sides of the face and the Left side of the Flank.</li>
                <li>
                  The Rejig changes have been given a rank as 1 for the reduction in prominence and Rank 2 for Change in shape.
</li>

              </ul>

            </p>
            <Col md="12" sm="12" lg="12">
              <img
                src={require("assets/img/methodology/R1.jpg")}
                //zoomPosition="original"
                alt=""
              />
            </Col>
          </Row>
          <h3 className="title">The Markings in a Leopards skin coat</h3>


          <p>
            <ul>
              <li>
                The leopard’s striking rust yellow coloration and blackish markings make it easy to identify.
                  </li>
              <li>
                The size, appearance, and shape of the spots vary within the coats of individual leopards . The individual spots form a pattern in some parts of the coat. In some areas of the coat, individual spots form an amalgamation and create a rosette.
</li>
              <li>In some areas of the coat, a darker orangish contrast can be observed between the spots that form a rosette. Individual rosettes in the skin will form into a collective pattern.
</li>
              <li>We have not come across any leopards bearing the same spot and rosette pattern formations. There are individual spots that appear similar when visually examined.
</li>

            </ul>
          </p>





          <br />
          <p>

            <Row>
              <Col md="12" sm="12" lg="12">
                <img
                  src={require("assets/img/methodology/F1.jpg")}
                  //zoomPosition="original"
                  alt=""
                />
              </Col>
              <Col md="12" sm="12" lg="12">
                <img
                  src={require("assets/img/methodology/F2.jpg")}
                  //zoomPosition="original"
                  alt=""
                />
              </Col>
              {/* <Col md="12" sm="12" lg="12">
                <img
                  src={require("assets/img/methodology/Methodology_left.jpg")}
                  //zoomPosition="original"
                  alt=""
                />
              </Col>
              <Col md="12" sm="12" lg="12">
                <img
                  src={require("assets/img/methodology/Methodology_right.jpg")}
                  //zoomPosition="original"
                  alt=""
                />
              </Col> */}
            </Row>
          </p>



          <p>
            <ul>
              <li>
                Leopards have distinctive markings on the bottom of their abdomen (belly area) that consist of a formation of thick spots in the shape of a circular or oblong circumferential pattern, with fewer spots within the perimeter.

                  </li>
              <li>
                This pattern was found in all of the leopards we examined. The spot patterns and formations of individual leopards, on the other hand, vary, though the presence of such a pattern is common.
</li>
              <li>In some areas of the coat, a darker orangish contrast can be observed between the spots that form a rosette. Individual rosettes in the skin will form into a collective pattern.
</li>
              <li>However, the spot patterns and formations are different in individual leopards, though the presence of such a pattern is common.
</li>
              <li> We have termed this as Idiosyncratic spot pattern formation. A comparison of the idiosyncratic spot pattern formation of three individual leopards that were identified is shown below.
</li>

            </ul>
          </p>

          <Col md="12" sm="12" lg="12">
            <img
              src={require("assets/img/methodology/F3.jpg")}
              //zoomPosition="original"
              alt=""
            />
          </Col>

          <Col md="12" sm="12" lg="12">
            <img
              src={require("assets/img/methodology/SK1.jpg")}
              //zoomPosition="original"
              alt=""
            />
          </Col>

          <Col md="12" sm="12" lg="12">
            <img
              src={require("assets/img/methodology/SK2.jpg")}
              //zoomPosition="original"
              alt=""
            />
          </Col>

          <Col md="12" sm="12" lg="12">
            <img
              src={require("assets/img/methodology/SK3.jpg")}
              //zoomPosition="original"
              alt=""
            />
          </Col>

          <Col md="12" sm="12" lg="12">
            <img
              src={require("assets/img/methodology/SK4.jpg")}
              //zoomPosition="original"
              alt=""
            />
          </Col>
          <Col md="12" sm="12" lg="12">
            <img
              src={require("assets/img/methodology/SK5.jpg")}
              //zoomPosition="original"
              alt=""
            />
          </Col>

         
          <h3 className="title">Naming Convention</h3>

          <p>
            Providing unique IDs to Leopards for easy identification
  <br />
            <br />
            <ul className="list-unstyled">
              <li>
                Male Leopards (Block 01) – <b>YM##</b>{" "}
              </li>
              <li>
                Male Leopards (Block 05) – <b>YB5M##</b>{" "}
              </li>
              <br />
              <li>
                Female Leopards (Block 01)– <b>YF##</b>
              </li>
              <li>
                Female Leopards (Block 05) – <b>YB5F##</b>{" "}
              </li>
            </ul>
  Nick name (Ex: <b>YF01 – Jeena</b> | <b>YB5F02 – Bella</b>) has been
  provided for easy reference
  <br />
            <br />
            <p className="text-danger">
              Cubs are monitored until sub adult stage and listed at an
              estimated age of between 12 - 18 months.
    <br />
    Adult leopards are listed if there is sufficient photographic
    evidence of even one sighting.
  </p>
          </p>
          <p>
            We have identified 138 Leopards since the year 2012, at the Yala
            National Park, block - 1, of where all identities have been
            confirmed using the multi point identification method.{" "}
          </p>
          <p>
            A Census Survey was done by the research team (refer abstracts
            published). It was established that 68 Leopards of the identified
            Leopards were sighted during the period of 15 months from January
            2020 to March 2021. This indicates that the minimum Leopard
            population of the Yala National park- Block - 1 , is 68 Leopards.
            The area where the identification was done was 118.41 Sq. Km's. The
            minimum density of Leopards at the identification area of the Yala
            National park is 59.41 Leopards per 100 Sq. Km's, which makes Yala
            the park with the highest density of Leopards in the world.
          </p>

          <p>
            The identities of all Leopards identified are listed{" "}
            <a href="/search-identified-leopards">here</a>. We have also listed
            the <a href="/leopard-homeranges">Home ranges</a> of the 68 Leopards
            that were confirmed as present in the park, by way of census survey.
            The overlapping home ranges of these leopards can be derived too.
          </p>
          <br />



          <h3 className="title">Age Classfication</h3>
          <p>
            The age estimation was done by considering the observation period of
            a particular Leopard, where photographic data was available. If the
            data is not available, the research team did a detailed comparison
            of the physical appearance of the Leopard , with many Leopards in
            the database of the research team and came into conclusion about the
            age of the Leopard in concern.
          </p>
          <p>
            We have categorized the Listed Leopards in to 05 age brackets as
            follows.
          </p>

          <Row>
            <Col md="36">
              <table className="table" responsive>
                <thead>
                  <tr>
                    <th width="200">Age category</th>
                    <th width="200">Abbreviation used</th>
                    <th>Basis of separation</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="200">
                      <p>Cub</p>
                    </td>
                    <td width="200">
                      <p>C</p>
                    </td>
                    <td>
                      <p>The period of below 12 months</p>
                    </td>
                  </tr>

                  <tr>
                    <td width="200">
                      <p>Sub Adult</p>
                    </td>
                    <td width="200">
                      <p>SA</p>
                    </td>
                    <td>
                      <p>
                        The period between 12 to 30 months after observing as a
                        Cub
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td width="200">
                      <p>Early adult</p>
                    </td>
                    <td width="200">
                      <p>EA</p>
                    </td>
                    <td>
                      <p>
                        The period between 31 months and 45 Months after
                        observing as a Cub or if the estimated age is less than
                        4 years.{" "}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td width="200">
                      <p>Adult</p>
                    </td>
                    <td width="200">
                      <p>A</p>
                    </td>
                    <td>
                      <p>An estimated age between 4 years and 8 years</p>
                    </td>
                  </tr>
                  <tr>
                    <td width="200">
                      <p>Aged adult</p>
                    </td>
                    <td width="200">
                      <p>AA</p>
                    </td>
                    <td>
                      <p>An estimated age >8 year</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default AboutLeopardIdentificationMethod;
