import React from "react";

// reactstrap components
import { Container, Row, Col, FormGroup, Input } from "reactstrap";

// core components
//import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { react_select_styles } from "../../utils/React-Select-Styling.js";
import ResultsPageHeader from "components/Headers/ResultsPageHeader.js";

import GoogleMapReact from "google-map-react";
import GoogleMapMarker from "components/territory-components/GoogleMapMarker.js";
import { GetAllCoordinatesForPark } from "../../utils/GetAllLocationCoordinatesForParkPromise";

import { divIcon } from "leaflet";
import { Map, Marker, Popup, TileLayer, Tooltip, Polygon } from "react-leaflet";

import { ToastContainer, toast } from "react-toastify";

import config from "../../config.json";
import { createCoordinateListBasedOnLngLat } from "../../utils/MapUtils.js";
import { SwitchNavBar } from "../../utils/SwitchNavBar.js";
import { NationalParkList } from "../../variables/AllNationalParks.js";

var randomColor = require("randomcolor");

// DRAW polygons in google maps tool
// http://apps.headwallphotonics.com/

function LeopardTerritories(props) {
  const isInitialMount = React.useRef(true);

  const animatedComponents = makeAnimated();

  const [map, setMap] = React.useState(null);
  const [maps, setMaps] = React.useState(null);
  const [polygons, setPolygons] = React.useState([]);

  const [tags, setTags] = React.useState([]);

  const [locationcoords, setLocationcoords] = React.useState([]);

  const [suggestions, setSuggestions] = React.useState([]);

  const [leafletPolygons, setLeafletPolygons] = React.useState([]);

  const [MAP_API_PROVIDER, setMAP_API_PROVIDER] = React.useState(
    config.MAP_API_PROVIDER
  );

  const [defaultProps, setDefaultProps] = React.useState(
    config.MAP_DEFAULT_PROPS
  );

  const national_parks_list = NationalParkList;
  const [park, setPark] = React.useState("1");
  const [filterGender, setFilterGender] = React.useState("");
  const [leopardCoordinateList, setLeopardCoordinateList] = React.useState([]);

  const handleSelectChange = (options) => {
    if (options === null || options.length === 0) {
      setTags([]);
    } else {
      setTags(options);
    }
  };

  function setGoogleMapReference(map, maps) {
    setMap(map);
    setMaps(maps);
  }

  function genrateRandomColor() {
    // generate a random color from ramdomcolor package.
    // Math.floor(Math.random()*16777215).toString(16); generates murkey colors
    // So switched to this
    return randomColor();
  }

  function drawTerritoryPolygonsGoogleMaps() {
    clearAllPolygons();

    if (tags !== null && tags.length > 0) {
      tags.map((mapItem) => {
        var polyFillColor = genrateRandomColor();
        // Construct the polygon.
        var bermudaTriangle = new maps.Polygon({
          paths: mapItem.coords,
          strokeColor: polyFillColor,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: polyFillColor,
          fillOpacity: 0.25,
        });
        polygons.push(bermudaTriangle);
        attachPolygonInfoWindow(bermudaTriangle, mapItem.label);
        bermudaTriangle.setMap(map);
      });
    }
  }

  function attachPolygonInfoWindow(polygon, text) {
    var infoWindow = new maps.InfoWindow();
    maps.event.addListener(polygon, "mouseover", function (e) {
      infoWindow.setContent(text);
      var latLng = e.latLng;
      infoWindow.setPosition(latLng);
      infoWindow.open(map);
    });

    maps.event.addListener(polygon, "mouseout", function (e) {
      infoWindow.close();
    });
  }

  function clearAllPolygons() {
    while (polygons.length) {
      polygons.pop().setMap(null);
    }
    polygons.length = 0;
  }

  function showAlert(message) {
    toast.error(message, { containerId: "SearchError" });
  }

  function getLocationCoords(park) {
    GetAllCoordinatesForPark(park)
      .then((response) => {
        setLocationcoords(response);
      })
      .catch((err) => {
        showAlert("Error: " + err.message);
      });
  }

  function filterOutResultsBasedOnLastSeenDate(leopard_list) {
    var fromDate = new Date("2020-01-01");

    var filtered_leopard_list = leopard_list.filter((a) => {
      var date = new Date(a.last_seen_date);
      return date >= fromDate;
    });

    return filtered_leopard_list;
  }

  function getLeopardTerritories(park) {
    fetch(
      config.APP_URLS.BACKEND_SERVER_API +
      "/web/get-all-leopard-territory-coordinates/" +
      park,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        // If no results found, show toast and exit
        if (json["matchResults"].length == 0) {
          return;
        }

        var leopard_list = filterOutResultsBasedOnLastSeenDate(
          json["matchResults"]
        );
        setLeopardCoordinateList(leopard_list);

        // console.log(
        //   "FILTERED LEOPARD COORDS  TOTAL: " + JSON.stringify(leopard_list)
        // );
        var suggestionList = [];
        for (var i = 0; i < leopard_list.length; i++) {
          var leopard = leopard_list[i];
          if (
            filterGender == null ||
            filterGender == "" ||
            leopard.gender == filterGender
          ) {
            //console.log("LEOPARD COORDS RECEIVED : " + JSON.stringify(leopard));
            var suggest = {};
            suggest.label = leopard.name;
            suggest.value = leopard.officialnamecode;
            suggest.coords = createCoordinateListBasedOnLngLat(leopard.coords);
            suggestionList.push(suggest);
          }
        }

        setSuggestions(suggestionList);
      });
  }

  function drawTerritoryPolygonsLeafletJS() {
    setLeafletPolygons([]);
    var allPolygons = [];
    if (tags !== null && tags.length > 0) {
      tags.map((mapItem) => {
        var polygon = {};
        polygon.label = mapItem.label;
        var polygncoords = [];
        for (var i = 0; i < mapItem.coords.length; i++) {
          polygncoords[i] = [mapItem.coords[i].lat, mapItem.coords[i].lng];
        }
        polygon.coords = polygncoords;
        allPolygons.push(polygon);
      });
    }
    setLeafletPolygons(allPolygons);
    // console.log(
    //   "LEAFLET Constructed Ploy Array : " + JSON.stringify(allPolygons)
    // );
  }

  function getLocationsForNP(selectedNP) {
    // console.log(">>>>>>>>> SELECTED NP : " + JSON.stringify(selectedNP));
    setPark(selectedNP);
    getLocationCoords(selectedNP);
    getLeopardTerritories(selectedNP);
  }

  function getParkCenter() {
    if (park == 1) {
      return defaultProps.PARKS.park1.center;
    } else if (park == 2) {
      return defaultProps.PARKS.park2.center;
    }
  }

  function changeGender(gender) {
    setFilterGender(gender);
    //(">>>>>> Gender changed : "+gender)

    var suggestionList = [];
    for (var i = 0; i < leopardCoordinateList.length; i++) {
      var leopard = leopardCoordinateList[i];
      if (gender == null || gender == "" || leopard.gender == gender) {
        //(">>>> LEOPARD GENDER FILTERED : " + JSON.stringify(leopard));
        var suggest = {};
        suggest.label = leopard.name;
        suggest.value = leopard.officialnamecode;
        suggest.coords = createCoordinateListBasedOnLngLat(leopard.coords);
        suggestionList.push(suggest);
      }
    }
    //(">>>> Gender Filterd list : " + JSON.stringify(suggestionList))
    setSuggestions(suggestionList);
  }

  React.useEffect(() => {
    if (isInitialMount.current) {
      getLocationCoords("1");
      getLeopardTerritories("1");
      isInitialMount.current = false;
    } else {
      // If Google Maps draw polygons in this way:
      if (config.MAP_API_PROVIDER === "GOOGLE") {
        drawTerritoryPolygonsGoogleMaps();
      } else if (config.MAP_API_PROVIDER === "LEAFLET") {
        drawTerritoryPolygonsLeafletJS();
      }
    }
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };

    // Use the effects  only if the "tags" are updated.
  }, [tags]);

  return (
    <>
      {SwitchNavBar()}
      <div className="wrapper">
        <ResultsPageHeader title="Home Ranges of Leopards" />

        <Container>
          <br />

          <p>
            The home ranges are shown by polygon by connecting the frequently
            sighted areas ( Photographic sightings and Trap Camera sightings) of
            the particular Leopard, by the team during the{" "}
            <b>15-month period from January 2020 to March 2021</b>.{" "}
          </p>
          <p>
            The Home ranges are shown only for the Leopard seen during the above
            said 15 month period, as per the Census survey conducted by the
            research team.
          </p>

          <h5 className="font-weight-bold">
            Filter and/or select one or more leopards from the below field to
            check its home range and overlapping home ranges.{" "}
          </h5>

          <ToastContainer
            enableMultiContainer
            containerId={"SearchError"}
            position={toast.POSITION.TOP_RIGHT}
          />

          <Row>
            <Col md="6" lg="6" sm="6">
              <FormGroup>
                <label>National Park</label>
                <Select
                  //Value={park}
                  defaultValue={national_parks_list[0]}
                  name="zone"
                  onChange={(e) => getLocationsForNP(e.value)}
                  className="searchable-dropdown basic-single"
                  classNamePrefix="select"
                  isSearchable={false}
                  // TO DO : Get this from AllNationalParks.js file's list. Restricting to Block1 for now
                  options={[national_parks_list[0]]}
                  // rounded corners to match others
                  styles={react_select_styles}
                />

              </FormGroup>
            </Col>
            <Col md="6" lg="6" sm="6">
              <FormGroup>
                <label>Gender</label>
                <Select
                  Value={filterGender}
                  name="filterGender"
                  onChange={(e) => changeGender(e.value)}
                  className="searchable-dropdown basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  options={[
                    { value: "", label: "Combined" },
                    { value: "Male", label: "Male" },
                    { value: "Female", label: "Female" },
                  ]}
                  // rounded corners to match others
                  styles={react_select_styles}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="12" lg="12" sm="12">
              <Select
                key={park}
                closeMenuOnSelect={true}
                components={animatedComponents}
                isMulti
                options={suggestions}
                onChange={handleSelectChange}
                placeholder="Search leopards by their nickname or codename.."
                // className="territory-leopard-select"
                className="searchable-dropdown basic-single"
                // rounded corners to match others
                styles={react_select_styles}
                autoFocus
              />
            </Col>
          </Row>
          <br />

          {MAP_API_PROVIDER === "LEAFLET" && (
            <div className="google-map-container">
              <Map
                center={getParkCenter()}
                zoom={defaultProps.zoom}
                maxZoom={defaultProps.zoom}
              >
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, &amp;copy <a href="http://www.openmaptiles.org/">OpenMapTiles</a>.'
                  url={config.APP_URLS.TILE_SERVER_SERVE_URL}
                  maxZoom={defaultProps.zoom}
                />

                {/* 
                Use the same marker as google maps 
                https://github.com/PaulLeCam/react-leaflet/issues/48#issuecomment-121339376 
                */}

                {locationcoords.map((location, index) => (
                  <Marker
                    icon={divIcon({
                      className: "my-div-icon",
                      html: "<div class='hovicon effect-1 sub-a'/>",
                    })}
                    position={[
                      location.coordinates.split(",")[0],
                      location.coordinates.split(",")[1],
                    ]}
                    key={"location_" + index}
                  >
                    {/* <Popup>{location.location_name}</Popup> */}
                    <Tooltip>{location.location_name}</Tooltip>
                  </Marker>
                ))}

                {leafletPolygons.map((poly) => (
                  <Polygon color={genrateRandomColor()} positions={poly.coords}>
                    <Tooltip>{poly.label}</Tooltip>
                  </Polygon>
                ))}
              </Map>
            </div>
          )}

          {MAP_API_PROVIDER === "GOOGLE" && (
            <div className="google-map-container">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: config.GOOGLE_MAP_API_KEY,
                }}
                defaultCenter={getParkCenter()}
                defaultZoom={defaultProps.zoom}
                options={(map) => ({ mapTypeId: map.MapTypeId.SATELLITE })}
                onGoogleApiLoaded={({ map, maps }) =>
                  setGoogleMapReference(map, maps)
                }
                yesIWantToUseGoogleMapApiInternals
                key={park}
              >
                {locationcoords.map((location, index) => (
                  <GoogleMapMarker
                    lat={location.coordinates.split(",")[0]}
                    lng={location.coordinates.split(",")[1]}
                    text={location.location_name}
                    idForTooltip={"Tooltip" + index}
                  ></GoogleMapMarker>
                ))}
              </GoogleMapReact>
            </div>
          )}
        </Container>
      </div>

      <DefaultFooter />
    </>
  );
}

export default LeopardTerritories;
