import React from "react";

// reactstrap components
import {
  Row,
  Col,
} from "reactstrap";

function AbouDWCParks_FloodPlains() {
  return (
    <>
      <Row>
        <Col>
          <h3 className="title">Flood Plains National Park</h3>
          <img
            src={require("assets/img/aboutDWC/parks/flood-plains.jpg")}
            className="dwc-parks-image"
            alt="Flood Plains National Park"
          ></img>
          <h4>Introduction</h4>
          <p>
            The park is central to the integrity of the Mahaweli system of
            protected areas, both for its unique “villus” and as a corridor for
            wildlife migration between grazing lands in Wasgamuwa and
            Somawathiya Chithiya National Park. The area spans the Mahaweli
            Ganga in Polonnaruwa district in North Central Province. The
            township of Manampitiya lies just outside the eastern boundary of
            the park, a stopover in the Batticaloa-Polonnaruwa road and on the
            railway which pass through the park.
          </p>
          <h4>ACCESS</h4>
          <p>
            <ul>
              <li>
                The simplest approach from Colombo would be via Kurunegala,
                Dambulla, Habarana and Polonnaruwa, the range office is in
                Polonnaruwa town this park is 233km away from Colombo.
              </li>
            </ul>
          </p>
          <h4>PHYSICAL CHARACTERRISTICS</h4>
          <p>
            The Mahaweli Ganga flows the south to north through the centre of
            the park. The flood plains of rich alluvial soil flanking the river
            are characterized by numerous shallow marshy depressions known as
            “villus”. The prolonged periods of inundation of these low-lying
            areas, together with the nutrients carried in by the water are
            largely responsible for the exceptionally high level of net primary
            productivity. The area ranges from 20m to 60m with an occasional
            rock outcrop. Covering 17,350ha and lying in the Mahaweli protected
            area complex, the flood plains National Park links Wasgamuwa
            National Park 37,063ha to the south with Somawathiya Chaitiya
            National Park 37,762ha to the North. Being in the dry zone season,
            there is only northeast monsoon from October to late January. This
            is followed by a dry season lasting from Nay to September.
          </p>
          <h4>VEGITATION</h4>
          <p>
            Saturated soils and flooding prevent tree growth and enhance the
            growth of water tolerant grasses and aquatic plants. The vegetation
            of the ‘villus’ shows definite pattern of zonation, with creeping
            grasses
          </p>
          <h4>FAUNA</h4>
          <p>
            The rich vegetation in the villu attracts large number of grazing
            animals and birds and supports a higher annual biomass than any
            other type of habitat within the Mahaweli development project area.
            The Food Plain, with their abundant supply of water and grasslands,
            mammals are commonly found in the Food Plains and the marshes
            support large populations of reptiles are present in here. The Food
            Plains are particularly important for the diversity and abundance of
            their avifauna, particularly migrant birds it is estimated that
            around 75 migrant species spend their winter time in the marshes of
            the Food Plain.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default AbouDWCParks_FloodPlains;
