import config from "../config.json";

export const GetLeopardMetaInformation = (officialnamecode) =>
  new Promise(function (resolve, reject) {
    fetch(
      config.APP_URLS.BACKEND_SERVER_API +
        "/web/get-mata-information-for-leopard",
      {
        method: "POST",
        body: JSON.stringify({ officialnamecode: officialnamecode }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        // If no results found, show toast and exit
        if (json["matchResults"].length == 0) {
          reject(
            new Error(
              "Unable to find a leopard with code " +
                officialnamecode +
                " in the database"
            )
          );
        } else {
          var metadata = json["matchResults"][0];
          resolve(metadata);
        }
      })
      .catch((err) => {
        reject(
          new Error("Unable to fetch leopard information. Error:" + err.message)
        );
      });
  });
