import React from "react";

// reactstrap components
import {

  Card,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
} from "reactstrap";

// core components
//
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { DocumentList } from "../../variables/AllBehaviourList.js";
import { ObservationList } from "../../variables/AllObservationsList.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";

import config from "../../config.json";

import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  VolumeMenuButton,
} from "video-react";

function AboutLeopardBehaviours(props) {
  const [documentList, setDocumentList] = React.useState(DocumentList);
  const [observationList, setobservationList] = React.useState(ObservationList);

  const [currentSrc, setCurrentSrc] = React.useState(null);
  const [currentDescription, setCurrentDescription] = React.useState("");

  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  const closeBtn = (
    <button className="model-close-button close" onClick={toggle}>
      &times;
    </button>
  );

  function showVideoModal(document) {
    setCurrentSrc(require(`assets/videos/${document.Path}`));
    setCurrentDescription(document.Name);
    setModal(true);
  }

  function showBehaviourList() {}

  React.useEffect(() => {
    // document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const queryParams = new URLSearchParams(props.location.search);

  return (
    <>
      {SwitchNavBar(queryParams.get("source"))}
      <div className="wrapper">
        <SubPageHeader title=" Distinct Observations" />

        <Container>
          <br />
          <div>
            <Table>
              <Row>
                {queryParams.get("source") === "leopardcenter" && (
                  <>
                    {documentList.map((document, index) => (
                      <Col lg="3" md="4" sm="6" xs="12" key={"docs-" + index}>
                        <Card>
                          <div>
                            <a onClick={() => showVideoModal(document)}>
                              <video className="result-document-inner">
                                <source
                                  src={require(`assets/videos/${document.Path}`)}
                                  type="video/mp4"
                                />
                                Your browser does not support HTML5 video.
                              </video>
                            </a>
                            <p className="video-card-description">
                              {document.Name}
                            </p>
                          </div>
                        </Card>
                      </Col>
                    ))}
                  </>
                )}

                {queryParams.get("source") != "leopardcenter" && (
                  <div>
                    {observationList.map((document, index) => (
                      <div>
                        <img
                          className="result-document-inner"
                          src={require(`assets/img/behaviours/${document.Path}`)}
                        ></img>
                      </div>
                    ))}
                  </div>
                )}
              </Row>
            </Table>
          </div>

          <Modal className="model-fullscreen" isOpen={modal} toggle={toggle}>
            <ModalHeader
              className="modal-header-no-padding"
              toggle={toggle}
              close={closeBtn}
            >
              {currentDescription}
            </ModalHeader>
            <div className="modal-body-no-border">
              <Player poster="/assets/poster.png" autoPlay={true}>
                <source src={currentSrc} />
                <ControlBar>
                  <ReplayControl seconds={10} order={1.1} />
                  <ForwardControl seconds={30} order={1.2} />
                  <VolumeMenuButton enabled />
                </ControlBar>
              </Player>
            </div>
          </Modal>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default AboutLeopardBehaviours;
