import React from "react";

// reactstrap components
import { Table, Button, Container, Row, Col } from "reactstrap";

// core components
//import IndexNavbar from "components/Navbars/IndexNavbar.js";

import DefaultFooter from "components/Footers/DefaultFooter.js";
import LeopardImageCarousel from "components/search-components/LeopardImageCarousel.js";
import FamilyTree from "components/search-components/FamilyTree.js";

import config from "../../config.json";

import { divIcon } from "leaflet";
import { Map, Marker, TileLayer, Tooltip, Polygon } from "react-leaflet";
import GoogleMapReact from "google-map-react";
import GoogleMapMarker from "components/territory-components/GoogleMapMarker.js";

import { createCoordinateListBasedOnLngLat } from "../../utils/MapUtils.js";
import { SwitchNavBar } from "../../utils/SwitchNavBar.js";

import { GetAllCoordinatesForPark } from "../../utils/GetAllLocationCoordinatesForParkPromise";
import { GetLeopardSeenAreas } from "../../utils/GetLeopardSeenAreasPromise";
import { GetLeopardTerritory } from "../../utils/GetLeopardTerritoryPromise";
import { GetLeopardMetaInformation } from "../../utils/GetLeopardMetaInformationPromise";

import { ToastContainer, toast } from "react-toastify";

function LeopardSearchResult(props) {
  //("ok ok. calm down. I'm showing : " + JSON.stringify(props));

  const isInitialMount = React.useRef(true);

  const [selectedResultRow, setSelectedResultRow] = React.useState([]);

  const [parkid, setParkId] = React.useState(null);
  const [seenareas, setSeenareas] = React.useState([]);
  const [territoryCoords, setTerritoryCoords] = React.useState([]);

  const [openFamilyTree, setOpenFamilyTree] = React.useState(false);

  const [locationcoords, setLocationcoords] = React.useState([]);

  const [MAP_API_PROVIDER, setMAP_API_PROVIDER] = React.useState(
    config.MAP_API_PROVIDER
  );

  const [defaultProps, setDefaultProps] = React.useState(
    config.MAP_DEFAULT_PROPS
  );

  const [map, setMap] = React.useState(null);
  const [maps, setMaps] = React.useState(null);
  const [leafletPolygons, setLeafletPolygons] = React.useState([]);

  function getFamilyTreeForLeopard() {
    setOpenFamilyTree(true);
  }

  function setGoogleMapReference(map, maps) {
    //("setGoogleMapReference. Google Map Loaded");
    setMap(map);
    setMaps(maps);
  }

  function drawTerritoryPolygonsGoogleMaps() {
    // console.log(
    //   "drawTerritoryPolygonsGoogleMaps : territoryCoords " + territoryCoords
    // );
    var formattedCoords = createCoordinateListBasedOnLngLat(territoryCoords);
    var bermudaTriangle = new maps.Polygon({
      paths: formattedCoords,
      strokeColor: "#f96332",
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: "#f96332",
      fillOpacity: 0.25,
    });
    attachPolygonInfoWindow(
      bermudaTriangle,
      selectedResultRow.officialnamecode + " - " + selectedResultRow.nickname
    );
    bermudaTriangle.setMap(map);
  }

  function attachPolygonInfoWindow(polygon, text) {
    var infoWindow = new maps.InfoWindow();
    maps.event.addListener(polygon, "mouseover", function (e) {
      infoWindow.setContent(text);
      var latLng = e.latLng;
      infoWindow.setPosition(latLng);
      infoWindow.open(map);
    });

    maps.event.addListener(polygon, "mouseout", function (e) {
      infoWindow.close();
    });
  }

  function drawTerritoryPolygonsLeafletJS() {
    setLeafletPolygons([]);

    //console.log("PRINTING LEAFLET COORDS")
    //console.log(territoryCoords)

    var formattedCoords = createCoordinateListBasedOnLngLat(territoryCoords);
    var allPolygons = [];

    var polygon = {};
    polygon.label =
      selectedResultRow.officialnamecode + " - " + selectedResultRow.nickname;
    var polygncoords = [];
    for (var i = 0; i < formattedCoords.length; i++) {
      polygncoords[i] = [formattedCoords[i].lat, formattedCoords[i].lng];
    }
    polygon.coords = polygncoords;
    allPolygons.push(polygon);

    setLeafletPolygons(allPolygons);
    // console.log(
    //   "LEAFLET Constructed Ploy Array : " + JSON.stringify(allPolygons)
    // );
  }

  function getParkCenter() {
    if (parkid == 1) {
      return defaultProps.PARKS.park1.center;
    } else if (parkid == 2) {
      return defaultProps.PARKS.park2.center;
    }
  }

  function showAlert(message) {
    toast.error(message, { containerId: "SearchError" });
  }

  function getFallbackImageComp() {
    try {
      return (
        <img
          src={require("assets/img/identified-leopards/YALA_NP/" +
            `${props.match.params.url_leopard_code}` +
            "/thumbnail.jpg")}
          alt={props.match.params.url_leopard_code}
          className="leopard-search-result-thumb"
        />
      );
    } catch (error) {
      return (
        <img
          src={require("assets/img/identified-leopards/default/deatult_leopard_low_size.jpg")}
          alt={props.match.params.url_leopard_code}
          className="results-page-thumbnail-img"
        />
      );
    }
  }

  React.useEffect(() => {
    if (isInitialMount.current) {
      console.log("URL PARAMATER : " + props.match.params.url_leopard_code);
      var selected_park = 0;
      GetLeopardMetaInformation(props.match.params.url_leopard_code)
        .then((response) => {
          setSelectedResultRow(response);
          setParkId(response.parkid);
          selected_park = response.parkid;
          console.log("LEOPARD BELONGING TO PARK : " + selected_park);
        })
        .then(() => {
          GetLeopardTerritory(props.match.params.url_leopard_code)
            .then((response) => {
              setTerritoryCoords(response);
            })
            .catch((err) => {
              showAlert("Error: " + err.message);
            });

          GetLeopardSeenAreas(props.match.params.url_leopard_code)
            .then((response) => {
              setSeenareas(response);
            })
            .catch((err) => {
              showAlert("Error: " + err.message);
            });

          GetAllCoordinatesForPark(selected_park)
            .then((response) => {
              setLocationcoords(response);
            })
            .catch((err) => {
              showAlert("Error: " + err.message);
            });
        })
        .catch((err) => {
          showAlert("Error: " + err.message);
        });

      isInitialMount.current = false;
    } else {
      if (territoryCoords != null && territoryCoords.length !== 0) {
        // if Google wait for the Maps API to be loaded before drawing.
        if (config.MAP_API_PROVIDER === "GOOGLE" && maps != null) {
          drawTerritoryPolygonsGoogleMaps();
        } else if (config.MAP_API_PROVIDER === "LEAFLET") {
          drawTerritoryPolygonsLeafletJS();
        }
      }
    }

    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [territoryCoords, maps]);

  const queryParams = new URLSearchParams(props.location.search);

  return (
    <>
      {SwitchNavBar(queryParams.get("source"))}
      <div className="wrapper">
        <div
          className="page-header clear-filter page-header-small-profile-page"
          filter-color="blue"
        >
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/blurred-image-yellow.jpg") + ")",
            }}
          ></div>

          <ToastContainer
            enableMultiContainer
            containerId={"SearchError"}
            position={toast.POSITION.TOP_RIGHT}
          />

          <Container>
            <div className="photo-container">{getFallbackImageComp()}</div>
            <h4 className="category">{selectedResultRow.officialnamecode}</h4>
            <h4 className="category">{selectedResultRow.nickname}</h4>
            <h5 className="category">{selectedResultRow.lt_name}</h5>
            
          </Container>
        </div>

        <div className="section results-page-section">
          <Container>
            <LeopardImageCarousel
              imagepath={config.LEOPARD_IMAGE_FOLDER_RELATIVE}
              officialnamecode={props.match.params.url_leopard_code}
            />
            <h3 className="title">Leopard Information</h3>

            <Table responsive striped>
              <tr>
                <td>
                  <h5 className="table-text">
                    <b>{"Official Name".toUpperCase()}</b>
                  </h5>
                </td>
                <td>
                  <h5 className="table-text">
                    {selectedResultRow.officialnamecode}
                  </h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="table-text">
                    <b>{"Nick Name ".toUpperCase()}</b>
                  </h5>
                </td>
                <td>
                  <h5 className="table-text">{selectedResultRow.nickname}</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="table-text">
                    <b>{"Gender".toUpperCase()}</b>
                  </h5>
                </td>
                <td>
                  <h5 className="table-text">{selectedResultRow.gender}</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="table-text">
                    <b>{"First Recorded Year".toUpperCase()}</b>
                  </h5>
                </td>
                <td>
                  <h5 className="table-text">
                    {selectedResultRow.firstrecordedyear}
                  </h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="table-text">
                    <b>{"Special Remarks".toUpperCase()}</b>
                  </h5>
                </td>
                <td>
                  <h5 className="table-text">
                    {selectedResultRow.description}
                  </h5>
                </td>
              </tr>
              <tr>
              <td>
                  <h5 className="table-text">
                    <b>{"Leopard Trails Identification".toUpperCase()}</b>
                  </h5>
                </td>
                <td>
                  <a href={selectedResultRow.lt_profile} target="_blank">
                  <h5 className="table-text">
                    {selectedResultRow.lt_name}
                  </h5>
                  </a>
                </td>
              </tr>
              {/* <tr>
                <td>
                  <h5 className="table-text">
                    <b>{"Hangout Zones".toUpperCase()}</b>
                  </h5>
                </td>
                <td>
                  {zones.map((zone, index) => (
                    // <h5 className="table-text">{zone}</h5>
                    <Badge color="info" className="mr-1">
                      {"Zone " + zone}
                    </Badge>
                  ))}
                </td>
              </tr> */}
            </Table>

            <h3 className="title">Family Tree</h3>
            <h5>
              The family tree has been developed using only the mothers
              genealogy (confirmed by observations from cub stage). The Father
              of a leopard can be determined only through proper DNA analysis,
              which we have not analyzed in the current research that our team
              conducts.{" "}
            </h5>

            {/* Hide the button once the tree is displayed */}
            {!openFamilyTree && (
              <Row>
                <Col className="text-center">
                  <Button
                    className="btn-round"
                    color="primary"
                    size="lg"
                    onClick={() => getFamilyTreeForLeopard()}
                  >
                    {" "}
                    {"View Family Tree For : " +
                      selectedResultRow.officialnamecode}
                  </Button>
                </Col>
              </Row>
            )}

            <Row>
              <Col md="12" sm="12" lg="12">
                {openFamilyTree && (
                  <div>
                    <FamilyTree
                      id="familyTreeContainer"
                      toggle={true}
                      codename={selectedResultRow.officialnamecode}
                      leopard_id={selectedResultRow.leopard_id}
                    ></FamilyTree>
                  </div>
                )}
              </Col>
            </Row>

            <h3 className="title">Frequently Seen Areas</h3>
            <Table responsive striped>
              <tr>
                <td>
                  <h5 className="table-text">
                    <b>Location</b>
                  </h5>
                </td>
                {/* <td>
                  <h5 className="table-text">
                    <b>Zone</b>
                  </h5>
                </td> */}
              </tr>

              {seenareas.map((area, index) => (
                <tr>
                  <td>
                    <h5 className="table-text">{area.location_name}</h5>
                  </td>
                  {/* <td>
                    <h5 className="table-text">{area.zone}</h5>
                  </td> */}
                </tr>
              ))}
            </Table>

            <h3 className="title">
              {selectedResultRow.nickname + "'s Home Range"}
            </h3>

            <p>
              (Disclaimer : These areas are only frequently seen areas of each
              Leopard, and are connected to each of the frequently seen areas.
              We are in the process of studying their individual home ranges
              using GPS based studies.)
            </p>

            {MAP_API_PROVIDER === "LEAFLET" && (
              <div className="google-map-container">
                <Map
                  center={getParkCenter()}
                  zoom={defaultProps.zoom}
                  maxZoom={defaultProps.zoom}
                >
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, &amp;copy <a href="http://www.openmaptiles.org/">OpenMapTiles</a>.'
                    url={config.APP_URLS.TILE_SERVER_SERVE_URL}
                    maxZoom={defaultProps.zoom}
                  />
                  {/* 
                Use the same marker as google maps 
                https://github.com/PaulLeCam/react-leaflet/issues/48#issuecomment-121339376 
                */}

                  {locationcoords.map((location, index) => (
                    <Marker
                      icon={divIcon({
                        className: "my-div-icon",
                        html: "<div class='hovicon effect-1 sub-a'/>",
                      })}
                      position={[
                        location.coordinates.split(",")[0],
                        location.coordinates.split(",")[1],
                      ]}
                      key={"location_" + index}
                    >
                      {/* <Popup>{location.location_name}</Popup> */}
                      <Tooltip>{location.location_name}</Tooltip>
                    </Marker>
                  ))}

                  {leafletPolygons.map((poly) => (
                    <Polygon color={"#f96332"} positions={poly.coords}>
                      <Tooltip>{poly.label}</Tooltip>
                    </Polygon>
                  ))}
                </Map>
              </div>
            )}

            {MAP_API_PROVIDER === "GOOGLE" && parkid != null && (
              <div className="google-map-container">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: config.GOOGLE_MAP_API_KEY,
                  }}
                  defaultCenter={getParkCenter()}
                  defaultZoom={defaultProps.zoom}
                  options={(map) => ({ mapTypeId: map.MapTypeId.SATELLITE })}
                  onGoogleApiLoaded={({ map, maps }) =>
                    setGoogleMapReference(map, maps)
                  }
                  yesIWantToUseGoogleMapApiInternals
                >
                  {locationcoords.map((location, index) => (
                    <GoogleMapMarker
                      lat={location.coordinates.split(",")[0]}
                      lng={location.coordinates.split(",")[1]}
                      text={location.location_name}
                      idForTooltip={"Tooltip" + index}
                    ></GoogleMapMarker>
                  ))}
                </GoogleMapReact>
              </div>
            )}
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default LeopardSearchResult;
