/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Container, Card, CardBody, Row } from "reactstrap";

function TipBox(props) {
  const [showInfoDialog, setShowInfoDialog] = React.useState(props.isOpen);

  return (
    <>
      {showInfoDialog && (
        <div className="popover-notification-box">
          <Card id="success-box">
            <CardBody>
              <Row>
                <div
                  className="dot"
                  onClick={() => {
                    setShowInfoDialog(false);
                  }}
                >
                  <i class="fas fa-times-circle"></i>
                </div>
              </Row>
              <Row>
                <div className="face">
                  <i className="fas fa-info-circle fa-4x"></i>
                </div>
              </Row>
              <Row>
                <div className="shadow scale"></div>
              </Row>

              <Row>
                <div className="message">
                  <h3>AI based automatic leopard identification</h3>
                  {/* <h5 className="bottomless message-header-size">
                    AI based automatic identification
                  </h5> */}
                  {/* <p className="message-description-size">
                    AI based automatic identification
                  </p> */}
                </div>
              </Row>
              <Row>
                <Link to="/match-identify-leopards">
                  <button className="button-box">
                    <h5 className="green bottomless">Start</h5>
                  </button>
                </Link>
              </Row>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
}

export default TipBox;
