import React from "react";

// reactstrap components
import { Button, Container, Row, Table, Card, Col } from "reactstrap";

// core components
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";
import AbouDWCParks_Bundala from "./parks/AbouDWCParks_Bundala.js";
import AbouDWCParks_FloodPlains from "./parks/AbouDWCParks_FloodPlains.js";
import AbouDWCParks_GalwaysLand from "./parks/AbouDWCParks_GalwaysLand.js";
import AbouDWCParks_Hikkaduwa from "./parks/AbouDWCParks_Hikkaduwa.js";
import AbouDWCParks_Horagolla from "./parks/AbouDWCParks_Horagolla.js";
import AbouDWCParks_Hortonplains from "./parks/AbouDWCParks_Hortonplains.js";
import AbouDWCParks_Kaudulla from "./parks/AbouDWCParks_Kaudulla.js";
import AbouDWCParks_Lahugala from "./parks/AbouDWCParks_Lahugala.js";
import AbouDWCParks_Lunugamvehera from "./parks/AbouDWCParks_Lunugamvehera.js";
import AbouDWCParks_MaduruOya from "./parks/AbouDWCParks_MaduruOya.js";
import AbouDWCParks_Minneriya from "./parks/AbouDWCParks_Minneriya.js";
import AbouDWCParks_Udawalawa from "./parks/AbouDWCParks_Udawalawa.js";
import AbouDWCParks_Wasgamuwa from "./parks/AbouDWCParks_Wasgamuwa.js";
import AbouDWCParks_Wilpattu from "./parks/AbouDWCParks_Wilpattu.js";
import AbouDWCParks_Kumana from "./parks/AbouDWCParks_Kumana.js";

var parkList = [
  {
    parkname: "Bundala National Park",
    image: "Bundala.png",
    comp: AbouDWCParks_Bundala,
  },
  {
    parkname: "Flood Plains National Park",
    image: "flood-plains.jpg",
    comp: AbouDWCParks_FloodPlains,
  },
  {
    parkname: "Galways National Park",
    image: "GalwaysLand.png",
    comp: AbouDWCParks_GalwaysLand,
  },
  {
    parkname: "Hikkaduwa National Park",
    image: "Hikkaduwa.png",
    comp: AbouDWCParks_Hikkaduwa,
  },
  {
    parkname: "Horagolla National Park",
    image: "Horagolla.png",
    comp: AbouDWCParks_Horagolla,
  },
  {
    parkname: "Horton Plains NP",
    image: "HortonPlains.png",
    comp: AbouDWCParks_Hortonplains,
  },
  {
    parkname: "Kaudulla National Park",
    image: "Kaudulla.png",
    comp: AbouDWCParks_Kaudulla,
  },
  {
    parkname: "Lahugala National Park",
    image: "Lahugala.png",
    comp: AbouDWCParks_Lahugala,
  },
  {
    parkname: "Lunugamvehera NP",
    image: "Lunugamvehera.png",
    comp: AbouDWCParks_Lunugamvehera,
  },
  {
    parkname: "Maduru Oya NP",
    image: "MaduruOya.png",
    comp: AbouDWCParks_MaduruOya,
  },
  {
    parkname: "Minneriya National Park",
    image: "Minneriya.png",
    comp: AbouDWCParks_Minneriya,
  },

  {
    parkname: "Udawalawa National Park",
    image: "Udawalawa.png",
    comp: AbouDWCParks_Udawalawa,
  },

  {
    parkname: "Wasgamuwa NP",
    image: "Wasgamuwa.png",
    comp: AbouDWCParks_Wasgamuwa,
  },

  {
    parkname: "Wilpattu National Park",
    image: "Wilpattu.png",
    comp: AbouDWCParks_Wilpattu,
  },

  {
    parkname: "Kumana National Park",
    image: "Kumana.png",
    comp: AbouDWCParks_Kumana,
  },
];

function AbouDWCParks(props) {
  const [showPark, setShowPark] = React.useState(false);
  const [currentPark, setCurrentPark] = React.useState(null);

  const scrollRef = React.createRef();

  function showParkComponent(comp) {
    setCurrentPark(comp);
    setShowPark(true);

    scrollRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  const queryParams = new URLSearchParams(props.location.search);

  return (
    <>
      {SwitchNavBar(queryParams.get("source"))}
      <div className="wrapper">
        <SubPageHeader title="Srilanka's Other National Parks & Sanctuaries" />

        <Container>
          <br />
          <br />
          <Row>
            {parkList.map((park, index) => (
              <Col lg="3" md="4" sm="6" xs="12" key={"results-" + index}>
                <Card>
                  <div className="team-player">
                    <img
                      src={require(`assets/img/aboutDWC/parks/${park.image}`)}
                      className="dwc-parks-image-thumbnail"
                      alt=""
                    ></img>
                    <h5 className="title dwc-parks-view-button">
                      {park.parkname}
                    </h5>
                    <div className="dwc-parks-view-button">
                      <Button
                        className="btn-round"
                        color="info"
                        type="button"
                        onClick={(e) => showParkComponent(park.comp)}
                      >
                        View &nbsp;{" "}
                        <i className="fab fas fa-arrow-circle-right"></i>
                      </Button>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>

          <div ref={scrollRef}>{showPark && currentPark}</div>

          <Row>
            <Col>
              <h3 className="title">All DWC National Parks and Sanctuaries</h3>

              <Table className="tablesorter" responsive>
                <thead>
                  <tr>
                    <th>National Parks</th>
                    <th>Date of Declaration</th>
                    <th>Extent (ha)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1. Ruhuna (yala)</td>
                    <td>1938.02.25</td>
                    <td>97880.7</td>
                  </tr>
                  <tr>
                    <td>2. Wilpattu</td>
                    <td>1938.02.25</td>
                    <td>131667.1</td>
                  </tr>
                  <tr>
                    <td>3. Galoya</td>
                    <td>1954.02.12</td>
                    <td>25900</td>
                  </tr>
                  <tr>
                    <td>4. Yala East (Kumana)</td>
                    <td>1970.01.20</td>
                    <td>18148.5</td>
                  </tr>
                  <tr>
                    <td>5. Udawalawa</td>
                    <td>1972.06.30</td>
                    <td>30821</td>
                  </tr>
                  <tr>
                    <td>6. Lahugala Kithulana</td>
                    <td>1980.10.31</td>
                    <td>1554</td>
                  </tr>
                  <tr>
                    <td>7. Maduru Oya</td>
                    <td>1983.11.09</td>
                    <td>58849.6</td>
                  </tr>
                  <tr>
                    <td>8. Wasgamawa</td>
                    <td>1984.08.07</td>
                    <td>37062.9</td>
                  </tr>
                  <tr>
                    <td>9. Floodplains</td>
                    <td>1984.08.07</td>
                    <td>17350</td>
                  </tr>
                  <tr>
                    <td>10. Somawathiya</td>
                    <td>1986.09.02</td>
                    <td>37645.5</td>
                  </tr>
                  <tr>
                    <td>11. Horton plains</td>
                    <td>1988.03.16</td>
                    <td>3159.8</td>
                  </tr>
                  <tr>
                    <td>12. Bundala</td>
                    <td>1993.01.04</td>
                    <td>6216</td>
                  </tr>
                  <tr>
                    <td>13. Lunugamwehera</td>
                    <td>1995.12.08</td>
                    <td>23498.8</td>
                  </tr>
                  <tr>
                    <td>14. Minneriya</td>
                    <td>1997.08.12</td>
                    <td>8889.4</td>
                  </tr>
                  <tr>
                    <td>15. Kaudulla</td>
                    <td>2002.04.01</td>
                    <td>6900</td>
                  </tr>
                  <tr>
                    <td>16. Hikkaduwa</td>
                    <td>2002.10.08</td>
                    <td>101.6</td>
                  </tr>
                  <tr>
                    <td>17. Pegion Island</td>
                    <td>2003.06.04</td>
                    <td>471.429</td>
                  </tr>
                  <tr>
                    <td>18. Horagolla</td>
                    <td>2004.07.28</td>
                    <td>13.362</td>
                  </tr>
                  <tr>
                    <td>19. Galwaysland</td>
                    <td>2006.05.18</td>
                    <td>29.24</td>
                  </tr>
                  <tr>
                    <td>20. Horrowpatana</td>
                    <td>2011.12.06</td>
                    <td>2570</td>
                  </tr>
                  <tr>
                    <td>21. Ussangoda</td>
                    <td>2010.05.06</td>
                    <td>349.077</td>
                  </tr>
                  <tr>
                    <td>22. Angammadilla</td>
                    <td>2006.06.06</td>
                    <td>7529.1</td>
                  </tr>
                  <tr>
                    <td>23. Madu Road</td>
                    <td>2015.06.22</td>
                    <td>16367.36</td>
                  </tr>
                  <tr>
                    <td>24. Chundikulam</td>
                    <td>2015.06.22</td>
                    <td>19565.33</td>
                  </tr>
                  <tr>
                    <td>25. Adam's Bridge</td>
                    <td>2015.06.22</td>
                    <td>18990</td>
                  </tr>
                  <tr>
                    <td>26. Delf</td>
                    <td>2015.06.22</td>
                    <td>1846.28</td>
                  </tr>
                </tbody>
              </Table>
              <br />

              <h3 className="title">DWC Nature Reserves</h3>

              <Table className="tablesorter" responsive>
                <thead>
                  <tr>
                    <th>Nature Reserves</th>
                    <th>Date of Declaration</th>
                    <th>Extent (ha)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="tg-0lax">1. Triconamadu</td>
                    <td class="tg-0lax">1986.10.24</td>
                    <td class="tg-0lax">25019.00</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">2. Riverine</td>
                    <td class="tg-0lax">1991.07.31</td>
                    <td class="tg-0lax">824.00</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">3. Minneriya-Giritale</td>
                    <td class="tg-0lax"></td>
                    <td class="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">Block 1</td>
                    <td class="tg-0lax">1988.02.12</td>
                    <td class="tg-0lax">7529.10</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">Block 2</td>
                    <td class="tg-0lax">1993.06.25</td>
                    <td class="tg-0lax">1924.00</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">Block 3</td>
                    <td class="tg-0lax">1995.07.07</td>
                    <td class="tg-0lax">4745.00</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">Block 4</td>
                    <td class="tg-0lax">1997.09.01</td>
                    <td class="tg-0lax">8336.00</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">4. Wetahirakanda</td>
                    <td class="tg-0lax">2002.06.07</td>
                    <td class="tg-0lax">3229.00</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">5. Peak Wilderness</td>
                    <td class="tg-0lax">2007.09.21</td>
                    <td class="tg-0lax">12979.00</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">6. Nagar kovil</td>
                    <td class="tg-0lax">2016.03.01</td>
                    <td class="tg-0lax">7882.00</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">7. Vedithalathivu</td>
                    <td class="tg-0lax">2016.03.01</td>
                    <td class="tg-0lax">29180.00</td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <h3 className="title">DWC Elephant Corridor</h3>
              <Table className="tablesorter" responsive>
                <thead>
                  <tr>
                    <th>Elephant Corridor</th>
                    <th>Date of Declaration</th>
                    <th>Extent (ha)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="tg-0lax">1. Kaudulla - Minneriya</td>
                    <td class="tg-0lax">2004.06.01</td>
                    <td class="tg-0lax">8777.00</td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <h3 className="title">DWC Strict Nature Reserves</h3>
              <Table className="tablesorter" responsive>
                <thead>
                  <tr>
                    <th>Strict Nature Reserves</th>
                    <th>Date of Declaration</th>
                    <th>Extent (ha)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="tg-0lax">1.Hakgala</td>
                    <td class="tg-0lax"> 1938.02.25</td>
                    <td class="tg-0lax">1142.00</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">2.Yala</td>
                    <td class="tg-0lax"> 1938.03.01</td>
                    <td class="tg-0lax">28905.00</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">3.Ritigala</td>
                    <td class="tg-0lax">1941.11.07</td>
                    <td class="tg-0lax">1528.00</td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <h3 className="title">DWC Sanctuaries</h3>
              <Table className="tablesorter" responsive>
                <thead>
                  <tr>
                    <th>Santuaries</th>
                    <th>Date of Declaration</th>
                    <th>Extent (ha)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="tg-0lax">1. Willpattu noth</td>
                    <td class="tg-0lax">25.02.1938</td>
                    <td class="tg-0lax">632</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">2. telwatte</td>
                    <td class="tg-0lax">25.02.1938</td>
                    <td class="tg-0lax">1424.5</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">3. Weerawila-Tissa</td>
                    <td class="tg-0lax">27.05.1938</td>
                    <td class="tg-0lax">4164.2</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">4. Katagamuwa</td>
                    <td class="tg-0lax">27.05.1938</td>
                    <td class="tg-0lax">1003.6</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">5. Polonnaruwa</td>
                    <td class="tg-0lax">27.05.1938</td>
                    <td class="tg-0lax">1521.6</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">6. Galwaysland</td>
                    <td class="tg-0lax">27.05.1938</td>
                    <td class="tg-0lax">56.6</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">7. Tangamalai</td>
                    <td class="tg-0lax">27.05.1938</td>
                    <td class="tg-0lax">131.5</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">8. Mihintale</td>
                    <td class="tg-0lax">27.05.1938</td>
                    <td class="tg-0lax">999.6</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">9. Kataragama</td>
                    <td class="tg-0lax">27.05.1938</td>
                    <td class="tg-0lax">837.7</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">10. Anuradhapura</td>
                    <td class="tg-0lax">27.05.1938</td>
                    <td class="tg-0lax">3500.5</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">11. Udawattekale</td>
                    <td class="tg-0lax">29.07.1938</td>
                    <td class="tg-0lax">1041.2</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">12. Rock Islets -Ambalangoda</td>
                    <td class="tg-0lax">25.10.1940</td>
                    <td class="tg-0lax">1.2</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">13. Peakwildeness</td>
                    <td class="tg-0lax">25.10.1940</td>
                    <td class="tg-0lax">22379.1</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">14. Kegalle-Kurulu Kale</td>
                    <td class="tg-0lax">14.03.1941</td>
                    <td class="tg-0lax">113.3</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">15. Welhilla katagilla</td>
                    <td class="tg-0lax">18.02.1949</td>
                    <td class="tg-0lax">134.3</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">16. Kokilai</td>
                    <td class="tg-0lax">18.05.1951</td>
                    <td class="tg-0lax">1995</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">17. Senanayeka samudraya</td>
                    <td class="tg-0lax">12.02.1954</td>
                    <td class="tg-0lax">9324</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">18. Galoya -North Eastern</td>
                    <td class="tg-0lax">12.02.1954</td>
                    <td class="tg-0lax">12432</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">19. Galoya -South East</td>
                    <td class="tg-0lax">12.02.1954</td>
                    <td class="tg-0lax">15281</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">20. Yodha wewe</td>
                    <td class="tg-0lax">24.09.1954</td>
                    <td class="tg-0lax">4330.1</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">21. Vaunikulam</td>
                    <td class="tg-0lax">21.06.1963</td>
                    <td class="tg-0lax">4856.2</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">22. Sagamam</td>
                    <td class="tg-0lax">21.06.1963</td>
                    <td class="tg-0lax">616.4</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">23. Padawiya wewa</td>
                    <td class="tg-0lax">21.06.1963</td>
                    <td class="tg-0lax">6475</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">24. Trincomali Novel base</td>
                    <td class="tg-0lax">21.06.1963</td>
                    <td class="tg-0lax">18130</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">25. Lokusobara Island</td>
                    <td class="tg-0lax">21.06.1963</td>
                    <td class="tg-0lax">64.7</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">26. Kudasobara Island</td>
                    <td class="tg-0lax">21.06.1963</td>
                    <td class="tg-0lax">6.5</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">27. Kimbulwana oya</td>
                    <td class="tg-0lax">21.06.1963</td>
                    <td class="tg-0lax">492.1</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">28. Mahakandarawa Wewa</td>
                    <td class="tg-0lax">09.12.1966</td>
                    <td class="tg-0lax">519.33</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">29. Seruwila Allei</td>
                    <td class="tg-0lax">09.10.1970</td>
                    <td class="tg-0lax">15540</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">30. Ma embulkanda-Nittabuwa</td>
                    <td class="tg-0lax">31.10.1972</td>
                    <td class="tg-0lax">23.5</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">31. Honduwa Island</td>
                    <td class="tg-0lax">19.11.1973</td>
                    <td class="tg-0lax">8.5</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">32. Buddangala</td>
                    <td class="tg-0lax">01.11.1974</td>
                    <td class="tg-0lax">1841.3</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">33. Rawana ella</td>
                    <td class="tg-0lax">18.05.1979</td>
                    <td class="tg-0lax">1932</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">34. Madin Duwa</td>
                    <td class="tg-0lax">06.06.1980</td>
                    <td class="tg-0lax">0.8</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">35. Kalamatiya</td>
                    <td class="tg-0lax">28.06.1984</td>
                    <td class="tg-0lax">2525.2</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">36. Sri jayawardenapura</td>
                    <td class="tg-0lax">09.01.1985</td>
                    <td class="tg-0lax">449.2</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">37. Victoriya randenigala Rantebe</td>
                    <td class="tg-0lax">30.01.1987</td>
                    <td class="tg-0lax">42087.3</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">38. Paraputuwa Meheni</td>
                    <td class="tg-0lax">17.08.1988</td>
                    <td class="tg-0lax">189.6</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">39. Kahala-pallekale</td>
                    <td class="tg-0lax">01.07.1989</td>
                    <td class="tg-0lax">21690</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">40. Sigiriya</td>
                    <td class="tg-0lax">26.01.1990</td>
                    <td class="tg-0lax">5099</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">41. Bellanvila attidiya</td>
                    <td class="tg-0lax">25.07.1990</td>
                    <td class="tg-0lax">372</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">42. Welipara</td>
                    <td class="tg-0lax">03.04.1992</td>
                    <td class="tg-0lax">30669.9</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">43. Nimalawa</td>
                    <td class="tg-0lax">18.02.1993</td>
                    <td class="tg-0lax">1065.8</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">44. Madunagala</td>
                    <td class="tg-0lax">30.06.1993</td>
                    <td class="tg-0lax">995.2</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">45. Muthiraja wela I</td>
                    <td class="tg-0lax">31.10.1996</td>
                    <td class="tg-0lax">1028.6</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">46. Muthuraja wela II</td>
                    <td class="tg-0lax">31.10.1996</td>
                    <td class="tg-0lax">256.8</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">47. Anawilundawa</td>
                    <td class="tg-0lax">11.06.1997</td>
                    <td class="tg-0lax">1397</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">48. Elahara- Giritale</td>
                    <td class="tg-0lax">13.01.2000</td>
                    <td class="tg-0lax">14035.2</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">49. Dahaiyagala</td>
                    <td class="tg-0lax">07.06.2002</td>
                    <td class="tg-0lax">2685.07</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">50. Tabbowa</td>
                    <td class="tg-0lax">19.07.2002</td>
                    <td class="tg-0lax">2193.31</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">51. Rumassala</td>
                    <td class="tg-0lax">03.01.2003</td>
                    <td class="tg-0lax">170.7</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">52. Kirala kale</td>
                    <td class="tg-0lax">08.09.2003</td>
                    <td class="tg-0lax">310</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">53. Elluwila yaya</td>
                    <td class="tg-0lax">11.09.2003</td>
                    <td class="tg-0lax">186</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">54. Kiramaoya</td>
                    <td class="tg-0lax">06.10.2004</td>
                    <td class="tg-0lax">5</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">55. Kudumbigala-Panama</td>
                    <td class="tg-0lax">20.02.2006</td>
                    <td class="tg-0lax">6534</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">56. Godawaya</td>
                    <td class="tg-0lax">25.05.2006</td>
                    <td class="tg-0lax">232</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">57. Rekawa</td>
                    <td class="tg-0lax">25.05.2006</td>
                    <td class="tg-0lax">271</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">58. Wilmanna</td>
                    <td class="tg-0lax">30.06.2006</td>
                    <td class="tg-0lax">3340</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">59. Maduganga</td>
                    <td class="tg-0lax">17.07.2006</td>
                    <td class="tg-0lax">2300</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">60. Madampawila</td>
                    <td class="tg-0lax">21.09.2007</td>
                    <td class="tg-0lax">1217.75</td>
                  </tr>
                  <tr>
                    <td class="tg-0lax">61. Vankellei</td>
                    <td class="tg-0lax">08.09.2008</td>
                    <td class="tg-0lax">4839</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default AbouDWCParks;
