import React from "react";

// reactstrap components
import {
 
  Row,

  Col,
} from "reactstrap";

function AbouDWCParks_Hikkaduwa() {
  return (
    <>
      <Row>
        <Col>
          <h3 className="title">Hikkaduwa National Park</h3>
          <img
            src={require("assets/img/aboutDWC/parks/Hikkaduwa.png")}
            className="dwc-parks-image"
            alt="Hikkaduwa National Park"
          ></img>
          <h4>Introduction</h4>
          <p>
            The national park is lies on the south west coast in Southern
            province, approximately 100km from south of Colombo and it is
            located at 6° 08’N, 80° 05’E.
          </p>
          <h4>ACCESS</h4>
          <p>
            <ul>
              <li>
                The park office is situated at Hikkaduwa on Colombo- Galle road.
              </li>
            </ul>
          </p>
          <h4>PHYSICAL CHARACTERRISTICS</h4>
          <p>
            The reef at Hikkaduwa extends about 130m seawards before dropping
            sharply to soft substrate at 7-10m. A few rocky islets are located
            in the near shore area and the shoreline protection system is formed
            by coastal sandstone and submarine coral reefs. The area forms the
            Wet Zone receiving an average annual rainfall over 2000mm. The south
            west monsoon period is severe on the coast and average over 280mm of
            rainfall during the months of mid April to mid June and the
            northeast monsoon period from mid September through November
            averaging about 225mm of rain per month. Very strong winds blow
            during the southwest monsoon.
          </p>
          <h4>VEGITATION</h4>
          <p>
            Inshore reefs feature a narrow band of sea grass before a Halimeda
            zone. On seaward slopes.
          </p>
          <h4>FAUNA</h4>
          <p>
            The most interesting fauna are found in the marine ecosystem of the
            coral reefs. These include the non reef fish ornamental and rare
            reef fish, molluscans species and diverse live coralline species.
            Sixty coral species representing 31 genera are found. A total of 113
            species of fish has been recorded. This park is home for eight
            butterfly fish species too.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default AbouDWCParks_Hikkaduwa;
