import React from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Container,
  Row,
  Col,
  InputGroupText,
  Collapse,
  Badge,
} from "reactstrap";

import Select from "react-select";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

// core components
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";
import { ToastContainer, toast } from "react-toastify";
import { react_select_styles } from "../../utils/React-Select-Styling.js";
import { NationalParkList } from "../../variables/AllNationalParks.js";

import LoadingOverlay from "react-loading-overlay";
import PulseLoader from "react-spinners/PulseLoader";

import { SearchBySearchQuery } from "../../utils/SearchBySearchQueryPromise";
import { SearchByFilters } from "../../utils/SearchByFiltersPromise";
import { GetAllLocationsForPark } from "../../utils/GetAllLocationsForParkPromise";
import { AIAssistedMatch } from "../../utils/AIAssistedMatchPromise";

import config from "../../config.json";

function ManualLeopardMatchResults(props) {
  const [searchResults, setSearchResults] = React.useState([]);

  const [curentResultIndex, setCurentResultIndex] = React.useState(0);
  const [curentResultImg, setCurentResultImg] = React.useState(null);
  const [allTerritoryLocations, setAllTerritoryLocations] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filterZone, setFilterZone] = React.useState("");
  const [filterGender, setFilterGender] = React.useState("");
  const [showLoadingView, setShowLoadingView] = React.useState(false);
  const [rightFocus, setRightFocus] = React.useState(false);
  const national_parks_list = NationalParkList;
  const [park, setPark] = React.useState("1");
  const [selectedAreaValue, setSelectedAreaValue] = React.useState(null);
  const [isAiAssisted, setAiAssisted] = React.useState(true);
  const [isSearchCompleted, setIsSearchCompleted] = React.useState(false);
  const [isFitersOpen, setIsFitersOpen] = React.useState(false);

  const history = useHistory();

  function showLoading(isShown) {
    return (
      <LoadingOverlay
        active={isShown}
        spinner={<PulseLoader />}
        text=""
      ></LoadingOverlay>
    );
  }

  function showAlert(message) {
    //("Showing Alert : " + JSON.stringify(message));
    toast.error(message, { containerId: "SearchError" });
  }

  function changeSearchQuery(event) {
    setFilterGender(null);
    setFilterZone(null);

    // console.log("Changing the value of 'searchQuery' to " + event.target.value);

    setSearchQuery(event.target.value);
  }

  function getNextImageForMatching() {
    var nextIndex = curentResultIndex + 1;
    if (nextIndex >= searchResults.length) {
      return;
    }
    setCurentResultIndex(nextIndex);

    //console.log("Get me images of  : " + nextIndex);

    // Future reference why the hardcoded path :
    // https://stackoverflow.com/questions/41769385/react-cant-find-module-trying-to-pass-cell-image-path-variable#
    // Fix this

    setCurentResultImg(
      require("assets/img/identified-leopards/YALA_NP/" +
        searchResults[nextIndex].officialnamecode +
        "/thumbnail.jpg")
    );
  }

  function getPrevImageForMatching() {
    var prevIndex = curentResultIndex - 1;
    if (prevIndex <= -1) {
      return;
    }
    setCurentResultIndex(prevIndex);

    //console.log("Get me images of  : " + prevIndex);

    // Future reference why the hardcoded path :
    // https://stackoverflow.com/questions/41769385/react-cant-find-module-trying-to-pass-cell-image-path-variable#
    // Fix this

    setCurentResultImg(
      require("assets/img/identified-leopards/YALA_NP/" +
        searchResults[prevIndex].officialnamecode +
        "/thumbnail.jpg")
    );
  }

  function setSearchResultsList(results) {
    setSearchResults(results);
    setCurentResultImg(
      require("assets/img/identified-leopards/YALA_NP/" +
        results[0].officialnamecode +
        "/thumbnail.jpg")
    );
  }

  function applySearchFilters(skipAiMatch) {
    setIsSearchCompleted(false);
    setCurentResultIndex(0);
    setCurentResultImg(require("assets/img/loading-icon.svg"));

    if (searchQuery != null && searchQuery != "") {
      SearchBySearchQuery(searchQuery, park)
        .then((response) => {
          console.log("$$$ FROM FILTER 1 : " + JSON.stringify(response));
          return response;
        })
        .then((response) => {
          console.log("$$$ FROM FILTER 2 : " + JSON.stringify(response));
          if (!skipAiMatch && isAiAssisted) {
            // call match endpoint here
            return AIAssistedMatch(
              response,
              props.location.state.uploadSessionID
            )
              .then((response) => {
                console.log("$$$ FROM PY : " + JSON.stringify(response));
                return response;
              })
              .catch(function (error) {
                showAlert("Error: " + error.message);
              });
          } else {
            console.log("NOT AI ASSISTED. RETURNING AS RECEIVED..");
            return response;
          }
        })
        .then((response) => {
          console.log("$$$ FROM FILTER 3 : " + JSON.stringify(response));
          setSearchResults(response);
          setCurentResultImg(
            require("assets/img/identified-leopards/YALA_NP/" +
              response[0].officialnamecode +
              "/thumbnail.jpg")
          );
          setIsSearchCompleted(true);
        })
        .catch(function (error) {
          showAlert("Error: " + error.message);
        });
    } else {
      SearchByFilters(park, filterZone, filterGender)
        .then((response) => {
          console.log("$$$ FROM FILTER 2 : " + JSON.stringify(response));
          if (!skipAiMatch && isAiAssisted) {
            // call match endpoint here
            return AIAssistedMatch(
              response,
              props.location.state.uploadSessionID
            )
              .then((response) => {
                console.log("$$$ FROM PY : " + JSON.stringify(response));
                return response;
              })
              .catch(function (error) {
                showAlert("Error: " + error.message);
              });
          } else {
            console.log("NOT AI ASSISTED. RETURNING AS RECEIVED..");
            return response;
          }
        })
        .then((response) => {
          setSearchResults(response);
          setCurentResultImg(
            require("assets/img/identified-leopards/YALA_NP/" +
              response[0].officialnamecode +
              "/thumbnail.jpg")
          );
          setIsSearchCompleted(true);
        })
        .catch(function (error) {
          showAlert("Error: " + error.message);
        });
    }
  }

  function getAllLocations(parkid) {
    GetAllLocationsForPark(parkid)
      .then((response) => {
        setAllTerritoryLocations(response);
      })
      .catch((err) => {
        //console.log("Error captured!!! : " + err);

        showAlert(
          "Unable to fetch all seen area locations. Error: " + err.message
        );
      });
  }

  function getAndSaveMatchedImg() {
    setShowLoadingView(true);
    // console.log(
    //   "Current selected leopard : " +
    //     JSON.stringify(searchResults[curentResultIndex])
    // );
    fetch(
      config.APP_URLS.BACKEND_SERVER_API +
        "/web/identify-given-leopard/" +
        props.location.state.uploadSessionID +
        "/combine-images",
      {
        method: "POST",
        body: JSON.stringify({
          target_leopard_code:
            searchResults[curentResultIndex].officialnamecode,
          nickname: searchResults[curentResultIndex].nickname,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setShowLoadingView(false);
        // console.log("WATERMARKED BASE64 : " + JSON.stringify(json));

        // If no results found, show toast and exit
        if (json.status != "success") {
          showAlert("Unable to download the matched image");
          return;
        } else {
          const base64string = json.watermarkedImageBase64;
          const pageImage = new Image();
          pageImage.src = "data:image/jpg;base64," + base64string;
          pageImage.onload = function () {
            const canvas = document.createElement("canvas");
            canvas.width = pageImage.naturalWidth;
            canvas.height = pageImage.naturalHeight;

            const ctx = canvas.getContext("2d");
            ctx.imageSmoothingEnabled = false;
            ctx.drawImage(pageImage, 0, 0);
            // console.log(canvas, pageImage);

            let fileName = props.location.state.uploadSessionID;
            const link = document.createElement("a");
            link.download = fileName + ".jpg";
            //console.log(canvas);
            canvas.toBlob(function (blob) {
              // console.log(blob);
              link.href = URL.createObjectURL(blob);
              link.click();
            });
          };
        }
      })
      .catch((err) => {
        setShowLoadingView(false);
        //console.log("Error captured!!! : " + err);

        showAlert(
          "Unable to fetch all seen area locations. Error: " + err.message
        );
      });
  }

  function getBadgeForConfidence(resultIndex, confidence, matchedpoints) {
    console.log("SEARCH RES : " + JSON.stringify(searchResults));
    console.log("Confidence : " + confidence);
    if (confidence == "Low") {
      return (
        <div>
          <Row>
            <Badge color="info" className="mr-1">
              {"Match #" + (resultIndex + 1)}
            </Badge>
          </Row>
          <Row>
            <Badge color="primary" className="mr-1">
              {"Confidence : " + confidence}
            </Badge>
          </Row>
          <Row>
            <Badge color="info" className="mr-1">
              {"Matched Points : " + matchedpoints}
            </Badge>
          </Row>
        </div>
      );
    } else if (confidence == "High") {
      return (
        <div>
          <Row>
            <Badge color="info" className="mr-1">
              {"Match #" + (resultIndex + 1)}
            </Badge>
          </Row>
          <Row>
            <Badge color="success" className="mr-1">
              {"Confidence : " + confidence}
            </Badge>
          </Row>
          <Row>
            <Badge color="info" className="mr-1">
              {"Matched Points : " + matchedpoints}
            </Badge>
          </Row>
        </div>
      );
    }
  }

  function getLocationsForNP(selectedNP) {
    // console.log(">>>>>>>>> SELECTED NP : " + JSON.stringify( selectedNP))
    setSelectedAreaValue(null);
    setFilterZone(null);
    setPark(selectedNP);
    getAllLocations(selectedNP);
  }

  function openCloseFilters() {
    if (isFitersOpen) {
      setIsFitersOpen(false);
    } else {
      setIsFitersOpen(true);
    }
  }

  React.useEffect(() => {
    console.log("PROPS FROM upload : " + JSON.stringify(props));
    getAllLocations(park);
    applySearchFilters(false);
  }, []);

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      {SwitchNavBar()}
      <div className="wrapper">
        <SubPageHeader title="Match Leopard" />

        <ToastContainer
          enableMultiContainer
          containerId={"SearchError"}
          position={toast.POSITION.TOP_RIGHT}
        />
        <Container>
          <br />
          <div>
            <h5>
              The leopard image is being matched with the entire database with
              the help of artificial intelligence.{" "}
            </h5>
            <h5>
              You can filter the database to narrow down the search or turn off
              the AI assisted match to do a manual match.{" "}
            </h5>

            <label>{"Show More Filters : "}&nbsp;</label>
            <Switch
              onChange={(val, event) => openCloseFilters(val)}
              onClick={(val, event) => openCloseFilters(val)}
              disabled={false}
              checkedChildren="On"
              unCheckedChildren="Off"
              defaultChecked={isFitersOpen}
              checked={isFitersOpen}
              className="switch-width-control"
            />

            <Collapse isOpen={isFitersOpen}>
              <hr />
              <FormGroup>
                <Row>
                  <Col md="12" lg="12" sm="12">
                    <InputGroup
                      className={rightFocus ? "input-group-focus" : ""}
                    >
                      <Input
                        placeholder="Search By Nick Name or Official Codename"
                        type="text"
                        autoFocus
                        onFocus={() => setRightFocus(true)}
                        onBlur={() => setRightFocus(false)}
                        onChange={(e) => changeSearchQuery(e)}
                      ></Input>
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_zoom-bold"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4" lg="4" sm="6">
                    <FormGroup>
                      <label>National Park</label>
                      <Select
                        //Value={park}
                        defaultValue={national_parks_list[0]}
                        name="zone"
                        onChange={(e) => getLocationsForNP(e.value)}
                        className="searchable-dropdown basic-single"
                        classNamePrefix="select"
                        isSearchable={false}
                        options={national_parks_list}
                        // rounded corners to match others
                        styles={react_select_styles}
                      />
                      {/* <Input placeholder="Yala National Park" disabled>
                        Yala National Park
                      </Input> */}
                    </FormGroup>
                  </Col>
                  <Col md="4" lg="4" sm="6">
                    <FormGroup>
                      <label>Seen Area</label>
                      <Select
                        onChange={(e) => setFilterZone(e.value)}
                        className="searchable-dropdown basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        name="seen-area"
                        options={allTerritoryLocations}
                        isDisabled={
                          searchQuery != null && searchQuery != ""
                            ? true
                            : false
                        }
                        // rounded corners to match others
                        styles={react_select_styles}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="4" lg="4" sm="6">
                    <FormGroup>
                      <label>Gender</label>
                      <Select
                        name="filterGender"
                        onChange={(e) => setFilterGender(e.value)}
                        className="searchable-dropdown basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        options={[
                          { value: "", label: "Unknown" },
                          { value: "male", label: "Male" },
                          { value: "female", label: "Female" },
                        ]}
                        // rounded corners to match others
                        styles={react_select_styles}
                        isDisabled={
                          searchQuery != null && searchQuery != ""
                            ? true
                            : false
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4" lg="4" sm="6">
                    <FormGroup>
                      <label>{"AI Assisted Match "}&nbsp;</label>
                      <Switch
                        onChange={(val, event) => setAiAssisted(val)}
                        onClick={(val, event) => setAiAssisted(val)}
                        disabled={false}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        defaultChecked={isAiAssisted}
                        checked={isAiAssisted}
                        className="switch-width-control"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="text-center">
                    <Button
                      className="btn-round"
                      color="info"
                      type="button"
                      size="lg"
                      onClick={() => applySearchFilters()}
                    >
                      Filter
                    </Button>
                  </Col>
                </Row>
                <br />
              </FormGroup>
            </Collapse>
          </div>

          <hr />

          <div className="text-center">
            <Row>
              <Col lg="6" md="6" sm="6" xs="6">
                <Button
                  className="btn-round"
                  //color="info"
                  type="button"
                  onClick={() => getPrevImageForMatching()}
                >
                  <i class="fas fa-angle-double-left"></i>
                  {"  Prev."}
                </Button>
              </Col>
              <Col lg="6" md="6" sm="6" xs="6">
                <Button
                  className="btn-round"
                  // color="info"
                  type="button"
                  onClick={() => getNextImageForMatching()}
                >
                  {"Next  "}
                  <i class="fas fa-angle-double-right"></i>
                </Button>
              </Col>
            </Row>

            {/* {searchResults.length > 0 && ( */}
            <div>
              <Row>
                <Col
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="match-leopard-sidebyside-column"
                >
                  <img
                    src={
                      "data:image/png;base64," +
                      props.location.state.croppedUploadedImage
                    }
                    alt="Your Image"
                    className="match-leopard-sidebyside-img"
                  />
                </Col>
                <Col
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="match-leopard-sidebyside-column"
                >
                  <img
                    src={curentResultImg}
                    alt="Searched Leopard"
                    className="match-leopard-sidebyside-img"
                  />

                  {isSearchCompleted && (
                    <div>
                      <div className="match-leopard-sidebyside-bottom-right-text">
                        {searchResults[curentResultIndex].officialnamecode +
                          " - " +
                          searchResults[curentResultIndex].nickname}
                      </div>

                      {isAiAssisted && (
                        <div className="match-leopard-sidebyside-top-left-confidence">
                          {getBadgeForConfidence(
                            curentResultIndex,
                            searchResults[curentResultIndex].confidence,
                            searchResults[curentResultIndex].matchedpoints
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            {/* )} */}
          </div>
          <div className="text-center">
            <Row>
              <Col lg="6" md="6" sm="6" xs="6">
                <Button
                  className="btn-round"
                  // size="lg"
                  color="warning"
                  onClick={() => history.goBack()}
                >
                  <i className="fab fas fa-arrow-circle-left"></i>
                  {" Cancel"}
                </Button>
              </Col>
              <Col lg="6" md="6" sm="6" xs="6">
                <Button
                  className="btn-round"
                  // size="lg"
                  color="info"
                  onClick={() => getAndSaveMatchedImg()}
                >
                  <i className="fab fas fa-arrow-circle-down"></i>
                  {"  Save Match"}
                </Button>
              </Col>
            </Row>
            {showLoadingView && showLoading(showLoadingView)}
          </div>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default ManualLeopardMatchResults;
