import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
} from "reactstrap";

// core components
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";
import { Link } from "react-router-dom";

function YalaLeopardsHome() {

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      {SwitchNavBar()}
      <div className="wrapper">
        <SubPageHeader title="Yala Leopards" />

        <Container>
          <br />
          <h3 className="title"> Our Identification Convention </h3>
          <Row>
            <p>
              We have used the latest Leopard identification method which was
              developed by our research team after thorough analysis, in
              identifying the Leopards of the Yala National Park- Block-1. Such
              identified Leopards have all been given a Gender based Code and a
              Nick name. Our naming Convention differs from the naming
              convention of the African national parks and private reserves (By
              using the 1 st row spots of the Mystacial area, Gender and a
              location of the territory). A unique naming convention was adopted
              by us due to the following reasons...
            </p>
            <p>
              <ul>
                <li>
                  The Leopards of Yala are not Solitary and are not Confined to
                  a Territory, as in Africa We believe that the reason for such
                  behavior is that the Leopard in Africa is not the Apex
                  predator and the availability of prey for African Leopards are
                  limited. The scenario of the Leopards of the Yala national
                  Park- Block-1 is quite different. They are the Apex predator
                  of the park . The Abundance of water and the prey base makes
                  the Leopard behavior in Yala different. Our research work has
                  proven that the Leopard of Yala share their home ranges
                  amongst individuals of both genders. Our research work has
                  also proven that there are positive preferential interactions
                  amongst Leopards in Yala.
                </li>
                <li>
                  Due to the sharing of home ranges, we find that there are many
                  Leopards who bear the same Mystacial spot count, in the same
                  area.
                </li>
                <li>
                  The Yala National Park, Block -1 is only 141 Sq Km’s and there
                  are many prominent locations which are named, close to one
                  another within this area. As an example, the Location Pan wala
                  and Kotabendi weva are 2 locations which is only 330 meters apart.
                  We do not see any Logic in naming a Leopard as per nearby
                  locations.
                </li>
                <li>
                  To make the Wildlife loving public more aware, interested and
                  conscious of the identified Leopards by adopting a simple nick
                  name in addition to the scientific coding we have given,
                  instead of a confusing naming convention. We passionately
                  believe that the public must be aware and conscious of what we
                  strive to protect and conserve. We believe that greater
                  conservation can be achieved through greater interest of all
                  stakeholders and simplifying identifications can make more
                  people interested and concerned of the Leopards they sight.
                </li>
              </ul>
            </p>
          </Row>

          <h3 className="title">Overview</h3>
          <p>
            Our research team is the first in the World to establish that the
            Leopards spots and Rosettes patterns change with deep injury. These
            changes can be misleading in conventional identification methods (
            <a href="/projects/research">
              Please refer the abstracts published
            </a>
            ). The research team developed the Multi point Leopard
            identification method , where 15 segregations of a Leopard is taken
            into consideration when identfying a Leopard. This method provides
            accurate identities of a Leopard, while minimising the possibilities
            of innacurate identifications.
          </p>
          <br />
          <p>
            <Row>
              <Col md="12" sm="12" lg="12">
                <img
                  src={require("assets/img/methodology/Methodology_front.jpg")}
                  //zoomPosition="original"
                  alt=""
                />
              </Col>
              <Col md="12" sm="12" lg="12">
                <img
                  src={require("assets/img/methodology/Methodology_left.jpg")}
                  //zoomPosition="original"
                  alt=""
                />
              </Col>
              <Col md="12" sm="12" lg="12">
                <img
                  src={require("assets/img/methodology/Methodology_right.jpg")}
                  //zoomPosition="original"
                  alt=""
                />
              </Col>
            </Row>
          </p>
          <p>
            We have identified 122 Leopards since the year 2012, at the Yala
            National Park, block - 1, of where all identities have been
            confirmed using the multi point identification method.{" "}
          </p>
          <p>
            A Census Survey was done by the research team (refer abstracts
            published). It was established that 68 Leopards of the identified
            Leopards were sighted during the period of 15 months from January
            2020 to March 2021. This indicates that the minimum Leopard
            population of the Yala National park- Block - 1 , is 68 Leopards.
            The area where the identification was done was 118.41 Sq. Km's. The
            minimum density of Leopards at the identification area of the Yala
            National park is 59.41 Leopards per 100 Sq. Km's, which makes Yala
            the park with the highest density of Leopards in the world.
          </p>

          <p>
            The identities of all Leopards identified are listed{" "}
            <a href="/search-identified-leopards">here</a>. We have also listed
            the <a href="/leopard-homeranges">Home ranges</a> of the 68 Leopards
            that were confirmed as present in the park, by way of census survey.
            The overlapping home ranges of these leopards can be derived too.
          </p>
          <br />

          <h3 className="title">Naming Convention</h3>

          <p>
            Providing unique IDs to Leopards for easy identification
            <br />
            <br />
            <ul className="list-unstyled">
              <li>
                Male Leopards (Block 01) – <b>YM##</b>{" "}
              </li>
              <li>
                Male Leopards (Block 05) – <b>YB5M##</b>{" "}
              </li>
              <br />
              <li>
                Female Leopards (Block 01)– <b>YF##</b>
              </li>
              <li>
                Female Leopards (Block 05) – <b>YB5F##</b>{" "}
              </li>
            </ul>
            Nick name (Ex: <b>YF01 – Jeena</b> | <b>YB5F02 – Bella</b>) has been
            provided for easy reference
            <br />
            <br />
            <p className="text-danger">
            Cubs are monitored until sub adult stage and listed at an estimated age of between 12 months to 18 months of age.
            <br />
            Adult leopards are listed if there is sufficient photographic evidence of even one sighting.
            </p>
          </p>

          <h3 className="title">Age Classfication</h3>
          <p>
            The age estimation was done by considering the observation period of
            a particular Leopard, where photographic data was available. If the
            data is not available, the research team did a detailed comparison
            of the physical appearance of the Leopard , with many Leopards in
            the database of the research team and came into conclusion about the
            age of the Leopard in concern.
          </p>
          <p>
            We have categorized the Listed Leopards in to 05 age brackets as
            follows.
          </p>

          <Row>
            <Col md="36">
              <table className="table" responsive>
                <thead>
                  <tr>
                    <th width="200">Age category</th>
                    <th width="200">Abbreviation used</th>
                    <th>Basis of separation</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="200">
                      <p>Cub</p>
                    </td>
                    <td width="200">
                      <p>C</p>
                    </td>
                    <td>
                      <p>The period of below 12 months</p>
                    </td>
                  </tr>

                  <tr>
                    <td width="200">
                      <p>Sub Adult</p>
                    </td>
                    <td width="200">
                      <p>SA</p>
                    </td>
                    <td>
                      <p>
                        The period between 12 to 30 months after observing as a
                        Cub
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td width="200">
                      <p>Early adult</p>
                    </td>
                    <td width="200">
                      <p>EA</p>
                    </td>
                    <td>
                      <p>
                        The period between 31 months and 45 Months after
                        observing as a Cub or if the estimated age is less than
                        4 years.{" "}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td width="200">
                      <p>Adult</p>
                    </td>
                    <td width="200">
                      <p>A</p>
                    </td>
                    <td>
                      <p>An estimated age between 4 years and 8 years</p>
                    </td>
                  </tr>
                  <tr>
                    <td width="200">
                      <p>Aged adult</p>
                    </td>
                    <td width="200">
                      <p>AA</p>
                    </td>
                    <td>
                      <p>An estimated age >8 year</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <h3 className="title"> Features </h3>
          <Row>
            <Col md="4">
              <Card>
                <CardBody className="home-navigation-panel-wrapper">
                  <i className="now-ui-icons ui-1_zoom-bold home-navigation-panel-icons"></i>
                  <h4 className="title">Identified Leopards</h4>
                  <p>
                    List of identified leopards in Yala block 01 and block 05.
                  </p>
                  <Link to="/search-identified-leopards">
                    <Button
                      className="btn-round"
                      color="info"
                      type="button"
                      //onClick={(e) => viewSearchResultRecord(result)}
                    >
                      View &nbsp;{" "}
                      <i className="fab fas fa-arrow-circle-right"></i>
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody className="home-navigation-panel-wrapper">
                  <i className="now-ui-icons location_map-big home-navigation-panel-icons"></i>
                  <h4 className="title">Home Ranges Of Leopards</h4>
                  <p>Showcases identified home ranges of Yala leopards.</p>
                  <Link to="/leopard-homeranges">
                    <Button
                      className="btn-round"
                      color="info"
                      type="button"
                      //onClick={(e) => viewSearchResultRecord(result)}
                    >
                      View &nbsp;{" "}
                      <i className="fab fas fa-arrow-circle-right"></i>
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody className="home-navigation-panel-wrapper">
                  <i className="now-ui-icons business_bulb-63 home-navigation-panel-icons"></i>
                  <h4 className="title">Identification Methods</h4>
                  <p>
                    How to distinctly identify the leopards by rosette patterns.
                  </p>
                  <Link to="/about-leopard-identification-method">
                    <Button
                      className="btn-round"
                      color="info"
                      type="button"
                      //onClick={(e) => viewSearchResultRecord(result)}
                    >
                      View &nbsp;{" "}
                      <i className="fab fas fa-arrow-circle-right"></i>
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody className="home-navigation-panel-wrapper">
                  <i className="now-ui-icons media-1_album home-navigation-panel-icons"></i>
                  <h4 className="title">Distinct Observations</h4>
                  <p>
                    Distinct observations done regarding leopard behaviour in
                    the wild.
                  </p>
                  <Link to="/about-leopard-behaviours">
                    <Button
                      className="btn-round"
                      color="info"
                      type="button"
                      //onClick={(e) => viewSearchResultRecord(result)}
                    >
                      View &nbsp;{" "}
                      <i className="fab fas fa-arrow-circle-right"></i>
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody className="home-navigation-panel-wrapper">
                  <i className="now-ui-icons location_pin home-navigation-panel-icons"></i>
                  <h4 className="title">About Yala</h4>
                  <p>
                    Detailed information on Yala (යාල) National Park's history,
                    flora, fauna and more..
                  </p>
                  <Link to="/about-yala">
                    <Button
                      className="btn-round"
                      color="info"
                      type="button"
                      //onClick={(e) => viewSearchResultRecord(result)}
                    >
                      View &nbsp;{" "}
                      <i className="fab fas fa-arrow-circle-right"></i>
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default YalaLeopardsHome;
