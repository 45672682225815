import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

function AbouDWCParks_MaduruOya() {
  return (
    <>
      <Row>
        <Col>
          <h3 className="title">Maduru Oya National Park</h3>
          <img
            src={require("assets/img/aboutDWC/parks/MaduruOya.png")}
            className="dwc-parks-image"
            alt="Maduru Oya National Park"
          ></img>
          <h4>Introduction</h4>
          <p>
            The new park is designed to protect the immediate catchments of five
            reservoirs developed under the Accelerated Mahaweli Development
            Programme. The park area provided refuge for wildlife. The park lies
            between the Poloinnaruwa-Batticalo road and
            Mahiyangana-Padiyathalawa road in the districts of Ampara Buddulla
            and Polonnaruwa and spans the border between Eastern and Uva
            provinces.
          </p>
          <h4>ACCESS</h4>
          <p>
            <ul>
              <li>
                The easiest and most practical route from Colombo is via
                Kurunegala, Dambulla, Habarana, Polonnaruwa and Manamitiya. It
                is approximately 265km from Colombo.
              </li>
            </ul>
          </p>
          <h4>PHYSICAL CHARACTERRISTICS</h4>
          <p>
            Degradation of the pre-Cambrain rock has resulted in the formation
            of a mature mantle and undulating peneplain, broken by a number of
            prominent remnants of erosion (rock outcrops and ridges). The
            topographic feature is the 8km long range of Rocky Mountains in the
            south west of the park. The area of park is 58,7850ha. The park was
            extended in the east from its original size of 51,468ha on 16 th
            September 1985.in order to provided additional habitat for wildlife
            and to ensure protection of the immediate catchments of the NDK
            reservoir. Condition are influenced largely by the north-east
            monsoon, or Maha which lasts from October to late January. Mean
            annual rainfall is 1,650mm. Annual evapotranspiration rates normally
            exceed precipitations levels and mean annual temperature is about
            27°C.
          </p>
          <h4>VEGITATION</h4>
          <p>
            The park is located entirely within Sri Lanka’s dry zone, although
            its southern edge borders on the intermediate zone. The climax
            community of the area is tropical dru ,oxed evergreen forest. The
            rare and endemic tree vatica obscura, the only species of the
            Dipterocapaceae to occur in the dry zone, is found in restricted
            location on the banks of the Madury Oya and Gallodai Aru.
          </p>
          <h4>FAUNA</h4>
          <p>
            The park is an important for its rich wildlife which includes a
            variety of endemic species. Threatened species of mammals, reptiles
            and fishes are common in the park.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default AbouDWCParks_MaduruOya;
