import React from "react";

// reactstrap components
import {
  Button,
  NavItem,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
  Row,
  Col,
  FormGroup,
} from "reactstrap";

// other components
import Select from "react-select";
import PulseLoader from "react-spinners/PulseLoader";
import LoadingOverlay from "react-loading-overlay";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import config from "../../config.json";
import { NationalParkList } from "../../variables/AllNationalParks.js";
import { react_select_styles } from "../../utils/React-Select-Styling.js";

function AdvancedSearchBar(props) {
  //console.log("AdvancedSearchBar Props : " + JSON.stringify(props));

  const [allTerritoryLocations, setAllTerritoryLocations] = React.useState([]);
  const [rightFocus, setRightFocus] = React.useState(false);
  const [filterZone, setFilterZone] = React.useState("");
  const [filterGender, setFilterGender] = React.useState("");
  const [searchQuery, setSearchQuery] = React.useState("");
  // const [searchResults, setSearchResults] = React.useState([]);
  const [showLoadingView, setShowLoadingView] = React.useState(false);
  const [park, setPark] = React.useState("1");
  const [selectedAreaValue, setSelectedAreaValue] = React.useState(null);


  const national_parks_list = NationalParkList;

  function getAllLocations(parkid) {
    fetch(config.APP_URLS.BACKEND_SERVER_API + "/web/get-locations-in-np", {
      method: "POST",
      body: JSON.stringify({ national_park: parkid }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        // If no results found, show toast and exit
        if (json["matchResults"].length == 0) {
          showAlert("Unable to fetch location information from database");
          return;
        }

        //console.log("Got locationlist")
        var allLocs = json["matchResults"];
        //console.log("Which are; ")
        //console.log(JSON.stringify(allLocs))
        var locsUpdated = [{ value: "", label: "Combined" }];

        allLocs.forEach(function (loc) {
          //console.log("LOC : " + loc.location_name)
          locsUpdated.push({
            label: loc.location_name,
            value: loc.location_id,
          });
        });

        //console.log("LOCS UPDATED : " + JSON.stringify(locsUpdated));

        setAllTerritoryLocations(locsUpdated);
      })
      .catch((err) => {
        //console.log("Error captured!!! : " + err);

        showAlert(
          "Unable to fetch all seen area locations. Error: " + err.message
        );
      });
  }

  function showAlert(message) {
    // console.log("Showing Alert : " + message);
    toast.error(message, { containerId: "LocationError" });
  }

  function searchForLeopard() {
    if (searchQuery != null && searchQuery != "") {
      // console.log(
      //   "Calling the getSearchResultsFRomSearchQuery with data : " + searchQuery
      // );
      getSearchResultsFRomSearchQuery();
    } else {
      callSearchEndpoint();
    }
  }

  /**
   * Method for searching for results using the quick search query
   *
   */
  function getSearchResultsFRomSearchQuery() {
    if (searchQuery == null || searchQuery == "") {
      //console.log("Search Query is Empty. Abort! Abort!");
      return;
    }

    var json = {
      searchquery: searchQuery,
      park_id: park,
    };

    setShowLoadingView(true);

    fetch(
      config.APP_URLS.BACKEND_SERVER_API + "/web/list-leopards-for-searchquery",
      {
        method: "POST",
        body: JSON.stringify(json),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        // If no results found, show toast and exit
        if (json["matchResults"].length == 0) {
          setShowLoadingView(false);

          showAlert(
            <div>
              {" "}
              Unable to find a leopard with name or nickname '{
                searchQuery
              }'{" "}
            </div>
          );
          return;
        }

        // setSearchResults(json["matchResults"])
        setShowLoadingView(false);

        // console.log("Search results : ");
        // console.log(searchResults);

        props.parentCallback({ "results": json["matchResults"], "parkid": park });
      })
      .catch((err) => {
        //console.log("Error captured!!! : " + err);
        setShowLoadingView(false);
        showAlert(
          <div>Unable to search for leopards. Error: '{err.message}' </div>
        );
      });
  }

  /**
   * Calls the manual search endpoint with the filter arguments.
   *
   * Invoked by the search button click.
   */
  function callSearchEndpoint() {
    //console.log("In the callSearchEndpoint method");
    // Show loading screen

    // setSearchResults([]);
    setShowLoadingView(true);

    // console.log(
    //   "callSearchEndpoint state : " + JSON.stringify(showLoadingView)
    // );

    var json = {
      filters: {},
    };

    if (park != null && park != "") {
      json["filters"]["parkid"] = park;
    }

    if (filterZone != null && filterZone != "") {
      json["filters"]["location_id"] = filterZone;
    }

    if (filterGender != null && filterGender != "") {
      json["filters"]["gender"] = filterGender;
    }

    fetch(
      config.APP_URLS.BACKEND_SERVER_API + "/web/list-leopards-for-filter",
      {
        method: "POST",
        body: JSON.stringify(json),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        // If no results found, show toast and exit
        if (json["matchResults"].length == 0) {
          setShowLoadingView(false);
          props.parentCallback({ "results": [], "parkid": park });
          return;
        }

        // console.log(
        //   "Hot result received : " + JSON.stringify(json["matchResults"])
        // );

        // setSearchResults(searchResults => ([...searchResults, ...json["matchResults"]]));
        setShowLoadingView(false);

        // console.log(
        //   "search results received : " + JSON.stringify(json["matchResults"])
        // );

        props.parentCallback({ "results": json["matchResults"], "parkid": park });
      })
      .catch((err) => {
        // console.log("Error captured!!! : " + err);

        showAlert("Unable to fetch search results. Error: " + err.message);
        setShowLoadingView(false);
      });
  }

  function changeSearchQuery(event) {
    setFilterGender(null);
    setFilterGender(null);

    //console.log("Changing the value of 'searchQuery' to " + event.target.value);

    setSearchQuery(event.target.value);
  }

  function showLoading() {
    return (
      <LoadingOverlay
        active={showLoadingView}
        spinner={<PulseLoader />}
        text="Loading"
      ></LoadingOverlay>
    );
  }

  function getLocationsForNP(selectedNP) {
    // console.log(">>>>>>>>> SELECTED NP : " + JSON.stringify( selectedNP))
    setSelectedAreaValue(null);
    setFilterZone(null);
    setPark(selectedNP);
    getAllLocations(selectedNP);
  }

  function changeSeenArea(e) {
    setSelectedAreaValue(e);
    setFilterZone(e.value);
  }

  // Passing [] will make sure the function is called only once after the rendering..
  // https://stackoverflow.com/questions/53120972/how-to-call-loading-function-with-react-useeffect-only-once

  React.useEffect(() => {
    getAllLocations("1");
  }, []);

  return (
    <>
      <ToastContainer
        enableMultiContainer
        containerId={"LocationError"}
        position={toast.POSITION.TOP_RIGHT}
      />
      <ToastContainer
        enableMultiContainer
        containerId={"SearchError"}
        position={toast.POSITION.TOP_RIGHT}
      />

      {showLoadingView && showLoading()}

      <FormGroup>
        <Row>
          <Col md="12" lg="12" sm="12">
            <InputGroup className={rightFocus ? "input-group-focus" : ""}>
              <Input
                placeholder="Search By Nick Name or Official Codename"
                type="text"
                autoFocus
                onFocus={() => setRightFocus(true)}
                onBlur={() => setRightFocus(false)}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    searchForLeopard();
                  }
                }}
                onChange={(e) => changeSearchQuery(e)}
              ></Input>
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i className="now-ui-icons ui-1_zoom-bold"></i>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md="4" lg="4" sm="6">
            <FormGroup>
              <label>National Park</label>
              <Select
                //Value={park}
                defaultValue={national_parks_list[0]}
                name="zone"
                onChange={(e) => getLocationsForNP(e.value)}
                className="searchable-dropdown basic-single"
                classNamePrefix="select"
                isSearchable={false}
                options={national_parks_list}
                // rounded corners to match others
                styles={react_select_styles}
              />
            </FormGroup>
          </Col>
          <Col md="4" lg="4" sm="6">
            <FormGroup>
              <label>Seen Area</label>
              <Select
                value={selectedAreaValue}
                defaultValue={allTerritoryLocations[0]}
                onChange={(e) => changeSeenArea(e)}
                className="searchable-dropdown basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="seen-area"
                options={allTerritoryLocations}
                // rounded corners to match others
                styles={react_select_styles}
                isDisabled={
                  searchQuery != null && searchQuery != ""
                    ? true
                    : false
                }
              />
            </FormGroup>
          </Col>

          <Col md="4" lg="4" sm="6">
            <FormGroup>
              <label>Gender</label>
              <Select
                Value={filterGender}
                name="filterGender"
                onChange={(e) => setFilterGender(e.value)}
                className="searchable-dropdown basic-single"
                classNamePrefix="select"
                isSearchable={true}
                options={[
                  { value: "", label: "Combined" },
                  { value: "male", label: "Male" },
                  { value: "female", label: "Female" },
                ]}
                // rounded corners to match others
                styles={react_select_styles}
                isDisabled={
                  searchQuery != null && searchQuery != ""
                    ? true
                    : false
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button
              className="btn-round"
              color="info"
              type="button"
              size="lg"
              onClick={() => searchForLeopard()}
            >
              Search
            </Button>
          </Col>
        </Row>
      </FormGroup>
    </>
  );
}

export default AdvancedSearchBar;
