import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Nav,
  NavItem,
  Button,
  Card,
  CardHeader,
  NavLink,
  CardBody,
  TabContent,
  TabPane,
} from "reactstrap";

import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { useWizard } from "react-use-wizard";
import { ToastContainer, toast } from "react-toastify";

import config from "../../config.json";
import WizardFooter from "components/detect-leopard-components/WizardFooter";

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { CheckImageUploadedFromDevice } from "utils/CheckImageUploadedFromDevice";

function UploadTheImage(props) {
  var QRCode = require("qrcode.react");

  const [pills, setPills] = React.useState("1");

  const { previousStep } = useWizard();

  const getUploadParams = () => ({
    url:
      config.APP_URLS.BACKEND_SERVER_API +
      "/web/upload-to-identify/" +
      props.uuid,
  });

  const [isNextActionAllowed, setIsNextActionAllowed] = React.useState(null);
  const [isUploadDisabled, setIsUploadDisabled] = React.useState(false);
  const [uploadText, setUploadText] = React.useState(
    "Browse Files from Device"
  );

  const [isUploadFromQR, setIsUploadFromQR] = React.useState(false);
  const [isUploadFromDisk, setIsUploadFromDisk] = React.useState(true);

  const [timerDuration, setTimerDuration] = React.useState(300);

  const handleChangeStatus = ({ meta, remove }, status) => {
    if (status === "headers_received" || status === "done") {
      setIsUploadDisabled(true);
      setIsNextActionAllowed(true);
      setUploadText("Upload Completed Successfully! Click Next to Proceed");
      remove();
    }
  };

  function showAlert(message) {
    //("Showing Alert : " + JSON.stringify(message));
    toast.error(message, { containerId: "UploadError" });
  }

  function waitForImageToBeUploaded() {
    CheckImageUploadedFromDevice(props.uuid).then((response) => {
      console.log(response);
      if (response.status === "success") {
        setIsUploadDisabled(true);
        setIsNextActionAllowed(true);
      } else {
        showAlert(response.status);
        new Promise((resolve) => setTimeout(resolve, 3000)).then(() => {
          previousStep();
        });
      }
    });
  }

  function startQRUpload() {
    waitForImageToBeUploaded();
  }

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      previousStep();
      return <div className="timer">Too late...</div>;
    }

    return (
      <div className="timer">
        <div className="text">Remaining</div>
        <div className="value">{remainingTime}</div>
        <div className="text">seconds</div>
      </div>
    );
  };

  React.useEffect(() => {
    if (props.source == "leopardcenter") {
      startQRUpload();
    }
  }, []);

  return (
    <>
      <div className="wrapper">
        <ToastContainer
          enableMultiContainer
          containerId={"UploadError"}
          position={toast.POSITION.TOP_RIGHT}
        />
        <div>
          <h5>Upload Image to Identify Leopard :</h5>
          <Row>
            <Col md="12" id="tab-view-upload-types">
              <div className="text-center">
                {/* <Button onClick={() => uploadFromDisk()}> from Disk</Button>
                <Button onClick={() => uploadFromQR()}> QR Code</Button> */}

                <Col className="ml-auto mr-auto" md="12" xl="12" lg="12">
                  <div>
                    <CardHeader className="tab-header-view">
                      <Nav
                        className=" justify-content-center"
                        // data-background-color="blue"
                        role="tablist"
                        tabs
                      >
                        {/* If coming from center disable disk upload */}
                        {props.source != "leopardcenter" && (
                          <>
                            <NavItem>
                              <NavLink
                                className={pills === "1" ? "active" : ""}
                                href="#pablo"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setPills("1");
                                }}
                              >
                                {"Upload Image from the Device "}
                                <i className="fas fa-upload"></i>
                              </NavLink>
                            </NavItem>
                            <div className="tab-header-OR">
                              <span>{"- OR -"}</span>
                            </div>

                            <NavItem>
                              <NavLink
                                className={pills === "2" ? "active" : ""}
                                href="#pablo"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setPills("2");
                                  startQRUpload();
                                }}
                              >
                                {"Scan QR Code to Upload the Image "}
                                <i className="fas fa-qrcode"></i>
                              </NavLink>
                            </NavItem>
                          </>
                        )}

                        {props.source == "leopardcenter" && (
                          <NavItem>
                            <NavLink
                              className="active"
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                setPills("2");
                                startQRUpload();
                              }}
                            >
                              {"Scan QR Code to Upload the Image "}
                              <i className="fas fa-qrcode"></i>
                            </NavLink>
                          </NavItem>
                        )}
                      </Nav>
                    </CardHeader>
                    <CardBody>
                      <TabContent
                        className="text-center"
                        activeTab={
                          props.source != "leopardcenter"
                            ? "pills" + pills
                            : "pills2"
                        }
                      >
                        <TabPane tabId="pills1">
                          <Dropzone
                            disabled={isUploadDisabled}
                            getUploadParams={getUploadParams}
                            multiple={false}
                            maxFiles={1}
                            accept="image/*"
                            inputContent={uploadText}
                            onChangeStatus={handleChangeStatus}
                            maxSizeBytes={10485760}
                          />
                        </TabPane>
                        <TabPane tabId="pills2">
                          <>
                            <p>
                              Scan the below QR code from your mobile device to
                              upload the image directly.
                            </p>
                            <Row>
                              <Col md="3">
                                <Card>
                                  <QRCode
                                    value={
                                      config.APP_URLS.WEBSERVER_URL +
                                      "/device-upload-leopard/" +
                                      props.uuid
                                    }
                                    size={300}
                                    renderAs="svg"
                                  />
                                  {/* <a
                                    href={
                                      config.APP_URLS.WEBSERVER_URL +
                                      "/device-upload-leopard/" +
                                      props.uuid
                                    }
                                    target="_blank"
                                  >
                                    Device Upload
                                  </a> */}
                                </Card>
                              </Col>
                              <Col md="2"></Col>
                              <Col md="7">
                                {!isNextActionAllowed && (
                                  <>
                                    <Row>
                                      <Col md="12">
                                        <p>
                                          Waiting for your image to be uploaded.{" "}
                                        </p>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="12">
                                        <div className="timer-wrapper">
                                          <CountdownCircleTimer
                                            isPlaying
                                            duration={timerDuration}
                                            colors={[
                                              ["#004777", 0.33],
                                              ["#F7B801", 0.33],
                                              ["#A30000"],
                                            ]}
                                            onComplete={() => [false, 1000]}
                                          >
                                            {renderTime}
                                          </CountdownCircleTimer>
                                        </div>
                                      </Col>
                                    </Row>
                                  </>
                                )}

                                {isNextActionAllowed && (
                                  <div className="upload-success-message">
                                    <h5>
                                      {" "}
                                      <i
                                        className="fa fa-check-circle fa-5x"
                                        aria-hidden="true"
                                      ></i>
                                      &nbsp;&nbsp;
                                      {
                                        "Image uploaded the image successfully! "
                                      }
                                      <br />
                                      {"Click Next to proceed."}
                                    </h5>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <br />
                          </>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
          {/* 
          {isUploadFromDisk && (
            <>
              <p>Upload the image from your disk</p>
              <Dropzone
                disabled={isUploadDisabled}
                getUploadParams={getUploadParams}
                multiple={false}
                maxFiles={1}
                accept="image/*"
                inputContent={uploadText}
                onChangeStatus={handleChangeStatus}
                maxSizeBytes={10485760}
              />
            </>
          )} */}

          {/* {isUploadFromQR && (
            <>
              <p>
                Scan the below QR code from your mobile device to upload the
                image directly.
              </p>
              <Row>
                <Col md="3">
                  <Card>
                    <QRCode
                      value={
                        config.APP_URLS.WEBSERVER_URL +
                        "/device-upload-leopard/" +
                        props.uuid
                      }
                      size={300}
                      renderAs="svg"
                    />
                    <a
                      href={
                        config.APP_URLS.WEBSERVER_URL +
                        "/device-upload-leopard/" +
                        props.uuid
                      }
                      target="_blank"
                    >
                      Device Upload
                    </a>
                  </Card>
                </Col>
                <Col md="2"></Col>
                <Col md="7">
                  {!isNextActionAllowed && (
                    <>
                      <Row>
                        <h5>Waiting for your image to be uploaded. </h5>
                      </Row>
                      <Row>
                        <div className="timer-wrapper">
                          <CountdownCircleTimer
                            isPlaying
                            duration={300}
                            colors={[
                              ["#004777", 0.33],
                              ["#F7B801", 0.33],
                              ["#A30000"],
                            ]}
                            onComplete={() => [false, 1000]}
                          >
                            {renderTime}
                          </CountdownCircleTimer>
                        </div>
                      </Row>
                    </>
                  )}

                  {isNextActionAllowed && (
                    <h5>Uploaded the image successfully!</h5>
                  )}
                </Col>
              </Row>
              <br />
            </>
          )} */}
        </div>

        <WizardFooter
          isNextAllowed={isNextActionAllowed}
          nextButtonText={"Next "}
        ></WizardFooter>
      </div>
    </>
  );
}

export default UploadTheImage;
