export const DocumentList = [
  {
    DocumentType: "VIDEO",
    Path: "Sibling Behaviour [STICHED][REDUCED].m4v",
    Name: "Leopard Siblings",
  },
  {
    DocumentType: "VIDEO",
    Path: "Leopard Family Eating Pray [REDUCED].m4v",
    Name: "Leopard Family Eating Pray",
  },
  {
    DocumentType: "VIDEO",
    Path: "Leopard Hunt [REDUCED].m4v",
    Name: "Young Leopard Hunting a Wild Boar",
  },
  {
    DocumentType: "VIDEO",
    Path: "Mother calling cubs [REDUCED].m4v",
    Name: "Leopard Mom Calling Her Cubs",
  }
];
