export const VideoList = [
  {
    DocumentType: "VIDEO",
    EmbedURL: "https://www.youtube.com/embed/9A4qxzpX2rI",
    Name: "Farewell Sage (YF24)",
  },
  {
    DocumentType: "VIDEO",
    EmbedURL: "https://www.youtube.com/embed/c16Mj1YhMXU",
    Name: "Playful Lance (YM-17) and Lucas (YM-16)",
  }
];
