import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

function AbouDWCParks_Wilpattu() {
  return (
    <>
      <Row>
        <Col>
          <h3 className="title">Wilpattu National Park</h3>
          <img
            src={require("assets/img/aboutDWC/parks/Wilpattu.png")}
            className="dwc-parks-image"
            alt="Wilpattu National Park"
          ></img>
          <h4>Introduction</h4>
          <p>
            Wilpttu National park is among the oldest and most important of
            protected areas in Sri Lanka. It also contains a number of important
            cultural sites. The sanctuary lies inland from the coast and is
            entirely within Northern Province. It is contiguous with the park,
            the intervening boundary being marked by the Moderagam Aru.
          </p>
          <h4>ACCESS</h4>
          <p>
            <ul>
              <li>
                The only practical access from the Colombo would be via Negombo,
                Chilaw and Puttalam. The turn off is at the little hamlet of
                Thimbiriwewa on the 28 th mile post along the Puttalam
                Anuradhapura road and the park office where one obtains permit
                and a guide is at Hunuwilagama 8km from the turn off.
              </li>
            </ul>
          </p>
          <h4>PHYSICAL CHARACTERRISTICS</h4>
          <p>
            The main topographical feature in this park is the concentration of
            “villus” or “lakes” within it. The striking feature, though confined
            to certain sector of the park, is copper red, loamy soils extremely
            varied. The western sector of the park with deeply forested areas
            and thorny bushes in reminiscent of Yala National Park in the
            southern Sri Lanka. Characterized by inter monsoon rains in March
            and April an extensive drought from May until early September and a
            major rainy season (Northern Monsoon) from September until December.
            Mean annual temperature is 27.2 ° C and total annual precipitations
            approximately 1000mm based on long term records.
          </p>
          <h4>VEGITATION</h4>
          <p>
            Three types of vegetation can be distinguished : Littoral vegetation
            including salt grass and low scrub immediately adjacent to the
            beach, a 5-10km coastal belt of monsoon scrub of very low stature;
            and further inland, monsoon forest with tall emergent.
          </p>
          <h4>FAUNA</h4>
          <p>
            Mammalian diversity and ecological densities are highest in such
            ecotomes as the interfaces between forest, scrub and grasslands
            converge in the west and the “villus” and drainage systems in the
            center of the park. A total of 31 species of mammals have been
            recorded but additional species of Rodentia and Chiropotera
            unbodoubtedly present. Among the most conspicuous reptiles are
            common.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default AbouDWCParks_Wilpattu;
