import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

function AbouDWCParks_Lunugamvehera() {
  return (
    <>
      <Row>
        <Col>
          <h3 className="title">Lunugamvehera National Park</h3>
          <img
            src={require("assets/img/aboutDWC/parks/Lunugamvehera.png")}
            className="dwc-parks-image"
            alt="Lunugamvehera National Park"
          ></img>
          <h4>Introduction</h4>
          <p>
            The national park is falls within the divisional secretaries (DS)
            divisions of Thanamalwila, Wellawaya, Kataragama and Buttala im
            Moneragla district and DS divisions of lunugamvenarain Hambantota
            District.
          </p>
          <h4>ACCESS</h4>
          <p>
            <ul>
              <li>
                Via Rathnapura, Uda walawe, Thanamalwila to Lunugamvehera
                (approximately 231km)
              </li>
              <li>
                Via Hambantota, tissamaharama, thanamalwila to Lunugamvehera
                (approximately 265km)
              </li>
            </ul>
          </p>
          <h4>PHYSICAL CHARACTERRISTICS</h4>
          <p>
            Lying in the dry zone, conditions is characterized n y an annual
            drought coinciding with the south-west monsoon. The physiographic of
            the park is characterized by vest featureless gently undulation
            plain with rock knob plain generally occupying the slightly elevated
            areas in south. The total extent of park is 23,498ha. A significant
            area of the park is occupied by the Lunugamvehera reservoir. Thus
            the total dry area of the park is 20,156.8ha. the average annual
            rainfall at Thanamalwila is about 1000mm and the rainfall in the
            park area decreases from north to south and west to east. The annual
            average temperature is about 30ºC.
          </p>
          <h4>VEGITATION</h4>
          <p>
            The vegetation includes different stages of forest succession along
            with scrub, grasslands mosaics. The shifting cultivaton has caused
            degradation of the forest communities to open thorny shrub and
            grassland communities.
          </p>
          <h4>FAUNA</h4>
          <p>
            Twenty one (21) species, 12 amphibians, 33 reptiles, 184 birds and
            43 mammals were recorded.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default AbouDWCParks_Lunugamvehera;
