import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
//import IndexNavbar from "components/Navbars/IndexNavbar.js";
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";

import config from "../../config.json";

function AboutLeopardDiaryTeam(props) {
  function gotoFacebook(e) {
    if (queryParams.get("source") == "leopardcenter") {
      e.preventDefault();
    }
  }

  const queryParams = new URLSearchParams(props.location.search);

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      {SwitchNavBar(queryParams.get("source"))}
      <div className="wrapper">
        <SubPageHeader title="Leopard Diary Team" />

        <Container>
          <h3 className="title">Team Leopard Diary</h3>
          <p>
            "Yala Leopard Diary" is a self-motivated initiative driven by a
            group of wildlife conservationists/photographers who believe in
            studying and understanding the Leopard behavior of Yala Block-01 &
            Yala Block-05. <br />
            The purpose of which is to share the knowledge and experience gained
            so that together the team can build up a comprehensive study that
            comprise of understanding on Leopard behavior with the aim of
            supporting better conservation of Leopard population in Yala
            National Park.
          </p>

          <h3 className="title"> Key Objectives </h3>
          <p>
            <ul className="ul-why-identify">
              <li>To identify leopards of Yala</li>
              <li>To understand leopard behavior of Yala</li>
              <li>To understand home range patterns</li>
              <li>
                To understand the size of leopard population in block 1 & block
                5.
              </li>
              <li>
                Share valid findings with DWC in order to construct long term
                Leopard conservation strategy.
              </li>
              <li>
                Share information with visitors in order for them to watch
                leopards in a meaningful way.
              </li>
            </ul>
          </p>
          <h3 className="title">Members of Yala Leopard Diary Team</h3>

          <Row>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/chathura.dushmantha"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/debugger89.png")}
                ></img>
                <p className="category">Chathura Dushmantha</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/dushrave.7777"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/DushyanthaNew.jpeg")}
                ></img>
                <p className="category">Dushyantha Silva</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/hesh.peiris4"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/HeshanPeiris.png")}
                ></img>
                <p className="category">Heshan Peiris</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/mevan.piyasena"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/mevanPiyasena.jpg")}
                ></img>
                <p className="category">Mevan Piyasena</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/milinda.wattegedara"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/MilindaWattegedara.png")}
                ></img>
                <p className="category">Milinda Wattegedara</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/Mindaka.Mahela.M"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/Mindaka.jpg")}
                ></img>
                <p className="category">Mindaka Mahela</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/pasan.i.senevirathne"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/PasanSenevirathne.jpeg")}
                ></img>
                <p className="category">Pasan Senevirathne</p>
              </a>
            </Col>

            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/PramodSenda/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/pramodsenda.jpeg")}
                ></img>
                <p className="category">Pramod Sendanayake</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/pummy.pre"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/Pumudi.jpg")}
                ></img>
                <p className="category">Pumudi Pemarathne</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/raveendra.siriwardene"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/RaveendraSiriwardana.jpeg")}
                ></img>
                <p className="category">Raveendra Siriwardana</p>
              </a>
            </Col>
            {/* <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/sadeepagunawar"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/SadeepaGunawardana.png")}
                ></img>
                <p className="category">Sadeepa Gunawardana</p>
              </a>
            </Col> */}
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/sahan.gooneratne"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/Sahan.jpg")}
                ></img>
                <p className="category">Sahan Gooneratne</p>
              </a>
            </Col>

            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/sandy00wickz"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/Sandaru.jpg")}
                ></img>
                <p className="category">Sandaru Wickramarathne</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/sudantha.chandrasena"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/Sudantha.jpg")}
                ></img>
                <p className="category">Sudantha Chandrasena</p>
              </a>
            </Col>
            </Row>
            <h3 className="title">Members of Yala Leopard Diary Tier 02 Team </h3>

          <Row>
          <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/amila.rodrigo.94"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/AmilaR.jpg")}
                ></img>
                <p className="category">Amila Rodrigo</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/Ashvitha.W"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/Ashvitha.jpg")}
                ></img>
                <p className="category">Ashvitha Wickrama</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/binathdiyasenar"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/Bindiya.jpg")}
                ></img>
                <p className="category">Bindiya Senarath</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/chamlihesh"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/Chamli.jpg")}
                ></img>
                <p className="category">Chamli Weerasinghe</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/dilshan.ranasinghe.31"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/Dilshan.jpg")}
                ></img>
                <p className="category">Dilshan Ranasinghe</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/gautham.mk.3"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/Gautham.jpg")}
                ></img>
                <p className="category">Gautham Kumar</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/kusal.fonseka"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/KusalFonseka.jpeg")}
                ></img>
                <p className="category">Kusal Fonseka</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/rajeev.abeysekara.1"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/RajeevAbeysekara.png")}
                ></img>
                <p className="category">Rajeev Abeysekara</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/rasula.bharatha"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/Rasula.jpg")}
                ></img>
                <p className="category">Rasula Madawanarachchi</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/Dravisara.jayamanna"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/Ravisara.jpeg")}
                ></img>
                <p className="category">Ravisara Jayamanna</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/senuri.premaratna"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/Senuri.jpeg")}
                ></img>
                <p className="category">Senuri Premaratna</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/tharindu.sendanayake"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/TharinduSenda.jpeg")}
                ></img>
                <p className="category">Tharindu Sendanayake</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/thilina.jayarathna.923"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/Thilina.JPG")}
                ></img>
                <p className="category">Thilina Chathuranga</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/thivanka.anthony"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/Thivanka.jpg")}
                ></img>
                <p className="category">Thivanka Anthony</p>
              </a>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <a
                href="https://www.facebook.com/Viraj2310"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => gotoFacebook(e)}
              >
                <img
                  alt="..."
                  className="tree-img"
                  src={require("assets/img/team/VirajW.jpeg")}
                ></img>
                <p className="category">Viraj Weerasooriya</p>
              </a>
            </Col>
          </Row>

          <h3 className="title">Copyright &copy; Information</h3>

          <h5>Copyright &copy; {new Date().getFullYear()}</h5>
          <p>Yala Leopard Diary Team </p>
          <p>
            All Rights Reserved. No part of this website may be Reproduced in
            any form, by any means without prior written permission from the
            copyright holders.
          </p>
          <br />
          <p>
            Milinda Wattegedara,
            <br />
            416/i, Jayagath Mawatha, Hokandara, Sri Lanka.
            <br />
            Email — milindawattegedara@gmail.com
          </p>
          <br />
          <p>
            <h5 className="title">Copyright &copy; Photographs</h5>
            The copyrights of all the photographs displayed here are owned by the Yala Leopard Diary and the original Photographer. Unauthorised reproduction, distribution, or use of these images is prohibited without the explicit permission of the original photographer or Yala Leopard Diary Team.
          </p>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default AboutLeopardDiaryTeam;
