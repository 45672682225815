/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
            <li>
              <a href="#">Team Leopard Diary</a>
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, Designed and Coded by : {" "}
          <a
            href="https://www.facebook.com/chathura.dushmantha"
            target="_blank"
          >
            chathura.dushmantha
          </a>
          <a href="https://www.facebook.com/PramodSenda" target="_blank">
            , pramod.senda
          </a>
          .
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
