import React from "react";

// reactstrap components
import {
  
  Row,
  
  Col,
} from "reactstrap";

function AbouDWCParks_Bundala() {
  return (
    <>
      <Row>
        <Col>
          <h3 className="title">Horagolla National Park</h3>
          <img
            src={require("assets/img/aboutDWC/parks/Horagolla.png")}
            className="dwc-parks-image"
            alt="Horagolla National Park"
          ></img>
          <h4>Introduction</h4>
          <p>
            Haragolla is biologically, the most diverse and significant patch of
            forest in the wet zone of Sri Lanka. In addition, this park
            represents a special zonal vegetation complex which provides a
            natural refugium for many species that are extremely rare, possibly
            extinct, in other parts of the island. This park is situated
            approximately west of the Colombo- kandy road near Nittambuwa town
            in the western province of Sri Lanka. The ectent of the part is
            about 33 acres. 7° 08’ - 7º 09’N 80º 04’ –80º 06’E
          </p>
          <h4>ACCESS</h4>
          <p>
            <ul>
              <li>
                Turn at Nittambuwa on the Nittanbuwa, Veyangoda road and proceed
                via Pinnagolla up to Horagolla. The distance from Colombo to
                Horagolla is 42km.
              </li>
            </ul>
          </p>
        </Col>
      </Row>
    </>
  );
}

export default AbouDWCParks_Bundala;
