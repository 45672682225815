/*

=========================================================
* Now UI Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/master/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// offline styles
import "assets/css/fontawesome-free-5.13.0-web/css/all.css";
import "assets/css/leaflet-1.6.0-dist/leaflet.css";
import "assets/css/montserrat-google-font.css";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/nucleo-icons-page-styles.css";
// pages for this kit
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "views/examples/LandingPage.js";
import ProfilePage from "views/examples/ProfilePage.js";

import AboutLeopardDiaryTeam from "views/about-leopard-diary-team/AboutLeopardDiaryTeam.js";
import AboutSrilankanLeopard from "views/about-leopards/AboutSrilankanLeopard.js";
import SearchIdentifiedLeopard from "views/identify-leopards/SearchIdentifiedLeopard";
import LeopardSearchResult from "views/identify-leopards/LeopardSearchResult";
import LeopardTerritories from "views/leopard-territories/LeopardTerritories";
import AboutYala from "views/about-yala/AboutYala";
import DemarcationOfZones from "views/about-zones/DemarcationOfZones";
import AboutLeopardBehaviours from "views/about-leopard-behaviours/AboutLeopardBehaviours";
import AboutPublishedArticles from "views/about-published-articles/AboutPublishedArticles";
import AboutLeopardIdentificationMethod from "views/about-leopard-identification-method/AboutLeopardIdentificationMethod";
import AbouDWC from "views/about-dwc/AbouDWC";
import AbouDWCParks from "views/about-dwc/AbouDWCParks";
import UploadLeopardImage from "views/detect-leopards/UploadLeopardImage";
import ProjectYalaLeopardCenter from "views/our-projects/projects/ProjectYalaLeopardCenter";
import ProjectLeopardIdentification from "views/our-projects/projects/ProjectLeopardIdentification";
import ProjectChildernAwareness from "views/our-projects/projects/ProjectChildernAwarenes";
import ProjectOutReach from "views/our-projects/projects/ProjectOutReach";
import AboutWorldLeopards from "views/about-leopards/AboutWorldLeopards";
import TestimonialsPage from "views/testimonials/TestimonialsPage";
// import AdminOperations from "views/admin-operations/AdminOperations";
import ManualDetectLeopardsFilterAndUpload from "views/detect-leopards/ManualDetectLeopardsFilterAndUpload";
import ManualLeopardMatchResults from "views/detect-leopards/ManualLeopardMatchResults";
// import AddNewLeopard from "views/admin-operations/AddNewLeopard";
// import UpdateLeopardDiary from "views/admin-operations/UpdateLeopardDiary";
import HillCountryLeopard from "views/our-projects/projects/HillCountryLeopard";
import ProjectsHome from "views/our-projects/ProjectsHome";
import OurResearch from "views/our-projects/projects/OurResearch";
import YalaLeopardsHome from "views/yala-leopards/YalaLeopardsHome";
import LeopardVideos from "views/leopard-videos/LeopardVideos";
import MatchLeopardWizard from "views/match-leopard-wizard/MatchLeopardWizard";
import DeviceUploadTheImage from "views/match-leopard-wizard/DeviceUploadTheImage";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/index" render={(props) => <Index {...props} />} />
        <Route
          path="/nucleo-icons"
          render={(props) => <NucleoIcons {...props} />}
        />
        <Route
          path="/about-srilankan-leopard"
          render={(props) => <AboutSrilankanLeopard {...props} />}
        />
        <Route
          path="/about-leopards-across-world"
          render={(props) => <AboutWorldLeopards {...props} />}
        />
        <Route
          path="/search-identified-leopards"
          render={(props) => <SearchIdentifiedLeopard {...props} />}
        />
        <Route
          path="/identified-leopard-result/:url_leopard_code"
          render={(props) => <LeopardSearchResult {...props} />}
        />
        <Route
          path="/leopard-homeranges"
          render={(props) => <LeopardTerritories {...props} />}
        />
        <Route
          path="/landing-page"
          render={(props) => <LandingPage {...props} />}
        />
        <Route
          path="/profile-page"
          render={(props) => <ProfilePage {...props} />}
        />
        <Route
          path="/about-leopard-diary-team"
          render={(props) => <AboutLeopardDiaryTeam {...props} />}
        />
        <Route
          path="/about-yala"
          render={(props) => <AboutYala {...props} />}
        />
        <Route
          path="/about-zones"
          render={(props) => <DemarcationOfZones {...props} />}
        />
        <Route
          path="/about-leopard-behaviours"
          render={(props) => <AboutLeopardBehaviours {...props} />}
        />
        <Route
          path="/about-published-articles"
          render={(props) => <AboutPublishedArticles {...props} />}
        />
        <Route
          path="/our-identification-convention"
          render={(props) => <AboutLeopardIdentificationMethod {...props} />}
        />
        <Route path="/about-dwc" render={(props) => <AbouDWC {...props} />} />
        <Route
          path="/about-dwc-parks"
          render={(props) => <AbouDWCParks {...props} />}
        />

        <Route
          path="/upload-to-identify-leopards"
          render={(props) => <UploadLeopardImage {...props} />}
        />

        <Route
          path="/projects/home"
          render={(props) => <ProjectsHome {...props} />}
        />

        <Route
          path="/projects/yala-leopard-center"
          render={(props) => <ProjectYalaLeopardCenter {...props} />}
        />

        <Route
          path="/projects/leopard-identification"
          render={(props) => <ProjectLeopardIdentification {...props} />}
        />
        <Route
          path="/projects/childern-awareness"
          render={(props) => <ProjectChildernAwareness {...props} />}
        />
        <Route
          path="/projects/outreach"
          render={(props) => <ProjectOutReach {...props} />}
        />
        <Route
          path="/projects/hill-country-leopards"
          render={(props) => <HillCountryLeopard {...props} />}
        />
        <Route
          path="/testimonials"
          render={(props) => <TestimonialsPage {...props} />}
        />

        <Route
          path="/projects/research"
          render={(props) => <OurResearch {...props} />}
        />

        <Route
          path="/yalaleopards/home"
          render={(props) => <YalaLeopardsHome {...props} />}
        />

        <Route
          path="/match-identify-leopards"
          render={(props) => <MatchLeopardWizard {...props} />}
        />
        <Route
          path="/match-identify-leopard-results"
          render={(props) => <ManualLeopardMatchResults {...props} />}
        />

        <Route
          path="/device-upload-leopard/:uuid"
          render={(props) => <DeviceUploadTheImage {...props} />}
        />

        <Route
          path="/leopard-videos"
          render={(props) => <LeopardVideos {...props} />}
        />

        {/* <Route
          path="/login-page"
          render={(props) => <LoginPage {...props} />}
        />
          <Route
          path="/admin-opertaions/add-new-leopard"
          render={(props) => <AddNewLeopard {...props} />}
        />
         <Route
          path="/admin-opertaions/update-diary"
          render={(props) => <UpdateLeopardDiary {...props} />}
        />  */}

        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
