import config from "../config.json";

export const GetLeopardTerritory = (officialnamecode) =>
  new Promise(function (resolve, reject) {
    fetch(
      config.APP_URLS.BACKEND_SERVER_API + "/web/get-territory-for-leopard",
      {
        method: "POST",
        body: JSON.stringify({ officialnamecode: officialnamecode }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        // If no results found, show toast and exit
        if (json["matchResults"].length == 0 || json["matchResults"][0]["coords"] == undefined) {
          reject(new Error("No home range defined for the leopard yet."));
        } else {
          var territory = json["matchResults"][0]["coords"];
          resolve(territory);
        }
      })
      .catch((err) => {
        reject(
          new Error(
            "Unable to fetch home ranges for leopard. Error:" + err.message
          )
        );
      });
  });
