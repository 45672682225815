import config from "../config.json";

export const GetAllLocationsForPark = (parkid) =>
  new Promise(function (resolve, reject) {
    fetch(config.APP_URLS.BACKEND_SERVER_API + "/web/get-locations-in-np", {
      method: "POST",
      body: JSON.stringify({ national_park: parkid }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        // If no results found, show toast and exit
        if (json["matchResults"].length == 0) {
          reject(
            new Error("Unable to fetch location information from database")
          );
        } else {
          //console.log("Got locationlist")
          var allLocs = json["matchResults"];
          //console.log("Which are; ")
          //console.log(JSON.stringify(allLocs))
          var locsUpdated = [{ value: "", label: "Unknown" }];

          allLocs.forEach(function (loc) {
            //console.log("LOC : " + loc.location_name)
            locsUpdated.push({
              label: loc.location_name,
              value: loc.location_id,
            });
          });

          resolve(locsUpdated);
        }
      })
      .catch((err) => {
        //console.log("Error captured!!! : " + err);
        reject(
          new Error(
            "Unable to fetch all seen area locations. Error:" + err.message
          )
        );
      });
  });
