import config from "../config.json";

export const SearchBySearchQuery = (searchQuery, park) =>
  new Promise(function (resolve, reject) {
    if (searchQuery == null || searchQuery == "") {
      resolve(null);
    }

    var json = {
      searchquery: searchQuery,
      park_id: park,
    };

    // console.log("searching by query : " + JSON.stringify(json))

    fetch(
      config.APP_URLS.BACKEND_SERVER_API + "/web/list-leopards-for-searchquery",
      {
        method: "POST",
        body: JSON.stringify(json),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        // If no results found, show toast and exit
        if (json["matchResults"].length == 0) {
          reject(
            new Error(
              "Unable to find a leopard with name or nickname " + searchQuery
            )
          );
        } else {
          resolve(json["matchResults"]);
        }
      })
      .catch((err) => {
        //console.log("Error captured!!! : " + err);
        reject(
          new Error("Unable to search for leopards. Error:" + err.message)
        );
      });
  });
