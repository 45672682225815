import React from "react";

// reactstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

// core components
//import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import AdvancedSearchBar from "components/search-components/AdvancedSearchBar";
import ReactImageFallback from "react-image-fallback";

import config from "../../config.json";
import { SwitchNavBar } from "../../utils/SwitchNavBar.js";

import ResultsPageHeader from "components/Headers/ResultsPageHeader.js";
//import TouchNavbar from "components/Navbars/TouchNavbar";

import PulseLoader from "react-spinners/PulseLoader";
import LoadingOverlay from "react-loading-overlay";
import { ToastContainer, toast } from "react-toastify";

function SearchIdentifiedLeopard(props) {
  var selectedResultRow = [];

  const [parkid, setParkId] = React.useState("1");
  const [searchResults, setSearchResults] = React.useState([]);
  const [searchResultsReceived, setSearchResultsReceived] = React.useState(
    false
  );
  const [showLoadingView, setShowLoadingView] = React.useState(false);

  const [filterDropdownOpen, setFilterDropdownOpen] = React.useState(false);
  const toggleFilter = () => setFilterDropdownOpen((prevState) => !prevState);

  const searchCallBackFunction = (searchResultsList) => {
    // console.log(
    //   "Received results to searchCallBackFunction function : " +
    //     JSON.stringify(searchResultsList)
    // );
    setSearchResults(searchResultsList.results);
    setParkId(searchResultsList.parkid);
    setSearchResultsReceived(true);
    // console.log(
    //   "Received results to searchCallBackFunction function XXXX : " +
    //     JSON.stringify(searchResults)
    // );
  };

  function viewSearchResultRecord(resultRow) {

    let profileURL = "/identified-leopard-result/" + resultRow.officialnamecode
    if (queryParams.get("source") === "leopardcenter") {
      profileURL = profileURL + "?source=" + queryParams.get("source");
    }

    props.history.push(profileURL);
  }

  function checkEmptyOrUndefinedImage(result) {
    return (
      <ReactImageFallback
        src={require(`assets/img/identified-leopards/YALA_NP/${result.officialnamecode}/thumbnail.jpg`)}
        fallbackImage={require(`assets/img/identified-leopards/default/deatult_leopard_low_size.jpg`)}
        initialImage={require(`assets/img/loading-icon.svg`)}
        alt={result.nickname}
        // className="rounded img-fluid img-raised"
        className="leopard-search-result-thumb"
      />
    );
  }

  /**
   * Get all available leopards. Only for leopard center.
   *
   * Invoked at page load
   */
  function getAllLeopards() {
    // console.log("In the getAllLeopards method");
    // Show loading screen

    // setSearchResults([]);
    setShowLoadingView(true);

    // console.log(
    //   "callSearchEndpoint state : " + JSON.stringify(showLoadingView)
    // );

    function showAlert(message) {
      // console.log("Showing Alert : " + message);
      toast.error(message, { containerId: "LocationError" });
    }

    // load the park id 1 at first page load
    var json = {
      filters: { parkid: "1" },
    };

    fetch(
      config.APP_URLS.BACKEND_SERVER_API + "/web/list-leopards-for-filter",
      {
        method: "POST",
        body: JSON.stringify(json),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        // If no results found, show toast and exit
        if (json["matchResults"].length === 0) {
          setShowLoadingView(false);
          props.parentCallback([]);
          return;
        }

        // console.log(
        //   "Hot result received : " + JSON.stringify(json["matchResults"])
        // );

        // setSearchResults(searchResults => ([...searchResults, ...json["matchResults"]]));
        setShowLoadingView(false);

        // console.log(
        //   "search results received : " + JSON.stringify(json["matchResults"])
        // );

        setSearchResults(json["matchResults"]);
      })
      .catch((err) => {
        //console.log("Error captured!!! : " + err);

        showAlert("Unable to fetch search results. Error: " + err.message);
        setShowLoadingView(false);
      });
  }

  function showLoading() {
    return (
      <LoadingOverlay
        active={showLoadingView}
        spinner={<PulseLoader />}
        text="Loading"
      ></LoadingOverlay>
    );
  }

  function sort_lastseen(a, b) {
    return (
      new Date(b.last_seen_date).getTime() -
      new Date(a.last_seen_date).getTime()
    );
  }

  function sort_az(a, b) {
    return a.officialnamecode < b.officialnamecode ? -1 : 1;
  }

  function sortLeopardsBySeenDate() {
    var sortedLeopards = [...searchResults].sort(sort_lastseen);
    setSearchResults(sortedLeopards);
    //console.log("SORTED LEOPARDS > " + JSON.stringify(sortedLeopards))
  }

  function sortLeopardsByAZ() {
    var sortedLeopards = [...searchResults].sort(sort_az);
    setSearchResults(sortedLeopards);
    // console.log("SORTED LEOPARDS > " + JSON.stringify(sortedLeopards))
  }

  React.useEffect(() => {
    getAllLeopards();
  }, []);

  React.useEffect(() => {
    //console.log("in useEffect function : " + JSON.stringify(selectedResultRow));
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const queryParams = new URLSearchParams(props.location.search);

  return (
    <>
      {SwitchNavBar(queryParams.get("source"))}
      <div className="wrapper">
        <ResultsPageHeader title="Search Identified Leopards" />

        <Container>
          <h4>
            Filter Identified Leopards (from 2013 to {new Date().getFullYear()})
          </h4>

          <AdvancedSearchBar parentCallback={searchCallBackFunction} />
        </Container>

        {showLoadingView && showLoading()}

        <div className="section section-team text-center">
          <Container>
            <div className="team">
              <Row>
                <Col>
                  <UncontrolledDropdown className="float-right">
                    <DropdownToggle caret color="primary" className="btn-round">
                      <i class="fas fa-sort"></i>
                      {"  Sort By"}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => sortLeopardsByAZ()}>
                        <i class="fas fa-sort-alpha-down"></i>A to Z
                      </DropdownItem>
                      <DropdownItem onClick={() => sortLeopardsBySeenDate()}>
                        <i class="fas fa-calendar-day"></i>
                        {"Last Seen Date"}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
              </Row>
              <Row>
                {/* If no results found after searching.. */}
                {searchResults.length === 0 && searchResultsReceived && (
                  <div className="search-no-results-image-container">
                    <table>
                      <tr>
                        <td>
                          <img
                            className="search-no-results-image"
                            alt="No Results Found"
                            src={require("assets/img/search_no_result.png")}
                          ></img>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>No Results Found!</p>
                        </td>
                      </tr>
                    </table>
                  </div>
                )}

                {searchResults.map((result, index) => (
                  <Col lg="3" md="4" sm="6" xs="12" key={"results-" + index}>
                    <Card>
                      <div className="team-player">
                        {checkEmptyOrUndefinedImage(result)}
                        <h5 className="title">
                          {result.officialnamecode}
                        </h5>
                        <h5>
                          {result.nickname}
                        </h5>

                        {result.lt_name && (
                          <label className="label-for-lt-naming">
                            {"["+result.lt_name + "]"}
                          </label>
                        )}

                        {(result.lt_name==null) && (
                          <label className="label-for-lt-naming"><div>&nbsp;</div></label>
                        )}

                        <p>{result.gender}</p>
                        <Button
                          className="btn-round"
                          color="info"
                          type="button"
                          onClick={(e) => viewSearchResultRecord(result)}
                        >
                          View &nbsp;{" "}
                          <i className="fab fas fa-arrow-circle-right"></i>
                        </Button>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Container>
        </div>
      </div>

      <DefaultFooter />
    </>
  );
}

export default SearchIdentifiedLeopard;
