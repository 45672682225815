import React from "react";

// reactstrap components
import {
  Row,
 
  Col,
} from "reactstrap";

function AbouDWCParks_Bundala() {
  return (
    <>
      <Row>
        <Col>
          <h3 className="title">Galways Land National Park</h3>
          <img
            src={require("assets/img/aboutDWC/parks/GalwaysLand.png")}
            className="dwc-parks-image"
            alt="Galways Land National Park"
          ></img>
        </Col>
      </Row>
    </>
  );
}

export default AbouDWCParks_Bundala;
