/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function DefaultFooter() {
  return (
    <>
      <footer className="footer footer-default">
        <Container>
          <nav>
            <ul>
              <li>
                {/* <a href="#">Team Leopard Diary</a> */}
              </li>
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Designed and Coded by : {" "}
            <a
              href="https://www.facebook.com/chathura.dushmantha"
              target="_blank"
            >
              chathura.dushmantha
            </a>
            <a href="https://www.facebook.com/PramodSenda" target="_blank">
              , pramod.senda
            </a>
            .
          </div>
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
