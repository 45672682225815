import config from "../config.json";

export const GetLeopardSeenAreas = (officialnamecode) =>
  new Promise(function (resolve, reject) {
    fetch(
      config.APP_URLS.BACKEND_SERVER_API + "/web/get-seen-areas-for-leopard",
      {
        method: "POST",
        body: JSON.stringify({ officialnamecode: officialnamecode }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        // If no results found, show toast and exit
        if (json["matchResults"].length == 0) {
          reject(new Error("No seen areas found for the leopard in database"));
        } else {
          var seenareas = json["matchResults"];
          resolve(seenareas);
        }
      })
      .catch((err) => {
        reject(
          new Error(
            "Unable to fetch all seen area locations. Error:" + err.message
          )
        );
      });
  });
