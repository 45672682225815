import React, { useRef, useEffect } from "react";


// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

import config from "../../config.json";


function LeopardImageCarousel(props) {
  
  //console.log("Received props to corousal  : " + JSON.stringify(props));

  const ref = useRef();


  //var items = [];
  const [items, setitems] = React.useState([]);

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  function getAvailableImagesForLeopard(targetFolder, officialnamecode) {
    //("Get me files in the folder : " + targetFolder);

    fetch(config.APP_URLS.BACKEND_SERVER_API + "/web/get-images-for-leopard", {
      method: "POST",
      body: JSON.stringify({
        relativepath: targetFolder + "/" + officialnamecode,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        var fullpathslist = [];

        json["imagefiles"].forEach((img) => {
          var imageItem = {};

          // Future reference why the hardcoded path :
          // https://stackoverflow.com/questions/41769385/react-cant-find-module-trying-to-pass-cell-image-path-variable#
          // Fix this
          imageItem.src = require("assets/img/identified-leopards/YALA_NP/" +
            officialnamecode +
            "/" +
            img);
          imageItem.altText = "";
          imageItem.caption = "";

          fullpathslist.push(imageItem);
        });
        setitems(fullpathslist);
        // console.log(
        //   "Constructed images list for carousal : " + JSON.stringify(items)
        // );
      });
  }

  useEffect(() => {
    getAvailableImagesForLeopard(props.imagepath, props.officialnamecode);
  }, []);

  return (
    <>
      <div id="carousel">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12" md="12">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                interval={ false }
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {items.map((item) => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.src}
                      ref={ref}
                    >
                      <img src={item.src} alt={item.altText} />
                      <div className="carousel-caption d-none d-md-block">
                        <h5>{item.caption}</h5>
                      </div>
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <i
                    class="fab fas fa-chevron-circle-left fa-3x"
                    style={{ color: "#fa764b" }}
                  ></i>
                 
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <i
                    class="fab fas fa-chevron-circle-right fa-3x"
                    style={{ color: "#fa764b" }}
                  ></i>
                  
                </a>
              </Carousel>
            </Col>
          </Row>
          
        </Container>
      </div>
    </>
  );
}

export default LeopardImageCarousel;
