import config from "../config.json";

export const SearchByFilters = (park, filterZone, filterGender) =>
  new Promise(function (resolve, reject) {
    var json = {
      filters: {},
    };

    if (park != null && park != "") {
      json["filters"]["parkid"] = park;
    }

    if (filterZone != null && filterZone != "") {
      json["filters"]["location_id"] = filterZone;
    }

    if (filterGender != null && filterGender != "") {
      json["filters"]["gender"] = filterGender;
    }

    fetch(
      config.APP_URLS.BACKEND_SERVER_API + "/web/list-leopards-for-filter",
      {
        method: "POST",
        body: JSON.stringify(json),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        // If no results found, show toast and exit
        if (json["matchResults"].length == 0) {
          reject(new Error("No match found in the database for the filter"));
        } else {
          resolve(json["matchResults"]);
        }
      })
      .catch((err) => {
        //console.log("Error captured!!! : " + err);
        reject(
          new Error("Unable to search for leopards. Error:" + err.message)
        );
      });
  });
