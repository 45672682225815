import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

function AbouDWCParks_Minneriya() {
  return (
    <>
      <Row>
        <Col>
          <h3 className="title">Minneriya National Park</h3>
          <img
            src={require("assets/img/aboutDWC/parks/Minneriya.png")}
            className="dwc-parks-image"
            alt="Minneriya National Park"
          ></img>
          <h4>Introduction</h4>
          <p>
            Giant tanks and irrigation system have been built to utilize for
            cultivation. Out of the massive tanks built in Sri Lanka dowered
            with a long cultivate heritage symbolized by the tanks and the
            dagaba. Minneriya is second to none. The park is situated in
            Higurakgoda Divisional secretariet in the District of Polonnaruwa in
            the North Central Province. The total of the Park is 8889.411
            hectares. Minneriya was declared as a National Park on the 12 th of
            August 1997 under the same ordinance opened for visitors in May
            1998. Minneriya reservoir along with its surroundings, plays a vital
            role as a wetland, hence it has high biodiversity.
          </p>
          <h4>ACCESS</h4>
          <p>
            <ul>
              <li>
                The park is located 182km from Colombo on the main road to
                Polonnaruwa via Habarana. The main entrance to the Park is
                situated at Ambagaswewa, 8.8km from Habarana on the Colombo
                Polonnaruwa road. After obtaining a permit from the Ambagaswewa
                wild life conservation office, one could enter the park.
              </li>
            </ul>
          </p>
          <h4>PHYSICAL CHARACTERRISTICS</h4>
          <p>
            The topography is varied, with hills, patanas and talawas. Minneriya
            is an ancient irrigation tank, with a capacity of 22,550ha when full
            and a catchment area of 24,000ha. The main source of water is from a
            diversion of the Amban Ganga, along the Elahera channel. The park
            covers an area of 8,889ha. The altitude ranges from approximately
            100m to 8,885m at the top of Nilgala peak. Condition is topical
            monsoon climate. Mean annual rainfall is about 1,146mm and mean
            annual temperature 27.5 ° C.
          </p>
          <h4>VEGITATION</h4>
          <p>
            The national park vegetation consists of tropical dry mixed
            evergreen forests, abandoned chena cultivated lands, grassland, and
            wetlands.
          </p>
          <h4>FAUNA</h4>
          <p>
            Twenty four (24) species of mammals in Sri Lanka have been reported
            from this park. The most important of them is the wild elephant
            (Elephant maximus) the population of which of about 150-200.
            Minnerya reservoir and its surrounding wetlands habitat is inhabited
            by large number of a aquatic bird species. Early morning and late
            evening are the optimum observation of the day for resident and
            migratory birds. Three species of fishes are endangered out of four
            endemic species recorded in the reservoir, while the introduced
            Thilapia species are dominant.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default AbouDWCParks_Minneriya;
