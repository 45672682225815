import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  CardHeader,
  Card,
  CardBody,
  Col,
} from "reactstrap";

// core components
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";
import { Link } from "react-router-dom";

var projectList = [
  {
    project: "Our Research on Leopards",
    image: "about-projects/research/research_banner.jpg",
    link: "/projects/research",
    desc:
      "Our research team conducts continuous research at the Yala National Park under a research permit issued by the Department of Wildlife Conservation",
  },
  {
    project: "Leopard Identification Guide",
    image: "about-projects/leopard-identification-guide/LI8.jpg",
    link: "/projects/leopard-identification",
    desc:
      "This guide book was given to all field staff of the Yala National park which will help them identify Leopards they see in the park more accurately.",
  },
   
  {
    project: "Yala Leopard Center",
    image: "about-projects/yala-leopard-center/img1.jpg",
    link: "/projects/yala-leopard-center",
    desc:
      " The Yala Leopard Center commissioned at the Dr. Ravi Samarasinghe memorial hall at the Yala National Park contains information about the Leopards of Yala and their behaviour.",
  },
  {
    project: "Awareness Program for Children",
    image: "about-projects/awareness-program/img_1.jpg",
    link: "/projects/childern-awareness",
    desc:
      "Yala Leopard Diary team together with the community outreach program of the Yala National Park, conducted an educational tour of the Yala Leopard Center and the Yala National Park",
  },
  
  {
    project: "Hill Country Leopard Conservation initiative",
    image: "about-projects/hill-country-leopards/HL3.jpg",
    link: "/projects/hill-country-leopards",
    desc:
      "The initiative is based on quick communications, quick reaction, awareness on hill country leopards and handling situations,and the removal of snares and traps",
  },
 
  {
    project: "Outreach program to Ranminithenna Kanitu Vidyala",
    image: "about-projects/outreach/Img_1.jpg",
    link: "/projects/outreach",
    desc:
      "Yala Leopard Center conducted a community outreach project to Ranminithenna Kanitu Vidyala. Sixty students from Age groups 7-14 were provided a Yala safari",
  },
];

function ProjectsHome() {

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      {SwitchNavBar()}
      <div className="wrapper">
        <SubPageHeader title="Our Conservation Initiatives" />

        <Container>
          <br />
          <br />
          <h3 className="title"> Our Initiatives </h3>
          <Row>
            {projectList.map((project, index) => (
              <Col lg="4" md="4" sm="6" xs="12" key={"results-" + index}>
                <Card>
                  <div className="team-player">
                    <img
                      src={require(`assets/img/${project.image}`)}
                      className="dwc-parks-image-thumbnail"
                      alt=""
                    ></img>
                    <CardHeader>
                      <h5 className="title dwc-parks-view-button">
                        {project.project}
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <p>{project.desc}</p>

                      <div className="dwc-parks-view-button">
                        <Link to={project.link}>
                          <Button
                            className="btn-round"
                            color="info"
                            type="button"
                          >
                            View &nbsp;{" "}
                            <i className="fab fas fa-arrow-circle-right"></i>
                          </Button>
                        </Link>
                      </div>
                    </CardBody>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>

          

        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default ProjectsHome;
