import React from "react";

// reactstrap components
import {
  
  Container,
} from "reactstrap";

// core components
//import IndexNavbar from "components/Navbars/IndexNavbar.js";
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";

function TestimonialsPage() {
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      {SwitchNavBar()}
      <div className="wrapper">
        <SubPageHeader title="Testimonial" />

        <Container>
          
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default TestimonialsPage;
