import config from "../config.json";

export const GetAllCoordinatesForPark = (parkid) =>
  new Promise(function (resolve, reject) {
    fetch(config.APP_URLS.BACKEND_SERVER_API + "/web/get-all-location-coordinates", {
      method: "POST",
      body: JSON.stringify({ national_park: parkid }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        // If no results found, show toast and exit
        if (json["matchResults"].length == 0) {
          reject(
            new Error("Unable to fetch coordinate information from database")
          );
        } else {
          //console.log("Got locationlist")
          var allcoords = json["matchResults"];

          resolve(allcoords);
        }
      })
      .catch((err) => {
        //console.log("Error captured!!! : " + err);
        reject(
          new Error(
            "Unable to fetch coordinate locations. Error:" + err.message
          )
        );
      });
  });
