import React from "react";

// reactstrap components
import {

  Container,

} from "reactstrap";

// core components
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import ImageCarousel from "components/projects-components/ImageCarousel.js";

//import config from "../../config.json";
//import GoogleMapReact from "google-map-react";

import { SwitchNavBar } from "../../../utils/SwitchNavBar.js";

function ProjectChildernAwarenes() {
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      {SwitchNavBar()}
      <div className="wrapper">
        <SubPageHeader title="Awareness Program for Children" />

        <Container>
          <ImageCarousel
            imagepaths={[
              "about-projects/awareness-program/img_1.jpg",
              "about-projects/awareness-program/img_2.jpg",
              "about-projects/awareness-program/img_3.jpg",
              "about-projects/awareness-program/img_4.jpg",
              "about-projects/awareness-program/img_5.jpg",
              "about-projects/awareness-program/img_6.jpg",
              "about-projects/awareness-program/img_7.jpg",
              "about-projects/awareness-program/img_8.jpg",
              "about-projects/awareness-program/img_9.jpg",
              "about-projects/awareness-program/img_10.jpg",
              "about-projects/awareness-program/img_11.jpg",

            ]}
          />

          <h3 className="title"> About </h3>
          <p>
            In keeping with our vision of broad basing knowledge gathered in the course of pursuing our passion on Wildlife, Yala Leopard Diary together with the Community Outreach program of the Yala National Park, conducted an educational tour of the Yala Leopard Center and the Yala National Park block 1, to 30 students, aged 12-15, from the villages bordering the Park.
          </p>
          <p>
            The students were accompanied by 3 Parents during the entire program. The Program started today (26/9/20)  with sharing knowledge about the importance of the Yala National Park to the ecosystem and the economic system of the area, the importance of conservation, and the need to stop poaching.
          </p>
          <p>
            A tour of the Yala Leopard Center was given and basic insights of Leopards of Yala and the importance of them were explained to them at the Yala Leopard Center. An interactive session to get the students' feedback and thoughts of the visit to the park,  was held at Patanangala in the evening.
          </p>
          <p>
            The students reacted extremely positively to this program and shared many good ideas about what they think could be done for the conservation of Fauna and Flora of the Yala National Park. The students and parents were picked from the Uddakandara Raja Maha Viharaya and dropped back to the same location. Lunch and refreshments were served to all who participated. </p>
          <p>
            The Program was conducted by the Park Warden, Rangers, Outreach officer, and the staff of the park, under the guidance of the Assistant Director of the area. The Team Yala Leopard Diary was represented by Pramod Sendanayake and Chathura Dushmantha, who also shared many insights with the students and recorded their feedback. We plan to conduct this program on a regular basis until all students of the villages bordering the Park are educated. </p>
          <p>
            <h5> Concluded on 29th September 2020 </h5>
          </p>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default ProjectChildernAwarenes;
