export const DocumentList = [
    {
        "DocumentType" : "DOCUMENT",
        "Path" : "599702_2.pdf",
        "Name" : "Research Article Leopard Panthera pardus fusca Density in the Seasonally Dry,Subtropical Forest in the Bhabhar of Terai Arc, Nepal",
        "Author":"Millinda Wattegedara"
    },
    {
        "DocumentType" : "DOCUMENT",
        "Path" : "599702_3.pdf",
        "Name" : "Reproductive success of female leopards Panthera pardus: The importance of top-down processes",
        "Author":"Millinda Wattegedara"
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.702_6.pdf",
        "Name" : "Focus on the Leopard (Panthera pardus)",
        "Author":"Millinda Wattegedara"
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.702.pdf",
        "Name" : "The Un-Common Leopard presence, distribution and abundance in Gallies and Murree Forest Division, Northern Pakistan",
        "Author":"Millinda Wattegedara"
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.702(7).pdf",
        "Name" : "PRELIMINARY FINDINGS ON THE ACTIVITY PATTERNS OF THE CLOUDED LEOPARD, Neofelis diardi, AND ITS PREY AT GUNUNG PUEH NATIONAL PARK, LUNDU, SARAWAK",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.702(8).pdf",
        "Name" : "Panthera pardus, Leopard , Assessment by: Stein, A.B. et al.",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.702(9).pdf",
        "Name" : "Leopard (Panthera pardus) status, distribution, and the research efforts across its range",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.702(10).pdf",
        "Name" : "PROPOSAL FOR THE INCLUSION OF THE LEOPARD (Panthera pardus)",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.702(11).pdf",
        "Name" : "Integrating remote sensing and interview data for the identification of a leopard corridor in the Alborz Mountains, Iran",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.702(12).pdf",
        "Name" : "Identification of individual leopards {Panthera pardus kotiya) using spot pattern variation",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.702(13).pdf",
        "Name" : "Summer diet composition of the Common Leopard Panthera pardus (Carnivora: Felidae) in Nepal",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.756 (2).pdf",
        "Name" : "Coexisting Large Carnivores: Spatial Relationships of Tigers and Leopards and their Prey in a Prey-Rich Area in Lowland Nepal",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.756(1).pdf",
        "Name" : "Conservation Genetics of the Far Eastern Leopard (Panthera pardus orientalis)",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.756(3).pdf",
        "Name" : "Big cat in well: an unconventional threat to leopards in southern India",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.756(4).pdf",
        "Name" : "Phylogenetics, genome diversity and origin of modern leopard, Panthera pardus",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.756(5).pdf",
        "Name" : "Use molecular-genetics characteristics for reintroduction of leopard (Panthera pardus L., 1758) in the Caucasus",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.756(6).pdf",
        "Name" : "Population survey and genetic diversity of snow leopards Panthera uncia as revealed by fecal DNA",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.756(7).pdf",
        "Name" : "Genetic based population survey of snow leopard (P. uncia) in Annapurna Conservation and Rolwaling areas of Nepal.",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.756(8).pdf",
        "Name" : "Genetic diversity among common leopards inhabiting Azad Jammu and Kashmir, Pakistan",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.756(34).pdf",
        "Name" : "Home range structure and space use of a female Amur leopard, Panthera pardus orientalis (Carnivora, Felidae)",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.756(35).pdf",
        "Name" : "Diel patterns of movement activity and habitat use by leopards (Panthera T pardus pardus) living in a human-dominated landscape in central Kenya",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.756(36).pdf",
        "Name" : "Strategy for the Conservation of the Leopard in the Arabian Peninsula",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.756(37).pdf",
        "Name" : "Leopard population and home range estimates in north‐central Namibia",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767 (2).pdf",
        "Name" : "Are conflict-causing tigers different? Another perspective for understanding human-tiger conflict in Chitwan National Park, Nepal",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767 (3).pdf",
        "Name" : "Leopard in a tea-cup: A study of leopard habitat-use and human-leopard interactions in north-eastern India",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767 (4).pdf",
        "Name" : "A panel of microsatellites to individually identify leopards and its application to leopard monitoring in human dominated landscapes",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767 (5).pdf",
        "Name" : "Conflict to Coexistence: Human – Leopard Interactions in a Plantation Landscape in Anamalai Hills, India",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767 (6).pdf",
        "Name" : "Why Did Leopards Kill Humans in Mumbai but not in Nairobi? Wildlife Management in and Around Urban National Parks: The Quest for Naturbanity",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767 (7).pdf",
        "Name" : " Factors associated with co-occurrence of large carnivores in a human-dominated landscape.",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767 (8).pdf",
        "Name" : "The balancing act: Maintaining leopard–wild prey equilibrium could offer economic benefits to people in a shared forest landscape of central India",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767.pdf",
        "Name" : "Human-Leopard Conflict Management Guidelines",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767(9).pdf",
        "Name" : "Occurrence, density and habitat use responses of leopard (Panthera pardus) in a human disturbed region of rural Mozambique",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767(10).pdf",
        "Name" : "Human-leopard conflict in Nepal: A case study from Baitadi District.",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767(11).pdf",
        "Name" : "Investigating Human-Leopard Conflict In and Around Gir Protected Areas",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767(12).pdf",
        "Name" : "Human-Leopard Conflict: An Emerging Issue of Common Leopard Conservation in Machiara National Park, Azad Jammu and Kashmir, Pakistan",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767(13).pdf",
        "Name" : "Human-Leopard Conflict Management Guidelines",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767(14).pdf",
        "Name" : "Human Leopard Conflict in Bandhavgarh Tiger Reserve: The Emerging Drift and Community Perspective",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767(15).pdf",
        "Name" : "Human-Leopard Conflict in Girimukti Village, Sukabumi, Indonesia",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767(16).pdf",
        "Name" : "Human Leopard Conflict, Assam- India - Understanding Leopard",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767(17).pdf",
        "Name" : "Monitoring Human-Leopard Conflict in Altitudinal Variations with Landscape Characteristics Using GIS and Remote Sensing in Pauri Garhwal, India",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767(18).pdf",
        "Name" : "Human disturbance affects habitat use and behaviour of Asiatic leopard Panthera pardus in Kaeng Krachan National Park, Thailand",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767(19).pdf",
        "Name" : "The susceptibility of leopards (Panthera pardus) to trophy hunting",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767(20).pdf",
        "Name" : " IMPACT OF LEOPARD TRANSLOCATIONS ON LEVELS OF CONFLICT IN A HUMAN-DOMINATED LANDSCAPE IN MAHARASHTRA.",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767(21).pdf",
        "Name" : "Livestock husbandry practices and herd composition influence leopard-human conflict in Pokhara Valley, Nepal",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767(22).pdf",
        "Name" : "Policy for human-leopard conflict management in India",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.767(23).pdf",
        "Name" : "Monitoring Leopard (Panthera pardus) and its prey in different Human-Leopard conflict zones (high, medium and low), Pauri Garhwal, Uttaranchal",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.768 (2).pdf",
        "Name" : "Rapid recovery of tigers Panthera tigris in Parsa Wildlife Reserve, Nepal",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.768.pdf",
        "Name" : "DO TIGERS DISPLACE LEOPARDS?",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7054(1).pdf",
        "Name" : "Habitat use and predicted range for the mainland clouded leopard Neofelis nebulosa in Peninsular Malaysia",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554 (2).pdf",
        "Name" : "The Arabian Leopard Panthera pardus nimr conservation breeding programme",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554 (3).pdf",
        "Name" : "Territorial marking by the Persian Leopard ( Panthera pardus saxicolor Pocock, 1927) in Bamu National Park, Iran",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554 (4).pdf",
        "Name" : "Measuring body dimensions of leopards ( Panthera pardus ) from camera trap photographs",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554 (6).pdf",
        "Name" : "Avoiding Intraguild Competition: Leopard Feeding Ecology and Prey Caching in Northern Botswana",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554.pdf",
        "Name" : "Identifying priority conservation landscapes and actions for the Critically Endangered Javan leopard in Indonesia: Conserving the last large carnivore in Java Island",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(5).pdf",
        "Name" : "Cost-benefit analysis of increasing sampling effort in a baited-camera trap survey of an African leopard (Panthera pardus) population",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(7).pdf",
        "Name" : "First camera trap record of Persian leopard Panthera pardus saxicolor on the territory of the Ustyurt Nature Reserve (Kazakhstan)",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(8).pdf",
        "Name" : "Persistence of Persian leopard in a buffer habitat in northeastern Iran",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(9).pdf",
        "Name" : "Modelling potential habitat for snow leopards (Panthera uncia) in Ladakh, India",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(10).pdf",
        "Name" : "Morphology and morphometry of tarsals of the leopard (Panthera pardus)",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(11).pdf",
        "Name" : "Factors Affecting Location Failure of GPS Collars Fitted to African Leopards ( Panthera pardus )",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(12).pdf",
        "Name" : "Historical biogeography of the leopard (Panthera pardus) and its extinct Eurasian populations",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(13).pdf",
        "Name" : "A paradox of local abundance amidst regional rarity: the value of montane refugia for Persian leopard conservation",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(14).pdf",
        "Name" : "Assessing subspecies status of leopards (Panthera pardus) of northern Pakistan using mitochondrial DNA",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(15).pdf",
        "Name" : "Anchoring and adjusting amidst humans: Ranging behavior of Persian leopards along the Iran-Turkmenistan borderland",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(16).pdf",
        "Name" : "Effects of temporary closure of a national park on leopard movement and behaviour in tropical Asia",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(17).pdf",
        "Name" : "Patterns of sexual dimorphism in the Persian Leopard (Panthera pardus saxicolor) and implications for sex differentiation",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(18).pdf",
        "Name" : "Anchoring and adjusting amidst humans: Ranging behavior of Persian leopards along the Iran-Turkmenistan borderland",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(19).pdf",
        "Name" : "Strategy for the Conservation of the Leopard in the Caucasus Ecoregion Revised Version 2017",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(20).pdf",
        "Name" : "General conditions for the conservation of the leopard in the Caucasus",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(21).pdf",
        "Name" : "A REGIONAL PERSPECTIVE FOR SNOW LEOPARD CONSERVATION IN THE INDIAN TRANS-HIMALAYA",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(22).pdf",
        "Name" : "BEHAVIOUR FEATURES OF THE PERSIAN LEOPARD (PANTHERA PARDUS SAXICOLOR) DURING DIFFERENT STAGES OF THE ESTROUS CYCLE",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(23).pdf",
        "Name" : "Aspects of the ecology and the behaviour of the leopard",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(24).pdf",
        "Name" : "On the neck seizing behavior of leopard in southern Rajasthan, India",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(25).pdf",
        "Name" : "Scent marking in Sunda clouded leopards (Neofelis diardi): Novel observations close a key gap in understanding felid communication behaviours",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(26).pdf",
        "Name" : "Analysis of the influence of environmental enrichment on the behaviour of wild cats kept in captivity",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(27).pdf",
        "Name" : "Hunting behavior in West African forest leopards",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(28).pdf",
        "Name" : "Home Range, Movements and Habitat Use of Snow Leopard (Uncia Uncia) In Nepal",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(29).pdf",
        "Name" : "Seasonal food habits of the endangered Indochinese leopard Panthera pardus delacouri in a protected area of North West Thailand",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(30).pdf",
        "Name" : "Movement, activity and hunting behaviour of leopards in the Cederberg mountains, South Africa",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(31).pdf",
        "Name" : "Estimating Snow Leopard Population Abundance Using Photography and Capture-Recapture Techniques",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(32).pdf",
        "Name" : "Prey preferences of the leopard (Panthera pardus)",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(33).pdf",
        "Name" : " On the Ecological Separation between Tigers and Leopards",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.7554(34).pdf",
        "Name" : "How CITES Impacts Leopard Conservation",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.705493 (2).pdf",
        "Name" : "The ecology and behaviour of a protected area Sri Lankan leopard (Panthera pardus kotiya) population",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.705493(1).pdf",
        "Name" : "Forest cover and level of protection influence the island-wide distribution of an apex carnivore and umbrella species, the Sri Lankan leopard (Panthera pardus kotiya)",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.705493(3).pdf",
        "Name" : "Density of leopards (Panthera pardus kotiya) in Horton Plains National Park in the Central Highlands of Sri Lanka",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.705493(4).pdf",
        "Name" : "Notes on the status, distribution and abundance of the Sri Lankan leopard in the central hills of Sri Lanka",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.705493(5).pdf",
        "Name" : "AN OVERVIEW OF THE WILD CATS OF SRI LANKA -Part 2 The ecology of wild cats in Sri Lanka Leopards",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.705493(6).pdf",
        "Name" : "Commercial safari jeep drivers’ perspectives on mitigation of traffic congestion in the Ruhunu national park (Yala), Sri Lanka",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.705493(7).pdf",
        "Name" : "The Leopard’s Island",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.705493(8).pdf",
        "Name" : "Estimation of non use value of Srilankan Leopard",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.705493(9).pdf",
        "Name" : "Aspects of population ecology of the leopard (Panthera pardus) in Ruhuna National Park,Sri Lanka",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.705493(10).pdf",
        "Name" : "Notes on the diet and habitat selection of the Sri Lankan Leopard Panthera pardus kotiya (Mammalia: Felidae) in the central highlands of Sri Lanka",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.705493(11).pdf",
        "Name" : "Adrenal-Pituitary-gonadal relationships and ejaculate charactestics in captive leopards isolated on the island of Srilanka",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599.705493(12).pdf",
        "Name" : "Determining occupancy, abundance and population structure of an endangered apex predator, the Sri Lankan leopard (Panthera pardus kotiya)",
        "Author":""
    },{
        "DocumentType" : "DOCUMENT",
        "Path" : "599702 (4).pdf",
        "Name" : "Re-evaluating models for estimating prey consumption by leopards",
        "Author":""
    },



];

