import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
//import IndexNavbar from "components/Navbars/IndexNavbar.js";
import SubPageHeader from "components/Headers/SubPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { SwitchNavBar } from "../../utils/SwitchNavBar.js";

function DemarcationOfZones() {
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      {SwitchNavBar()}
      <div className="wrapper">
        <SubPageHeader title="Demarcations of Zones" />

        <Container>
          <h3 className="title">Overview</h3>

          <p>
            <b>Zone A</b> is demarcated and bounded as the areas inside of the
            Cinnamon wild Road, Palatupana weva, Bembawa, Magulmaha Viharaya on
            Sithulpawwa road, Ginikasaya Wala, Koti Gala, Wilapalawa Wewa to the
            beach.
          </p>
          <br />
          <p>
            <b>Zone B</b> is demarcated and bounded as the areas inside of
            Kotigala, Ginikasaya Wala, Magulmaha Viharaya, Sithulpawwa road,
            Katagamuwa Weva to Menik river to 2nd Darshana Weva Y junction on
            the Warahana Road, Modaragala, through to Moodigala on the Akasa
            Chaithya Road, Buttuwa Spill Road to Meynert Weva to the beach.
          </p>
          <br />
          <p>
            <b>Zone C</b> is demarcated and bounded as the areas inside of
            Meynert Weva, Buttuwa Spill road, Moodi Gala, Dharshana wewa Y
            junction to the Menik River straight from the Y junction to Parana
            Totupola up to the Menik River Estuary. The demarcations are clearly
            shown on the map. It is apparent that the demarcations are made to
            separate the vast park areas into smaller areas. These demarcations
            would assist us to identify individual Leopard in these zones. It
            also helps us to study their territorial habits and their movements
            inside each of these zones.
          </p>
          <br />
          <p>
            It should be clearly understood that zoning does NOT imply that the
            Leopards who are listed under each zone are visible only in that
            particular zone. They can be sometimes seen in other zones as well.
            It should be understood that the zonation is purely for this Leopard
            Diary only.
          </p>

          <br />

          <h3 className="title">Zonal Map</h3>
          <Row md="3">
            <Col>
              <img
                src={require("assets/img/methodology/ZonalMap_Large.png")}
                alt=""
              ></img>
            </Col>
          </Row>
          <Row>
            <br />
          </Row>
          <Row md="3">
            <Col>
              <img
                src={require("assets/img/methodology/ZoneA.png")}
                alt=""
              ></img>
            </Col>
            <Col>
              <img
                src={require("assets/img/methodology/ZoneB.png")}
                alt=""
              ></img>
            </Col>
            <Col>
              <img
                src={require("assets/img/methodology/ZoneC.png")}
                alt=""
              ></img>
            </Col>
          </Row>
          <h3 className="title">Zone Glossary</h3>

          <Row md="12">
            <Col>
              <table className="tablesorter" responsive>
                <thead className="text-primary">
                  <tr>
                    <th width="50">Zone</th>
                    <th width="50">Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>A</td>
                    <td>Jamburagala Camp site Road</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Banda Wala - Wepandeniya</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Bembawa</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Ginikasaya Wala</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Jamburagala Lake (Near tanki gala)</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Jamburagala Road</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Ketagal Wala</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Koti Gala (Wepandeniya gala)</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Kuda Seelawa</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Magul Maha Viharaya</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Mahaseelawa</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Mahaseelawa Weva</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Mating Gala (Aluth Para)</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Pattiya Wala</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Piyadasa Lane</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Thelambu kema</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Weli Aara</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Wepandeniya (Koti gala area)</td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>Cinnamon wild road area</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Wilapalawa Weva area</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>
                      Akasa Chaithya Road (From Buttuwa spill to Moodi Gala)
                    </td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Ali Gala</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Alikatupelessa</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Aluth Para (Gerimas pokuna)</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Buttuwa Bungalow Road</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Buttuwa Junction on main Road</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Buttuwa Spill Road</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Buttuwa Weva</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Buttuwa weva Road</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Darshana Weva (Modara Gala Weva)</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Darshana Weva Y Junction -2 (Warahana Road)</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Debaragas Wala</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col>
              <table className="tablesorter" responsive>
                <thead className="text-primary">
                  <tr>
                    <th width="50">Zone</th>
                    <th width="50">Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>B</td>
                    <td>Gallukada Weva</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Gerimas Pokuna</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Gonagala Road</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Gonagala Welmal Kema Road</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Gonagala Weva</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Heen Weva</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Heen Weva Road</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Idda Gala on Heen Weva Road</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Karawgas Wala ‚Äî Uraniya Road</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Karawgas Wala (1, 2, 3)</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Katagamuwa</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Kohombagas Wala</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Korawakka Weva</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Lolugas Wala</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Maliththan Kotu Wala</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Mating Gala (Jamburagala-Gonagala Rd)</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Meynert Weva</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Modara Gala</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Modara Gala Weva (Darshana Weva)</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Moodi Gala</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Ondatjie Bungalow Road</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Pahalahenthota</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Palatupana area</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Palugas Wala (No.1, 2)</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Pemdas Maga</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Pemdas Wila</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Rathmal Wala</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Sath Maga</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Sath Wila</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Sithulpawwa</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Sithulpawwa Road</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Siyamabalagas Wala</td>
                  </tr>
                </tbody>
              </table>
            </Col>

            <Col>
              <table className="tablesorter" responsive>
                <thead className="text-primary">
                  <tr>
                    <th width="50">Zone</th>
                    <th width="50">Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>B</td>
                    <td> Uraniya Area</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Walas Wala</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Warahana Area</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Weli Wala</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Welmal kema</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td> Wihanga Wala</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Agil Wala</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td>
                      {" "}
                      Akasa Chaithya Road (From Moodi gala to Meda para
                      junction)
                    </td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Darshana Weva Y Junction -1 (Thalgasmankada Road)</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Digan Wala</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Diwulgas Mandiya Meda Para</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Ehela Gas Wala</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Gonalabbe Meda Para (Gonalahamba)</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Gonalabbe Plains (Gonalahamba)</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Handunoruwa Weva (Andunoruwa)</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Karuwala Bokkuwa</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Koma Weva</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Kosgasmankada</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Kota Siyambale tree</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Kotabendi Weva</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Maila Wala</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Moda Wala (1, 2)</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Neganiyantota</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Padikema</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Parana Thotupola</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Patanangala</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Rakina Wala</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Rawum Wala 1 & 2</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Rukwila</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Suduwelimulla</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Thalgasmankada</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Thelambu Wala</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Yala Weva</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td> Panwala</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default DemarcationOfZones;
