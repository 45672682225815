/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Container, Card, CardBody, Row } from "reactstrap";
import { useMediaQuery } from "react-responsive";
import TipBox from "components/HomeComponents/TipBox";

function IndexHeader() {
  let pageHeader = React.createRef();

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });
  const isTab = useMediaQuery({
    query: "(max-width:  991px)",
  });

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  function getBackgroundImgPerSize() {
    if (isMobile) {
      return "landing-background-mobile.jpg";
    } else if (isTab) {
      return "landing-background-tab.jpg";
    } else {
      return "landing-background.jpg";
    }
  }

  return (
    <>
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/landing-page/" + getBackgroundImgPerSize()) +
              ")",
          }}
          ref={pageHeader}
        ></div>

        {!isMobile && <TipBox isOpen={true}></TipBox>}

        <Container>
          <div className="content-center brand">
            {/* <img
              alt="..."
              className="n-logo"
              src={require("assets/img/landing-page/Logo (1).png")}
            ></img> */}
            <br></br>
            <h3 className="h3-seo">Welcome to</h3>
            <h1 className="h1-seo">Yala Leopard Diary</h1>
            <h3>Leopard Identification and more, for Better Conservation</h3>
          </div>
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
