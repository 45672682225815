import React, { useEffect } from "react";

import { TreeNode } from "react-organizational-chart";
import config from "../../config.json";

function FamilyTreeItem(props) {
  // CSS Inspired by ::::
  // https://codepen.io/appirio-ux/pen/aBpJXJ?editors=1100
  // https://codesandbox.io/s/gkh4y
  //console.log("Received props to FamilyTreeItem  : " + JSON.stringify(props));

  function checkEmptyOrUndefinedImage(result) {
    // var thumbImg = result.images.find((x) => x.rotation == "thumbnail");
    if (result.isalive == 0) {
      return (
        <div className="deceased-notice-div">
          <a href={"/identified-leopard-result/" + result.officialnamecode}>
            <img
              className="tree-img"
              //src={"data:image/jpeg;base64, " + thumbImg.imagebase64}
              src={require(`assets/img/identified-leopards/YALA_NP/${result.officialnamecode}/thumbnail.jpg`)}
              alt="deceased"
            />
          </a>
          <h5 className="deceased-text">DECEASED</h5>
        </div>
      );
    } else {
      return (
        <div className="deceased-notice-div">
          <a href={"/identified-leopard-result/" + result.officialnamecode}>
            <img
              className="tree-img"
              //src={"data:image/jpeg;base64, " + thumbImg.imagebase64}
              src={require(`assets/img/identified-leopards/YALA_NP/${result.officialnamecode}/thumbnail.jpg`)}
              alt={result.nickname}
            />
          </a>
        </div>
      );
    }
  }

  function getNodeType(node) {
    //console.log(JSON.stringify(node))
    if (node.litternode === true) {
      var litterIndex = props.litterYears.indexOf(node.litteryear) + 1;
      return (
        <div>
          <div>
            <span
              className={
                "family-tree-litter-text family-tree-legend-l" + litterIndex
              }
            >
              {"Litter : " + node.litteryear}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={(() => {
            if (node.target == true) {
              return "tree-user highlighted-leopard";
            } else {
              return "tree-user";
            }
          })()}
        >
          {checkEmptyOrUndefinedImage(node)}

          <div className="tree-user-name">
            {node.nickname + " " + node.officialnamecode}
          </div>
          <div>{"Recorded : " + node.firstrecordedyear}</div>
        </div>
      );
    }
  }

  return (
    <>
      {props.ancestory.map((leopard) => (
        <TreeNode label={getNodeType(leopard)}>
          {leopard.children && (
            <FamilyTreeItem
              ancestory={leopard.children}
              litterYears={props.litterYears}
            />
          )}
        </TreeNode>
      ))}
    </>
  );
}

export default FamilyTreeItem;
