export const createCoordinateListBasedOnLngLat = (coordsString) => {
    //console.log("MapUtils.createCoordinateListBasedOnLngLat : coordsString " + JSON.stringify(coordsString))
    var finalCoordList = [];
    var coordArr = coordsString.split(",");
    for (var i = 0; i < coordArr.length; i++) {
      // if the cordinate is a Latitude coordinate..
      if (i % 2 === 0) {
        var coordPoint = {};
        coordPoint.lat = parseFloat(coordArr[i]);
        // get i+1 for Longtide from the array
        coordPoint.lng = parseFloat(coordArr[i + 1]);
        finalCoordList.push(coordPoint);
      }
    }
    //console.log("MapUtils.createCoordinateListBasedOnLngLat : finalCoordList " + JSON.stringify(finalCoordList))
    return finalCoordList;
}